import React from 'react';
import axios from 'axios';
// import MetaTags from 'react-meta-tags';
import { Modal }  from 'antd-mobile';
import {
    Link
  } from 'react-router-dom';
import renderHTML from 'react-render-html';
//import MarkdownRenderer from 'react-markdown-renderer';


//import {emojify} from 'react-emojione';
import CommentList from "./../../parts/CommentList.jsx";
import CommentForm from "./../../parts/CommentForm";
var md = require('markdown-it')().use(require('markdown-it-emoji'));
// var twemoji = require('twemoji');
// let md = new MarkdownIt();
var moment = require('moment');
// import  { Redirect } from 'react-router-dom'
// import FacebookProvider, { CommentsCount,Comments,Like } from 'react-facebook';
require('./../../../Shwesports.js')
 
//https://emoji-css.afeld.me/
//https://github.com/markdown-it/markdown-it-emoji/blob/master/dist/markdown-it-emoji.js
//https://github.com/markdown-it/markdown-it-emoji/blob/master/lib/data/shortcuts.js

// angry:            [ '>:(', '>:-(' ],
// blush:            [ ':")', ':-")' ],
// broken_heart:     [ '</3', '<\\3' ],
// // :\ and :-\ not used because of conflict with markdown escaping
// confused:         [ ':/', ':-/' ], // twemoji shows question
// cry:              [ ":'(", ":'-(", ':,(', ':,-(' ],
// frowning:         [ ':(', ':-(' ],
// heart:            [ '<3' ],
// imp:              [ ']:(', ']:-(' ],
// innocent:         [ 'o:)', 'O:)', 'o:-)', 'O:-)', '0:)', '0:-)' ],
// joy:              [ ":')", ":'-)", ':,)', ':,-)', ":'D", ":'-D", ':,D', ':,-D' ],
// kissing:          [ ':*', ':-*' ],
// laughing:         [ 'x-)', 'X-)' ],
// neutral_face:     [ ':|', ':-|' ],
// open_mouth:       [ ':o', ':-o', ':O', ':-O' ],
// rage:             [ ':@', ':-@' ],
// smile:            [ ':D', ':-D' ],
// smiley:           [ ':)', ':-)' ],
// smiling_imp:      [ ']:)', ']:-)' ],
// sob:              [ ":,'(", ":,'-(", ';(', ';-(' ],
// stuck_out_tongue: [ ':P', ':-P' ],
// sunglasses:       [ '8-)', 'B-)' ],
// sweat:            [ ',:(', ',:-(' ],
// sweat_smile:      [ ',:)', ',:-)' ],
// unamused:         [ ':s', ':-S', ':z', ':-Z', ':$', ':-$' ],
// wink:             [ ';)', ';-)' ]

md.renderer.rules.emoji = function(token, idx) {
  //return '<i class="em ' + token[idx].content + '" aria-role="presentation" ></i>';
  //return "sss"+JSON.stringify(token);
  return '<i class="em em-' + token[idx].markup + '" aria-role="presentation" aria-label="BIRD"></i>';
  // return '<span class="twemoji emoji emoji_' + token[idx].markup + '"></span>';
};
// md.renderer.rules.emoji = function(token, idx) {
//   //return emojify(token[idx].content);
//   return twemoji.parse(token[idx].content);
// };

const alert = Modal.alert;

class ForumDetailPage extends React.Component {
    constructor(props) {
      super(props);
      window.shwesports.footerMenu = "OTHER";

      this.state = {
        data : {
            id              : '',
            topic           : '',
            message         : '',
            title_id        : '',
            created_at      : '',
            updated_at      : ''
        },
        comments: [],
        comment :  { 
          id	: "536",
          key_id	: "28",
          salt :	"5d524400",
          message	: "test 222",
          parent_id	: "0",
          type_id :	"2",
          created_at :	"2020-05-08 04:11:33",
          updated_at :	"2020-05-08 04:11:33",
          username :	"san",
          child	: [] 
        },
          isLoading: true,
          loading: true,
          post_id : 1,
          fetch : 0,
          info : { role : 0 }
      };
      if(props.match.params.id) {
        this.state.post_id = props.match.params.id
      }

      this.addComment         = this.addComment.bind(this);
      this.postDelete         = this.postDelete.bind(this);

    }

    addComment(comment) {

      //this.props.history.push("/forum/15");
      this.setState({
        loading: false,
        // comments: [ comment, ...this.state.comments],
        fetch : 1
        //comments: [...this.state.comments,comment],
        //comments: [this.state.comments],
        // comment : comment
      });
      this.fetchCommentData();
    }

    hisUrl(url) {
      if(localStorage.getItem("route")) {
        let menu = JSON.parse(localStorage.getItem("route"));
        //console.log(menu.length+"-------------")
        if(menu.length === "0") {
          let data = ["/"];
          localStorage.setItem("route", JSON.stringify(data) );
        } 
         if(menu.length > 0) {
            //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                  //console.log("first");
                  menu.pop();
                  //window.shwesports.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  //localStorage.setItem("route", JSON.stringify(menu) );
              }  
              else if(menu[menu.length-1] !== url ){
                  menu.push(url)
                  //window.shwesports.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
              }
              window.shwesports.preurl =  menu[menu.length-2];
          }
      }
    }

    postDelete() {
        
        alert(window.lang.form.que.confirm, window.lang.form.que.delete, [
            { text: window.lang.form.cancel, onPress: () => console.log('cancel') },
            {
            text: window.lang.form.submit,
            onPress: () => {
                  this.sendDeleteApi();
                }
                
            },
        ])
    }

    sendDeleteApi() {
        let formdata = {id: this.state.post_id};
        var self = this;
        let dataurl = window.shwesports.apiUrl+'/user/forum/delete';
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then(function (response) {
            if(response.data.success) {
                self.setState({  
                    message : "Successfully Login"
                });
                self.props.history.push("/forum");
            } else {
                self.setState({  message : window.lang.messages.somethingwrong });
            }

            //console.log(localStorage.getItem('time'))
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }
    
    componentDidMount() {
        this.fetchData();
        this.hisUrl('/forum/'+ this.state.post_id );

        if(localStorage.getItem('time')) {
          if(localStorage.getItem('time') !== "0" ) {
            let opts = { phone : localStorage.getItem('time') , post_id : this.state.post_id }
            // fetch(window.shwesports.apiUrl+'/user/news/liked', {
            //   method: 'post',
            //   body: JSON.stringify(opts)
            // }).then(function(response) {
            //   return response.json();
              
            // }).then(function(data) {
            //   // console.log(data)
            //   // console.log(data.liked)
            //   if(data.liked) {
            //     self.setState({
            //       liked: data.liked,
            //       loading: false
            //     });
            //   }
            //   // ChromeSamples.log('Created Gist:', data.html_url);
            // });
            // console.log(this.state.data.liked)
            this.fetchCommentData();
          }
        }

    }

    UNSAFE_componentWillUpdate() {
      
      //localStorage.setItem('route', '/highlight/'+ this.state.post_id +'/'+ this.state.data.highlights_name);
      //console.log(localStorage.getItem('route'))
    }

    fetchCommentData() {
      fetch(window.shwesports.apiUrl+'/user/comment/'+this.state.post_id+"/2")
      .then(res => res.json())
      .then(res => {
        // console.log(res)
        if(res){
          this.setState({
            comments: res,
            loading: false
          });
        }
        
      })
      .catch(err => {
        this.setState({ loading: false });
      });
    }

    fetchData() {
        let time = localStorage.getItem('time');
        let dataurl = window.shwesports.apiUrl+'/user/forum/detail/'+this.state.post_id+"?time="+time;

        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
             this.setState({
                data: resp.data.data,
                info: resp.data.info
            });

            
        })
        .catch(function(error) {
          // console.log(error.tostring());
        });   
        
    }

    displayBlock() {
        let ui = []
        //let data
        if(this.state.data  != null){

            ui.push(
                <div className="main-content" key="0">
                       
                        
                        <div className="row content-box">
                            <br />
                            <h2 className="color-green">{this.state.data.topic}</h2><br />
                            
                            <small>{moment(this.state.data.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMMM YYYY, hh:mm A')}</small> | <small><i>Posted by : {this.state.data.username}</i></small> {(this.state.info.role === "1") ? <b className="text-right right btn btn-danger pointer" onClick={this.postDelete} >X</b>: ""}
                            <br/> 
                            <br />
                            <p>
                              
                            {/* {emojify(this.state.data.message) } 
                            <MarkdownRenderer markdown={this.state.data.message} />  */}
                           
                            {renderHTML(md.render(this.state.data.message))}
                            {/* {md.render('# markdown-it rulezz!')} */}
                              {/* {this.state.data.message} */}
                            </p>
                            <br />
                            <div className="row">
                                {
                                  ((localStorage.getItem('time')) ?
                                    ((localStorage.getItem('time') !== "0" ) ?
                                      <div className="row  pt-3 border-right">
                                        {/* {(this.state.liked)? <img src="/img/like-green.png"  height="15px"  alt="like"/>  : <img src="/img/like-white.png" height="15px" className="pointer" onClick={(e) => this.handleLikeClick(e)} alt="like"/> } <b >Like</b> <b>({this.state.data.liked})</b> */}
                                        <div className="gird-60 left">
                                          <h5>စိတ်ထဲရှိတာလေး ရေးခဲ့ပါအုန်း  | {" "}
                                            {window.lang.form.comment} {this.state.comments.length > 0 ? "များ" : ""} (<span className="badge badge-success">{this.state.comments.length}</span>)</h5>
                                            
                                            <br/><br/>
                                        </div>
                                        
                                        <CommentForm addComment={this.addComment} post_id={this.state.post_id} type_id="2" />
                                      </div>
                                    : <div className="row  pt-3 border-right">
                                      {/* <b className="color-green"> <img src="/img/like-green.png"  height="15px" alt="like"/> Like ({this.state.data.liked})</b><br/><br/> */}
                                      <Link to="/more/login" className="btn btn-success"> You need to login first </Link>
                                      </div> )
                                  : <div className="row  pt-3 border-right">
                                    {/* <b  className="color-green">  <img src="/img/like-green.png"  height="15px" alt="like"/> Like ({this.state.data.liked})</b><br/><br/> */}
                                    <Link to="/more/login" className="btn btn-success"> You need to login first </Link>
                                  </div>  )

                                }
                                
                                {(this.state.comments.length > 0) ? <div className="row  pt-3 bg-white">
                                  <CommentList
                                    loading={this.state.loading}
                                    comments={this.state.comments}
                                    fetch={this.state.fetch}
                                    type_id="2"
                                    post_id={this.state.post_id}
                                  />
                                </div> : <div className="row text-center">ကွန့်မန့်မရှိသေးပါ။</div> }
                                
                            </div>
                            {/* {this.displayVideoBlock(this.state.data.highlights_embed)} */}
                            {/* <FacebookProvider appId="1750258531872776">
                                <Comments href="http://localhost" />
                                <CommentsCount href="http://localhost" /> Comments
                            </FacebookProvider> */}
                        </div>    
                        
                 </div>
            )
        }

        if(ui.length === 0) {
            ui.push(<div className="main-content"><div className="text-center" key="0"><h2>Something was found.</h2></div></div>)
       }
        
        return ui
    }

  
    render() {
        
        return (
            <div className="page">
                <div className="page-content">
                    {this.displayBlock()}

                    <div className="ant-back-footer">
                        <div className="ant-back-top">
                            <div className="ant-back-top-content">
                                <Link to={window.shwesports.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null) 
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
      }
      
export default ForumDetailPage;
 