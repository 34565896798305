/* eslint no-dupe-keys: 0 */
import React,{Component} from 'react';
import { ListView } from 'antd-mobile';
//import { FacebookProvider, CommentsCount } from 'react-facebook';


import {
  Link
} from 'react-router-dom';

import axios from 'axios';
require('./../../../Shwesports.js')


let NUM_ROWS = 20;
let TOTAL_INDEX = 0;

function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}

class HighlightsListPage extends Component {
  constructor(props) {
    super(props);

    window.shwesports.footerMenu = "HIGHLIGHTS";

    var dataSource = new ListView.DataSource({
      rowHasChanged: (r1, r2) => r1 !== r2
    });

    this.data = [];
    this.state = {
      
      dataSource: dataSource.cloneWithRows(this.data),
      isLoading: true,
      
    };

    this.pageIndex = 1;
    TOTAL_INDEX = 0;
    NUM_ROWS = 20;
    if(this.props.perPage) {
      NUM_ROWS = this.props.perPage;
      TOTAL_INDEX = this.props.pageIndexTotal;

      this.state.isLoading = true;
      
    }
  }

  componentDidMount() {
    
    //TOTAL_INDEX = 20;
    // you can scroll to the specified position
    setTimeout(() => this.lv.scrollTo(0, 120), 800);

    // simulate initial Ajax
    setTimeout(() => {
      this.fetchData();
    }, 600);
  }

  fetchData() {
        
        const url = window.shwesports.apiUrl+'/highlights?limit='+NUM_ROWS+'&start=10';

        axios.get(url, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
          // console.log(this.data.concat(resp.data));
          this.data = resp.data;
          //let resp_data = resp.data;
            this.setState({
              dataSource: this.state.dataSource.cloneWithRows(this.data.reverse()),
              isLoading: false,
            });

            // this.data = { ...this.data, ...genData(++this.pageIndex) };
            // console.log(this.data)
            NUM_ROWS = Number(NUM_ROWS) + Number(NUM_ROWS);
            console.log(this.pageIndex)
            
        })
        .catch(function(error) {
          //console.log(error.tostring());
        });   
    // fetch(REQUEST_URL)
    //   .then((response) => response.json())
    //   .then((responseData) => {
    //     this.setState({
    //       dataSource: this.state.dataSource.cloneWithRows(responseData[0].data),
    //     });
    //   })
    //   .done();
  }

  //componentDidMount() {
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);

    // simulate initial Ajax
    // setTimeout(() => {
    //     console.log("ok");
    //     this.rData = genData();
    //     console.log(this.rData)
    //     // this.setState({
    //     //   dataSource: this.state.dataSource.cloneWithRows(this.rData),
    //     //   isLoading: false,
    //     // });

    //     //console.log(this.state)
    // }, 600);
  //}

  // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.dataSource !== this.props.dataSource) {
  //     this.setState({
  //       dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
  //     });
  //   }
  // }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      
      return;
    }
    //console.log('reach end', event);
    //this.setState({ isLoading: true });
    if(TOTAL_INDEX !== 0) { 
      if(this.pageIndex+1 >= TOTAL_INDEX) {
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: true });
      }
    } else {
      this.setState({ isLoading: false });
    }

    setTimeout(() => {
      //console.log(this.pageIndex)
      if(TOTAL_INDEX !== 0) {
  
        if(this.pageIndex+1 >= TOTAL_INDEX) {
          return ;
        }

        //this.data = { ...this.data, ...genData(++this.pageIndex) };
        
        this.fetchData();
        // this.setState({
        //   // dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      } else {
        this.data = { ...this.data, ...genData(++this.pageIndex) };
        this.fetchData();
        // this.setState({
        //   dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      }
      
    }, 1000);
  }

  render() {
    const separator = (sectionID, rowID) => (
      <div
        key={`${sectionID}-${rowID}`}
        style={{
          backgroundColor: '#F5F5F9',
          height: 8,
          borderTop: '1px solid #ECECED',
          borderBottom: '1px solid #ECECED',
        }}
      />
    );
    let index = this.data.length - 1;
    const row = (rowData, sectionID, rowID) => {
      if (index < 0) {
        index = this.data.length - 1;
      }
      const obj = this.data[index--];
      return (
        <Link to={"/highlight/"+obj.id_highlights+"/"+encodeURI(obj.highlights_name)}>
        <div key={rowID} style={{ padding: '0 5px' }}>
          {/* <div
            style={{
              lineHeight: '50px',
              color: '#888',
              fontSize: 18,
              borderBottom: '1px solid #F6F6F6',
            }}
          >{obj.title}</div> */}
          <div className="list-item  list-box-shadow">
            <img src='/img/video_preview.png' alt=""  className="list-featured-img" alt={obj.highlights_name}/>
            {/* <img style={{ height: '64px', marginRight: '15px' }} src={obj.news_image} alt="" /> */}
            <div className="row" style={{ lineHeight: 1 }}>
              {/* <div className="list-featured-p">{obj.highlights_name}</div> */}
              <b className="color-green">{obj.highlights_name}</b><br/>
              <div className="row list-footer text-right">
                  
                  {/* <FacebookProvider appId="1967980890125208">
                      <i>
                        <CommentsCount href={window.shwesports.baseUrl+"/highlight/"+obj.id_highlights+"/"+encodeURI(obj.highlights_name)} />
                        <img src="/img/comment.jpg" alt="comment" /> 
                      </i> 
                  </FacebookProvider> */}
                  
              </div>
            </div>
          </div>
        </div>
        </Link>
      );
    };
    return (
      <div className="page">
        <div className="page-content">
          <div className="main-content">
            <ListView
              ref={el => this.lv = el}
              dataSource={this.state.dataSource}
              renderHeader={() => <div className="list-header"><Link to="/highlight/latest"><img src="/img/badge.jpg" className="list-img-circle" alt="Latest News" /><span>LATEST HIGHLIGHTS</span></Link></div>}
              renderFooter={() => (<div style={{ padding: this.state.isLoading ? 30 : 0 , textAlign: 'center', display: this.state.isLoading ? 'block' : 'none' }}>
                {this.state.isLoading ? <img src="/img/loading.gif" alt="loading"  /> : ''}
              </div>)}
              renderRow={row}
              // renderSeparator={separator}
              className="am-list"
              pageSize={4}
              useBodyScroll
              // onScroll={() => { console.log('scroll'); }}
              scrollRenderAheadDistance={500}
              onEndReached={this.onEndReached}
              onEndReachedThreshold={10}
            />
            </div>
        </div>
      </div>
    );
  }
}

export default HighlightsListPage;

