import React from 'react';
import axios from 'axios';
//import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
    Link
  } from 'react-router-dom';
import { Grid } from 'antd-mobile';

require('./../../../Shwesports.js')

var moment = require('moment');
const days = ['Sunday','Monday','Tueday','Wednesday','Thursday','Friday','Saturday'];


let data1 = [
                // {icon: '/img/live-score/line-chart.jpg',text: 'Lines Up', short: 'line-up', url : "/line-up"}, 
              {icon: '/img/live-score/summary.png',text: 'Summary',  short: 'summary', url : "/summary" },
            // {icon: '/img/live-score/stats.jpg',text: 'Stats',  short: 'stats', url : "/stats"}, 
              {icon: '/img/live-score/key-events.png',text: 'Key Events',  short: 'key-events', url : "/key-events"},
            //   {icon: '/img/live-score/match-details.jpg',text: 'Match Details',  short: 'match-detail', url : "/live/match-detail/4381819"},
              {icon: '/img/live-score/form.png',text: 'Form',  short: 'form', url : "/form"},
              {icon: '/img/live-score/calendar.jpg',text: 'Table',  short: 'table', url : "/table"},
              {icon: '/img/live-score/h2h.jpg',text: 'H2H',  short: 'h2h', url : "/h2h"}];

class LiveScoreMatchDetailPage extends React.Component {
    constructor(props) {
      super(props);

      window.shwesports.footerMenu = "LIVE SCORES";
  
      this.state = {
        selectedDays: [new Date("2018-12-10T05:30:00.000Z")],
        data : [],
        standings : [],
        isLoading: true,
        post_id : 1,
        hidediv: false,
        selected_sub_menu : 'summary',
        form : []
        //selected_sub_menu : 'form'
      };
      if(props.match.params.id) {
        this.state.post_id = props.match.params.id
      }
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                    menu.pop();
                    //window.shwesports.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }  
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.shwesports.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    
                }
                //window.shwesports.preurl =  menu[menu.length-1];
                window.shwesports.preurl =  menu[menu.length-2];
            }
        }
      }


    componentDidMount() {
        this.fetchData();
    }

    UNSAFE_componentWillUpdate() {
        this.hisUrl('/live/match-detail/'+this.state.post_id )
        //localStorage.setItem('route', '/live/match-detail/'+this.state.post_id );
        //console.log(localStorage.getItem('route'))
    }

    fetchData() {
        let dataurl = window.shwesports.apiUrl+'/dbsoccer/match/detail/'+this.state.post_id ;

        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
            // this.pageIndex++;
            this.setState({
                data: resp.data.data,
                standings: resp.data.standings,
                form : resp.data.form,
            });
           // console.log(this.state.data);
        })
        .catch(function(error) {
        //   console.log(error.tostring());
        });   
        
    }
    
    subMenuClick(data) {
        this.setState({
            selected_sub_menu : data
        });
        // console.log('The link was clicked.');
        // console.log(data)
        // console.log(this.state.selected_sub_menu)
    }

    displayBlock( ) {
        let data = this.state.data
        //console.log(data)
        if(data.length  > 0){
            
            let tempArray = []
            data.forEach(function(each){
                let wday = moment(each.date, 'DD.MM.YYYY').isoWeekday();
                // console.log('--------------')
                // console.log(moment(each.date, 'DD.MM.YYYY'))
                // console.log('--------------')
                // let fullday = moment().isoWeekday(wday);
                tempArray.push(<div className="row live-detail-header"  key="0">    
                        <div className="grid grid-33">
                            {/* <img src="/img/manu_fc.jpg" alt="Real Madrid"/> */}
                            <br />
                            <b>{each.home.name}</b>
                        </div>
                        <div className="grid grid-33">
                            {(typeof(each.ft) === 'object' && each.ft === 'null') ?  ((each.ft.score !== "") ? (<b> FT {each.ft.score} </b>)  : "") : "" }
                         
                             {/* <b>{(each.ft.score !== "") ? "FT "+ each.ft.score : ""}</b><br /> */}
                            <span>{days[wday]}</span><br />
                            <span>{moment(each.created_at).format('DD MMM YYYY - hh:mm')}</span><br />
                            <span>{(each.venue !== "") ? each.venue : ""}</span><br/>
                            <span>{"["+each.home.goals +" - "+ each.away.goals+"]"}</span>
                            {/* <span>{(each.ht.score !== "") ? "HT "+ each.ht.score : ""}</span> */}
                        </div>
                        <div className="grid grid-33">
                            {/* <img src="/img/manu_fc.jpg" alt="Real Madrid"/> */}
                            <br />
                            <b>{each.away.name}</b>
                        </div>
                    </div>)
           })
            return tempArray
        }
    }

    viewHandler() {

        switch(this.state.selected_sub_menu) {

            // case "line-up":   return "";
            case "stats":   return this.stats();
            case "key-events": return this.keyEvent();
            case "summary":  return this.summary();
            // case "match-detail":  return this.keyEvent();
            // case "form":  return this.form();
            // case "table":  return this.table();
            case "form":  return this.commingsoom();
            case "table":  return this.commingsoom();
            case "h2h":  return this.commingsoom();
            default:      return "";
          }
    }

    scoreCleanResult(data) {
        let first;
        let second;
        data = data.split("-")
        first = data[0].replace(/[^\w\s]/gi, '');
        second = data[1].replace(/[^\w\s]/gi, '');
        return first+"-"+second;
    }

    scoreResult(data) {
        let first;
        let second;
        data = data.split("-")
        first = data[0].replace(/[^\w\s]/gi, '');
        second = data[1].replace(/[^\w\s]/gi, '');
        if(parseInt(first) > parseInt(second)) {
            return "W";
        }

        if(parseInt(second) > parseInt(first)) {
            return "L";
        }

        return "D";
    }

    commingsoom() {
        let tempdata = []

        tempdata.push(<div className="row text-center"><br /><br /><br /><h1>Comming Soon!!!</h1></div>)

        return tempdata;
    }

    form() {
        let hometeam;
        let home = this.state.form.home
        let away = this.state.form.away
        let form = []
        let tempdata = []
        let score;
        
       // console.log(this.state.data.events)
        if(this.state.form.home){
            let tempArray = []

            //tempArray.push(<tr key={100}><th>#</th><th>TEAM</th><th>H</th><th>W</th><th>D</th><th>L</th></tr>)
            console.log('-------------------')
            // Outer loop to create parent
            for (let i = 0; i < home.length; i++) {
                //children = []
                if(home[i].ft != null) {
                    //console.log(this.state.data[0].home.name)
                    hometeam = this.state.data[0].home.name
                    score = this.scoreResult(home[i].ft.score)
                    //Inner loop to create children 
                    tempArray.push(<div className="row live-header" key={`f${i}`}><b>{`${home[i].name}`}</b></div>)
                    tempArray.push(<div className="row live-footer-border" key={`s${i}`}><div className="grid-15 left"  key='1'>{`${moment(home[i].created_at).format("DD/MM/YY")}`}</div><div className="grid-30 left text-right" key='2'>{`${home[i].home.name}`}</div><div className="grid-10 left text-center" key='3'>{`${this.scoreCleanResult(home[i].ft.score)}`}</div><div className="grid-30 left text-left" key='4'>{`${home[i].away.name}`}</div><div className="grid-15 left text-center" key='5'><span className={`score-${score}  score-box`}>{`${score}`}</span></div></div>)
                }
                
            }
            tempdata.push(<div key="222"><br key="223"/><div className="color-green bg-gray live-header"><b>Last Matches:   {hometeam}</b></div>{tempArray}</div>)
            
        }

        if(this.state.form.away){
            
            let tempArray = []

            //tempArray.push(<tr key={100}><th>#</th><th>TEAM</th><th>H</th><th>W</th><th>D</th><th>L</th></tr>)
            console.log('-------------------')
            // Outer loop to create parent
            for (let i = 0; i < away.length; i++) {
               // children = []
               if(away[i].ft != null) {
                    hometeam = this.state.data[0].away.name
                    score = this.scoreResult(away[i].ft.score)
                
                    //Inner loop to create children 
                    tempArray.push(<div className="row live-header"><b>{`${away[i].name}`}</b></div>)
                    tempArray.push(<div className="row live-footer-border"><div className="grid-15 left"  key='1'>{`${moment(away[i].created_at).format("DD/MM/YY")}`}</div><div className="grid-30 left text-right" key='2'>{`${away[i].home.name}`}</div><div className="grid-10 left text-center" key='3'>{`${this.scoreCleanResult(away[i].ft.score)}`}</div><div className="grid-30 left text-left" key='4'>{`${away[i].away.name}`}</div><div className="grid-15 left text-center" key='5'><span className={`score-${score}  score-box`}>{`${score}`}</span></div></div>)
               }
            }
            tempdata.push(<div key="322"><br /><div className="color-green bg-gray live-header"><b>Last Matches:   {hometeam}</b></div>{tempArray}</div>)
            
        }
        form.push(<div className="live-content">{tempdata}</div>)
        

        return form
    }

    h2h() {
        let tempArray = []
        let data = this.state.data
       // console.log(this.state.data.events)
        if(data.length  > 0){
            console.log(this.state.data)
            // let events = this.state.data.events
            
            data.forEach(function(each){
                if(typeof(each.events) === 'object' ) {
                    let i = 0;
                    each.events.event.forEach(function(each1){
                       //let display = this.detectSide(each1.team,each1)
                        tempArray.push(<div className="row key-event-list" key={i}>  
                                <br />
                                <div className="grid-45 left text-right">
                                    {(each1.team === 'home') ? each1.minute+"'"  : "" } <br/>
                                    {(each1.team === 'home') ? each1.player  : "" } <br/>
                                    {(each1.team === 'home') ? each1.assist : ""}
                                </div>  
                                <div className="grid-10 left text-center ">
                                    {(each1.type === 'subst') ? <img src="/img/live-score/in_out.png" alt={each1.type} /> : "" }
                                    {(each1.type === 'redcard') ? <img src="/img/live-score/red_card.png" alt={each1.type} /> : "" }
                                    {(each1.type === 'yellowcard') ? <img src="/img/live-score/yellow_card.png" alt={each1.type} /> : "" }
                                    {(each1.type === 'goal') ? <img src="/img/live-score/soccer_ball.png" alt={each1.type} /> : "" }
                                </div>
                                <div className="grid-45 right">
                                    {(each1.team === 'away') ? each1.minute+"'" : "" } <br/>
                                    {(each1.team === 'away') ? each1.player : "" } <br/>
                                    {(each1.team === 'away') ? each1.assist : ""}
                                </div>
                        </div>)
                        i++;
                    })
                }
                
            })
            return tempArray
        }
    }

    keyEvent() {
        let tempArray = []
        let data = this.state.data
       // console.log(this.state.data.events)
        if(data.length  > 0){
            //console.log(this.state.data)
            // let events = this.state.data.events
            
            data.forEach(function(each){
                //console.log(each)
                //console.log(typeof(each.events))
                if(typeof(each.events) === 'object' ) {
                    let i = 0;
                    each.events.event.forEach(function(each1){
                       //let display = this.detectSide(each1.team,each1)
                        tempArray.push(<div className="row key-event-list" key={i}>  
                                <div className="grid-45 left text-right">
                                    {(each1.team === 'home') ? each1.minute+"'"  : "" } <br/>
                                    {(each1.team === 'home') ? each1.player  : "" } <br/>
                                    <span className={(each1.type === 'subst') ? "color-red" : "color-dark-gray"}>{(each1.team === 'home') ? each1.assist : ""}</span>
                                </div>  
                                <div className="grid-10 left text-center ">
                                    {(each1.type === 'subst') ? <img src="/img/live-score/in_out.png" alt={each1.type} /> : "" }
                                    {(each1.type === 'redcard') ? <img src="/img/live-score/red_card.png" alt={each1.type} /> : "" }
                                    {(each1.type === 'yellowcard') ? <img src="/img/live-score/yellow_card.png" alt={each1.type} /> : "" }
                                    {(each1.type === 'goal') ? <img src="/img/live-score/soccer_ball.png" alt={each1.type} /> : "" }
                                </div>
                                <div className="grid-45 right">
                                    {(each1.team === 'away') ? each1.minute+"'" : "" } <br/>
                                    {(each1.team === 'away') ? each1.player : "" } <br/>
                                    <span className={(each1.type === 'subst') ? "color-red" : "color-dark-gray"}>{(each1.team === 'away') ? each1.assist : ""}</span>
                                </div>
                        </div>)
                        i++;
                    })
                }
                
            })
            return tempArray
        }
    }

    table() {
        let data = this.state.standings  
        let table = []
        
       // console.log(this.state.data.events)
        if(data.length  > 0){
            
            let standings = this.state.standings
            let tempArray = []
            let children;
            tempArray.push(<tr key={100}><th>#</th><th>TEAM</th><th>MP</th><th>W</th><th>D</th><th>L</th><th>G</th><th>Pts</th></tr>)
            console.log('-------------------')
            // console.log(this.state.standings)
            // Outer loop to create parent
            for (let i = 0; i < standings.length; i++) {
                children = []
                //Inner loop to create children
                children.push(<td key='0'>{`${i+1}`}</td>)
                children.push(<td key='1'>{`${standings[i].name}`}</td>)
                children.push(<td key='2'>{`${standings[i].overall.played}`}</td>)
                children.push(<td key='3'>{`${standings[i].overall.win}`}</td>)
                children.push(<td key='4'>{`${standings[i].overall.draw}`}</td>)
                children.push(<td key='5'>{`${standings[i].overall.lose}`}</td>)
                children.push(<td key='6'>{`${standings[i].overall.goals_scored+":"+standings[i].overall.goals_attempted}`}</td>)
                children.push(<td key='7'>{`${standings[i].totals.points}`}</td>)
                
                //Create the parent and add the children
                tempArray.push(<tr key={i}>{children}</tr>)
            }
            table.push(<table key={0} width="100%" className="live-content"><br/><tbody>{tempArray}</tbody></table>)
            
        }
        return table
    }

    summary() {
        let stats = []
        let leagues = [ '3037','3232', '3054', '3102', '3062'];
        let data = this.state.data
        let i = 1;
        //console.log(data)
        if(data.length  > 0){
            let tempArray = []
            if(leagues.indexOf(data[0].league_id) > -1) {
                data.forEach(function(each){
                    //let wday = moment(each.date, 'DD.MM.YYYY').isoWeekday();
                    tempArray.push(<div className="live-content text-center" key={i}><div className="row"><br /><img src={"/img/league/"+data[0].league_id+".png"} alt=""  className="detail-icon"  alt="Whistle"  /><br/><p>{data[0].name}</p><br/><img src='/img/whistle.jpg' alt=""  className="detail-icon"   alt="Whistle" /><p>{moment(each.created_at).format('DD MMM YYYY - hh:mm')}</p><img src='/img/stadium.jpg' alt=""  className="detail-icon"   alt="Stadium" /><h1>{data[0].venue}</h1></div></div>)
                    i++;
               })
            } else {
                data.forEach(function(each){
                    //let wday = moment(each.date, 'DD.MM.YYYY').isoWeekday();
                    tempArray.push(<div className="live-content text-center" key={i}><div className="row"><br /><img src='/img/whistle.jpg' alt=""  className="detail-icon"   alt="Whistle" /><br/><p>{moment(each.created_at).format('DD MMM YYYY - hh:mm')}</p><br/><img src='/img/stadium.jpg' alt=""  className="detail-icon"   alt="Stadium" /><h1>{data[0].venue}</h1></div></div>)
                    i++;
               })
            }
            
            
            return tempArray
        }

        return  stats
    }
    render() {

        return (
            <div className="page">
                <div className="page-content">
                    
                     {this.displayBlock()}
                    <div className="row live-sub-menu">
                        <Grid data={data1}
                                columnNum={5}
                                renderItem={dataItem => (
                                    <div onClick={this.subMenuClick.bind(this, dataItem.short)}>
                                        <img src={dataItem.icon}  alt="" />
                                        <div style={{ color: '#888', fontSize: '9px', marginTop: '3px' }}>
                                        <span className={(dataItem.short === this.state.selected_sub_menu) ? 'active' : '' }>{dataItem.text}</span>
                                        </div>
                                    </div>
                                )}
                            />
                        
                    </div>
                    {this.viewHandler()}
                    <div className="ant-back-footer">
                        <div class="ant-back-top">
                            <div class="ant-back-top-content">
                                <Link to={window.shwesports.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null) 
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
      }
      
export default LiveScoreMatchDetailPage;
 