import React, { Component } from 'react';
import axios from 'axios';
import {
  Link
} from 'react-router-dom';
//import ReactHLS from 'react-hls-player';
import renderHTML from 'react-render-html';
import { Player } from 'video-react';
// import ReactHLS from 'react-hls-player';
import HLSSource from "./../../component/HLSSource";

import Chat from "./../../component/Chat/Chat";
import 'video-react/dist/video-react.css';
// import 'react-hls/src/style.css'; // need to import basic styles
// import 'react-hls/src/icons.css'; // need to import basic icons
//let useragentcheck = (navigator.userAgent.toLowerCase().indexOf("mac") > -1 );

class SuperAppPage extends Component {
  state = {
    video: {
        //src: "https://live.shwesports.com/live.m3u8",
        // livesrc: "https://api.new.livestream.com/accounts/22774234/events/6897936/live.m3u8",
        //src: "http://ott.artmott.com/ArenaSport1MW/tracks-v1a1/mono.m3u8",
        name: "Channel 1",
        description : "",
        // src: "https://stream.shwesports.com:9200/hls/shwesports.m3u8",

        // src : "http://172.104.184.216:9100/chone/hls/shwesports.m3u8",
        // src : "https://live.myanball.com/sschone/hls/shwesports.m3u8",
        src : "https://live.myanball.com/spremier/hls/output_video.m3u8",
        
        poster2: "http://www.example.com/path/to/video_poster.jpg",
        advertise: {},

    },
    total : 1,
    isloading : false
    // isloading : true
  }

  hisUrl(url) {
    if(localStorage.getItem("route")) {
      let menu = JSON.parse(localStorage.getItem("route"));
      //console.log(menu.length+"-------------")
      if(menu.length === "0") {
        let data = ["/"];
        localStorage.setItem("route", JSON.stringify(data) );
      }
       if(menu.length > 0) {
          //console.log(menu[menu.length - 1] + "-" + url )
            if(menu[menu.length - 2] === url ){
                //console.log("first");
                menu.pop();
                //window.shwesports.route = JSON.stringify(menu);
                localStorage.setItem("route", JSON.stringify(menu) );
                //localStorage.setItem("route", JSON.stringify(menu) );
            }
            else if(menu[menu.length-1] !== url ){
                menu.push(url)
                //window.shwesports.route = JSON.stringify(menu);
                localStorage.setItem("route", JSON.stringify(menu) );
            }
            window.shwesports.preurl =  menu[menu.length-2];
        }
    }
  }

  fetchUserCount(url,data) {

        let dataurl = url;

        // let today = moment(new Date()).format("YYYY-MM-DD");

        // this.fetchUserCount(window.shwesports.apiUrl+'/user/channels/livecount',7);

        // let todayurl = window.shwesports.apiUrl+'/dbsoccer/livescore/'+today
        let livecount  = window.shwesports.apiUrl+'/user/channels/livecount?cid='+data+'&total='+this.state.total;

        // if(url === livecount) {
            clearTimeout(window.shwesports.livecounturl)
            //27000 miliseconds
            //myVar =  setTimeout(() => this.fetchUserCount(url,'livescore'), 18000);27000
            window.shwesports.livecounturl =  setTimeout(() => this.fetchUserCount(url,'6'), 50000);
        // } else {
        //     clearTimeout(window.shwesports.livecounturl)
        // }

        axios.get(livecount, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
            //console.log(data)
            this.setState({
                total: resp.data.total
            });
        })
        .catch(function(error) {
          //console.log(error.tostring());
        });

  }

  getfetchApi() {
    var self = this;
    let dataurl = window.shwesports.apiUrl+'/user/channels/info?cid=6';
    let data = this.state.data;
    axios({
        method: 'get',
        url: dataurl,
        data: data,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    })
    .then(function (response) {
        // console.log(response.data.data[2]);
        if(response.data.data) {
            self.setState({

              //temp disable
              // video : response.data.data[0],
                video: {
                  //src: "https://live.shwesports.com/live.m3u8",
                  // livesrc: "https://api.new.livestream.com/accounts/22774234/events/6897936/live.m3u8",
                  //src: "http://ott.artmott.com/ArenaSport1MW/tracks-v1a1/mono.m3u8",
                  name: response.data.data[0].name,
                  description : response.data.data[0].description,
                  // src : response.data.data[0].src,
                  // src : 'http://172.104.184.216:9100/chone/hls/shwesports.m3u8',
                  src : ' https://live.myanball.com/spremier/hls/output_video.m3u8',  
                  // src: "https://stream.shwesports.com:9200/hls/shwesports.m3u8",
                  // src : "http://mobile.shwesports.com:9100/chone/hls/shwesports.m3u8",
                  poster2: "http://www.example.com/path/to/video_poster.jpg"
              },
              advertise : response.data.advertise,
              isloading : false
            });
            //self.setState({  message : "Successfully updated" });
            //console.log(response.data.data)
        } else {
            self.setState({  message : "Something was wrong" });
        }
        //console.log(response.data.success);
    })
    .catch(function (error) {
        //console.log(error);
    });
}



  componentDidMount() {
    this.hisUrl('/channel1');

    if(localStorage.getItem(window.shwesports.time)) {
          if(localStorage.getItem(window.shwesports.time) !== "0" ) {
              document.getElementById( 'toggle-home' ).style.display = 'none';
          }
    }
    // document.getElementById( 'toggle-home' ).style.display = 'none';

    clearTimeout(window.shwesports.liveurl)
    clearTimeout(window.shwesports.livecounturl)
    clearTimeout(window.shwesports.livecounturl2)

    this.getfetchApi();


    // this.fetchUserCount(window.shwesports.apiUrl+'/user/channels/livecount?cid=6&total='+this.state.total,6);
}

 render() {

   return (
        <div className="page">
          <div className="page-content">

              { (this.state.isloading) ? (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> )  :
                  <div className="row text-center">
                    <Player>
                      <HLSSource
                        isVideoChild
                        src={this.state.video.src}
                      />
                    </Player>
                  </div>
              }

                {/* { (this.state.isloading) ? (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> )  :
                 ((useragentcheck) ?
                 <div className="row text-center">
                    <Player>
                      <HLSSource
                        isVideoChild
                        src={this.state.video.src}
                      />
                    </Player>
                  </div>
                :  <ReactHLS url={this.state.video.src}  autoplay="true"/> ) }  */}

                <div className="row">
                    <div className="outerContainer chlivechat">
                        <div className="container-chat1">
                            <div className="infoBar">
                              <div className="leftInnerContainer">
                                <img className="onlineIcon" src="/icons/onlineIcon.png" alt="online icon" /><h3>Super Sport Channel </h3>
                              </div>
                            <div className="rightInnerContainer">
                              <a href="/"><img src="/icons/closeIcon.png" alt="close icon" /></a>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>


                  {/* <div className="list-header">
                      <img src="/img/badge.jpg" className="list-img-circle" alt={this.state.video.name} /><span>{this.state.video.name}</span>
                  </div>
                  <br/> */}
                  {/* {
                    (this.state.advertise) ? <div className="row">
                      <div className="grid">
                        <a href={this.state.advertise.advertise_goto} target="_blank" rel="noopener noreferrer">
                          <img src={window.shwesports.apiAssetUrl+"/images/"+this.state.advertise.advertise_image} style={{ width: '100%' }} alt={this.state.advertise.advertise_title}/>
                          </a>
                          {/* <br/> */}
                      {/* </div>
                    </div>: ""
                  } */}
                    {
                    (this.state.advertise) ? <div className="row">
                      <div className="grid1">
                        <a href={this.state.advertise.advertise_goto} target="_blank" rel="noopener noreferrer">
                          <img src={window.shwesports.apiAssetUrl+"/images/"+this.state.advertise.advertise_image} style={{ width: '100%' }} alt={this.state.advertise.advertise_title}/>
                          </a>
                          {/* <br/> */}
                      </div>
                    </div>: ""
                  }
                  

                <div className="main-content">
                <div className="league-price-table live-table row">
                    <br />
                        <div className="flex-sport league-price-table">
                            <div className='bein'>
                              <Link to={"/channel1"}>
                                <div style={{textAlign:'center',color:'white'}}>
                                Channel 1
                                </div>
                              </Link>
                            </div>
                            <div className='super'>
                              <Link to={"/channel2"}>
                                <div style={{textAlign:'center',color:'white'}}>
                                Channel 2
                                </div>
                              </Link>
                            </div>
                            <div className='bein'>
                              <Link to={"/beinsport"}>
                                <div style={{textAlign:'center',color:'white'}}>
                                BeinSports
                                </div>
                              </Link>
                            </div>
                            <div className='super'>
                              <Link to={"/supersport"}>
                                <div style={{textAlign:'center',color:'white'}}>
                                  Super Sports
                                </div>
                              </Link>
                            </div>
                            {/* <div className='bein'>
                          <Link to={"/movies"}>
                            <div style={{textAlign:'center',color:'white'}}>
                            Movies
                            </div>
                          </Link>
                          
                        </div> */}
                        </div>
                  <hr />
                    {renderHTML(this.state.video.description)}
                  </div>
                </div>
                <div className="page-content text-left setting-menu">
                    <ul>
                      <Link  to={"/channel"}><li>ပွဲစဉ် အချိန်ဇယားများကြည့်ရန်<span>&gt;</span></li></Link>
                    </ul>
                </div>



                    {/* <div class="ant-back-top"><div class="ant-back-top-content">Back<div class="ant-back-top-icon"></div></div></div> */}
                    <div className="ant-back-footer">
                        <div className="ant-back-top">
                            <div className="ant-back-top-content">
                                <Link to={window.shwesports.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null)
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
   );
 }
}

export default SuperAppPage;
