import React from 'react';
import axios from 'axios';
import { Modal }  from 'antd-mobile';
import {
    Link
  } from 'react-router-dom';


const alert = Modal.alert;
var moment = require('moment');

class MyAccPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { step: "profile", message : "", form : {username: '', phone: '',verify_code: '', password: '' },id:"",username:"",phone:"",created_at:"",gem:"",league_points:"0",max_league_points:"0",rank:"", editform : false};
        this.userLogout         = this.userLogout.bind(this);
        this.langSwitch         = this.langSwitch.bind(this);
        this.userNameEdit       = this.userNameEdit.bind(this);
        this.userNameSubmit     = this.userNameSubmit.bind(this);
    }

    lpad(value, padding) {
        var zeroes = new Array(padding+1).join("0");
        return (zeroes + value).slice(-padding);
    }


    
    userNameEdit() {
        this.setState({editform : true});
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.shwesports.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }  
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.shwesports.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.shwesports.preurl =  menu[menu.length-2];
            }
        }
      }

    fetchUserData() {
        
        const url = window.shwesports.apiUrl+'/user/token/'+localStorage.getItem('time');
    
        axios.get(url, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
          // console.log(this.data.concat(resp.data));
          //let latest_data = resp.data;
          //  console.log('-----------------------')
            //console.log(latest_data)
            //console.log(resp.data);
            this.setState({
              id : resp.data.id,
              username : resp.data.username,
              phone : resp.data.phone,
              created_at : resp.data.created_at,
              gem       : resp.data.gem,
              league_points : resp.data.league_points,
              max_league_points : resp.data.max_league_points,
              rank : resp.data.rank
            });
            
          })
          .catch(function(error) {
            // console.log(error);
          });   
    }

    componentDidMount() {
        // console.log(mmph.normalizeInput('+၉၅၉၇၈၄၁၂၃၄၅၆')); // returns 09784123456
        // console.log(mmph.normalizeInput('+959784123456')); // returns 09784123456
        // console.log(mmph.getTelecomName('09978412345')); // returns Ooredoo
        // console.log(mmph.getPhoneNetworkType('09978412345')); // returns GSM
        // console.log(mmph.isValidMMPhoneNumber('09978412345')); // returns true if it meets Myanmar Phone
        if(localStorage.getItem('time')) {
            if(localStorage.getItem('time') !== "0" ) {
              //localStorage.setItem('time', true);
                
              } else {
                this.props.history.push("/more");
                }
          } else {
              this.props.history.push("/more");
          }

        this.fetchUserData();

       
        this.hisUrl('/more/myaccount');
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        // this.state.username
        // let form = {...this.state.form};
        // form[e.target.name] = e.target.value;
        this.setState({
            username : e.target.value
        });
    }

    langSwitch() {

        if(localStorage.getItem('lang') === 'en') {
            localStorage.setItem('lang', 'mm');
            window.lang = window.mmlang;
        } else {
            localStorage.setItem('lang', 'en');
            window.lang = window.enlang;
        }
        
        // console.log(window.lang)
        this.props.history.push("/more/myaccount");

        //console.log(this.state.step);
     
        // alert('Message', 'အကောင့်မှ ထွက်ရန် သေချာပါသလား။', [
        //     { text: 'မထွက်ပါ', onPress: () => console.log('cancel') },
        //     {
        //     text: 'ထွက်မည်',
        //     onPress: () => {
                    
        //         }
                
        //     },
        // ])
    }

    userLogout() {

        alert('Message', 'အကောင့်မှ ထွက်ရန် သေချာပါသလား။', [
            { text: 'မထွက်ပါ', onPress: () => console.log('cancel') },
            {
            text: 'ထွက်မည်',
            onPress: () => {
                    localStorage.setItem('time', 0);
                    localStorage.setItem('username', "");
                    this.setState({ step: "login" , message : "Successfully Log Out" }); 
                    this.props.history.push("/");
                }
                
            },
        ])
    }



    userNameSubmit() {
        var self = this;
        let dataurl = window.shwesports.apiUrl+'/user/name/edit';
        axios({
            method: 'post',
            url: dataurl,
            data: {salt : localStorage.getItem('time'), username : this.state.username},
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then(function (response) {
            if(response.data.success) {
                self.setState({  
                    message : "Successfully Login",
                    editform : false
                });
                // localStorage.setItem('time', self.state.form.phone );
                // localStorage.setItem('username', response.data.username );
                // self.props.history.push("/contest");
            } else {
                self.setState({  message : "Something was wrong", editform : true });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

   

    viewHandler() {
        if(this.state.step){
            switch(this.state.step) {
                case "home":    return this.homeBlock();
                case "profile": return this.profileBlock();
                default:        return "";
              }
        }
       
    }

    loginBlock() {
        return <div className="container">
                <div className="row">
                    <div className="grid-30 grid-s-100 left">
                        <div className="form-group text-left">
                            <label>Phone Number</label>
                        </div>
                    </div>
                    <div className="grid-70 grid-s-100 left">
                        <div className="form-group text-center">
                        <input type="text" className="form-control text-center" placeholder="09**********" name="phone" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.phone} /><br />
                        </div>
                    </div>                   
                </div>
                <div className="row">
                    <div className="grid-30 grid-s-100 left">
                        <div className="form-group text-left">
                            <label>Password</label>
                        </div>
                    </div>
                    <div className="grid-70 grid-s-100 left">
                        <div className="form-group text-center">
                        <input type="password" className="form-control text-center"  name="password" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.password} /><br />
                        </div>
                        <input type="submit" className="btn btn-success grid-50" value="Submit" onClick={this.validatePhone} /><br />
                    </div>                   
                </div>
        </div>
    }

    profileBlock() {
      return <div>
          <div className="row">
              <h2>Hi {((this.state.form.username)? this.state.form.username : "Customer")}</h2>
              <p>Welcome to Shwesports</p>
              <input type="submit" className="btn btn-blue grid-50" value="Log Out" onClick={this.userLogout} />
              {/* <a className="btn btn-blue grid-50" onClick={this.userLogout}>Logout</a> */}
          </div>
      </div>
   }

    homeBlock() {
        return <div>
            <div className="row">
                <a href="/more/login" className="btn btn-success grid-50">Log in</a><br /><br />
                <a href="/more/sign-up" className="btn btn-blue grid-50">Sign up</a>
            </div>
        </div>
    }

    render() {
        return (
            <div className="page">
                <div className="page-content text-left">
                         
                        <br/>
                        <div className="list-header">
                            <img src="/img/badge.jpg" className="list-img-circle" alt={window.lang.more.my_account} /><span>{window.lang.more.my_account}</span>
                        </div>
                        
                        <br />
                        <div className="row text-left">
                            <ul className="secondsubmenu">
                                <li>
                                    <Link to="/more/bill-history" className="btn-success btn" >
                                        {window.lang.title.bill_history}
                                    </Link> 
                                </li>
                                <li>
                                    <Link to="/more/league-history" className="btn-success btn" >
                                        {window.lang.title.league_history}
                                    </Link> 
                                </li>
                                <li>
                                    <Link to="/more/inbox" className="btn-success btn" >
                                        {window.lang.title.inbox}
                                    </Link> 
                                </li>
                            </ul>
                        </div>
                        <br/>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>{window.lang.form.user}</td>
                                    {(this.state.editform) ?  <td><input value={this.state.username} onChange={(e) => this.inputChangeHandler.call(this, e)}  name="username"  className="form-control"/> <br/ ><b className="color-blue btn btn-success" onClick={this.userNameSubmit}>Submit</b></td> : <td>{this.state.username} (<b className="color-blue" onClick={this.userNameEdit}>Edit</b>)</td> }
                                </tr>
                                <tr>
                                    <td>{window.lang.form.id}</td>
                                    <td>{this.state.id}</td>
                                </tr>
                                <tr>
                                    <td className="info-indicator">{window.lang.form.gems} 
                                        {/* <Tooltip placement="bottom" title={text}>
                                            <span  title="text">?</span>
                                        </Tooltip> */}
                                    </td>
                                    <td><span className="color-green">{this.state.gem}</span></td>
                                </tr>
                                <tr>
                                    <td>{window.lang.form.point}</td>
                                    <td><span className="color-green">{this.state.league_points}</span></td>
                                </tr>
                                <tr>
                                    <td>{window.lang.form.hightest_point}</td>
                                    <td><span className="color-green">{this.state.max_league_points}</span></td>
                                </tr>
                                <tr>
                                    <td>{window.lang.form.rank}</td>
                                    <td><span className="color-green">{this.state.rank}</span></td>
                                </tr>
                                <tr>
                                    <td>{window.lang.form.join_date}</td>
                                    <td>{moment(this.state.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY')}</td>
                                </tr>
                                <tr>
                                    <td>{ window.lang.form.language } ({(localStorage.getItem('lang') === 'mm') ? 'Myanmar': 'English'})</td>
                                    <td>
                                        <label className="switch">
                                            <input type="checkbox"   onChange={this.langSwitch}/>
                                            <span className="sslider round"></span>
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                     <div className="setting-menu row">
                        <ul>
                            {/* <li>User ID     - {this.state.id}</li>
                            <li>Point       - {this.state.league_points}pts</li>
                            <li>Join Date   - {this.state.created_at}</li> */}
                            {/* <li><Link to="/more/league-contest">League Contest</Link></li> */}
                            {/* <li>ID          - SP{this.lpad(1,5)} </li> */}

                            {/* <Link  to="/more/bill-history"><li>Bill History <span>&gt;</span></li></Link>
                            <Link  to="/more/league-history"><li>League History <span>&gt;</span></li></Link> */}
                            {/* <a   onClick={this.langSwitch}><li>Language Switch <span className="color-green">En</span> </li></a> */}
                            <a   onClick={this.userLogout}><li>{window.lang.form.logout} </li></a>
                            {/* <a
                                onClick={() =>
                                    
                                }
                                >
                                <li>Log Out <span>&gt;</span></li>
                            </a> */}
                        </ul>
                    </div>


                    <div className="ant-back-footer">
                        <div className="ant-back-top">
                            <div className="ant-back-top-content">
                                <Link to={window.shwesports.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null) 
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link> 
                            </div>
                        </div>
                    </div>
                   
                </div>
               
            </div>
        );
    }
}

export default MyAccPage;