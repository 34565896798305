/* eslint no-dupe-keys: 0 */
import React from 'react';

//import Swiper from 'swiper/dist/js/swiper.min.js';

import Carousel from 're-carousel'
// import {
//   Link
// } from 'react-router-dom';

import axios from 'axios';

let NUM_ROWS = 4;
// let pageIndex = 0;

class NewsVideoSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      // dummy slides data
      // slides: (function () {
      //   var slides = [];
      //   for (var i = 0; i < 600; i += 1) {
      //     slides.push('Slide ' + (i + 1));
      //   }
      //   return slides;
      // }()),
      // virtual data
      videoSlides: [],
      virtualVideoData: {
        slides: [
          {
            "id_news": "31",
            "news_name": "ဂ်ာမနီ 1-1အီတလီ (pen 6-5 )",
            "news_link": "https://www.youtube.com/watch?v=JXZ3FmTk_eI",
            "news_embed": "http://www.youtube.com/embed/JXZ3FmTk_eI",
            "news_image": "https://img.youtube.com/vi/JXZ3FmTk_eI/mqdefault.jpg",
            "news_desc": "<p>ဂ်ာမနီ 1-1အီတလီ (pen 6-5 )</p>",
            "created_at": "2018-12-19 09:39:07",
            "updated_at": "2018-12-19 15:12:36"
          }
        ]
      },
      leagueMenu: 'NEWS',
      date: new Date()
    };

    // console.log(this.props)
    // if(this.props.videoData) {
    //   // console.log()
    //   console.log( this.props.videoData)
    //   this.state.virtualVideoData.slides = this.props.videoData
    // }

   
    
  }
  onSelect = key => {
    this.setState({ selected: key });
  }

  componentWillMount() {
    this.fetchData();
  }

  componentDidMount() {
    if(localStorage.getItem('videoSlides')) {
        
      if(JSON.parse(localStorage.getItem("videoSlides")) != null) {
        let video_slides = JSON.parse(localStorage.getItem("videoSlides"));
        this.setState({videoSlides: video_slides})
      }
    } 

    //setTimeout(() => {
      this.videoSliders();
    //}, 600);
    
    // this.timerID = setInterval(
    //   () => this.tick(),
    //   1000
    // );
    

  }

  async videoSliders() {
    await this.fetchData();

    // new Swiper('.swiper-video-container', {
    //   // ...
    //   loop: true,
    //   // slidesPerView: 'auto',
    //   pagination: {
    //     el: '.swiper-vieo-pagination',
    //   },
    // });
  }

  // tick() {
  //   this.setState({
  //     date: new Date()
  //   });
  // }

  fetchData() {
        
    const url = window.shwesports.apiUrl+'/news?limit='+NUM_ROWS+'&start=10&featured=0';

    axios.get(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "no-cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      //body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    .then(resp => {
      // console.log(this.data.concat(resp.data));
      //data = resp.data;
      //this.state.videoSlides = resp.data;
    //   this.state.videoSlides = data
      // this.setState({
      //       videoSlides : resp.data
      //   });
      let data = resp.data.news;
      
      //this.setState({videoSlides: resp.data.news})

      localStorage.setItem("videoSlides", JSON.stringify(data) )

      // console.log(localStorage.getItem("videoSlides")+"-----------");

      if(JSON.parse(localStorage.getItem("videoSlides"))) {
        let video_slides = resp.data.news;
        this.setState({videoSlides: video_slides})
      }
      // this.state.videoSlides = resp.data
      //this.setState.virtualVideoData.slides.push(resp.data)
      //let this.key = this.data.length;
    //   console.log(this.state.videoSlides)
    //   console.log(this.state.virtualVideoData)
    //   console.log("-----------------");
    // //   console.log("----lenght---"+data.length)
    //   console.log("-----------------");
        
        
        // this.setState({
        //   dataSource: this.state.dataSource.cloneWithRows(data.reverse()),
        //   isLoading: false,
        // });

        // this.data = { ...this.data, ...genData(++this.pageIndex) };
        // console.log(this.data)
        // NUM_ROWS = parseInt(NUM_ROWS) + parseInt(NUM_ROWS);
        //console.log(this.pageIndex)
        
      })
      .catch(function(error) {
        // console.log(error);
      });   
  }
  // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.dataSource !== this.props.dataSource) {
  //     this.setState({
  //       dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
  //     });
  //   }
  // }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    // if (this.state.isLoading && !this.state.hasMore) {
    //   return;
    // }
    // console.log('reach end', event);
    // this.setState({ isLoading: true });
    // setTimeout(() => {
    //   this.rData = { ...this.rData, ...genData(++pageIndex) };
    //   this.setState({
    //     dataSource: this.state.dataSource.cloneWithRows(this.rData),
    //     isLoading: false,
    //   });
    // }, 1000);
  }

  render() {
    
    return  <div className="swiper-video-container1 swiper-video-height" >
        <Carousel loop auto >
            {/* <div style={{backgroundColor: 'tomato', height: '100%'}}>Frame 1</div>
            <div style={{backgroundColor: 'orange', height: '100%'}}>Frame 2</div>
            <div style={{backgroundColor: 'orchid', height: '100%'}}>Frame 3</div> */}
            {this.state.videoSlides.map((slide, index) => (
                <div className="swiper-slide1"
                key={index} style={{left: `${this.state.videoSlides.offset}px`,background:'url('+window.shwesports.apiAssetUrl+'/images/'+slide.news_image+')', backgroundSize:'cover'}}
                >
                {/* <div className="swiper-slide"
                key={index}
                style={{left: `${this.state.virtualVideoData.offset}px`}}
                > */}
                  <a
                      href={`/news/${slide.id_news}`}
                      style={{ display: 'inline-block', width: '100%', height: '200px' }}
                      >
                    <div className="h-70">
                    </div>
                    <div className="h-30 swiper-paragraph">
                        <p >{slide.news_name}</p>
                    </div>
                  </a>
                </div>
             ))}
        </Carousel>
        </div>
    // return (
        
    //     <div className="swiper-container swiper-video-container">
    //         <div className="swiper-wrapper">
    //         {/* It is important to set "left" style prop on every slide */}
    //         {this.state.videoSlides.map((slide, index) => (
    //             <div className="swiper-slide"
    //             key={index} style={{left: `${this.state.videoSlides.offset}px`}}
    //             >
    //             {/* <div className="swiper-slide"
    //             key={index}
    //             style={{left: `${this.state.virtualVideoData.offset}px`}}
    //             > */}
    //             <a
    //                 href={`/news/${slide.id_news}/${slide.news_name}`}
    //                 style={{ display: 'inline-block', width: '100%', height: '200px' , background:'url('+window.shwesports.apiAssetUrl+'/images/'+slide.news_image+')', backgroundSize:'cover'}}
    //                 >
    //                 <p className="swiper-paragraph">{slide.news_name}</p>
    //             </a>
                   
    //             </div>
    //         ))}
    //         </div>
    //         <div className="swiper-vieo-pagination swiper-pagination"></div>
    //     </div>
          
    // );
  }
}

export default NewsVideoSlider;