import React,{Component} from 'react';
// import {Helmet} from "react-helmet";
import {
    Link
  } from 'react-router-dom';
import axios from 'axios';
import renderHTML from 'react-render-html';

var moment = require('moment');
  
  // One item component
  // selected prop will be passed
  const MenuItem = ({ activeMenu, data, text, selected }) => {
    return (
      
        <div
        className="menu-item"
        >
          {data.id === "0" ? (
            <Link to={`/${data.url}`} className={(activeMenu === text) ? 'active' : '' } >{data.shortname}</Link>
          ) : (
            <Link to={`/news/league/${data.id}/${data.url}`} className={(activeMenu === text) ? 'active' : '' } >{data.shortname}</Link>
          )}
          {/* <Link to="/about" className={(activeMenu === text) ? 'active' : '' }  > */}
           
            {/* <span>{text}</span> */}
  
          {/* </Link> */}
        </div>
    );
  };
  
  
  // All items component
  // Important! add unique key
  export const Menu = (list,selected,activeMenu) => list.map(el => {
    const { name,shortname } = el;
    // console.log(selected)
    return (
      <MenuItem
        activeMenu={activeMenu}
        text={shortname}
        key={name}
        data={el}
      />
    );
  });
  

class TipstersListDetailPage extends Component {
    constructor(props) {
      super(props);
  
      window.shwesports.footerMenu = "CONTEST";
      this.state = {
        data : {
            title : '',
            description : '',
            created_at: ''
        },
        latest_data : [],
        isLoading: true,
        liked : 0,
        tipster_id : 0,
        tipsterdata_id : 0,
      };


        if(props.match.params.id) {
            this.state.tipster_id = props.match.params.id
            this.state.tipsterdata_id = props.match.params.id2
        }
    }

 

    componentDidMount() {
      
        if(localStorage.getItem('time')) {
          if(localStorage.getItem('time') !== "0" ) {
            //localStorage.setItem('time', true);
            // this.setState({ step: "list" }); 
          } else {
            this.props.history.push("/more/sign-up");
            }
        } else {
            this.props.history.push("/more/sign-up");
        }
        
        this.fetchData();
        this.setState({ loading: true });
        this.hisUrl('more/tipsters/'+this.state.tipster_id+'/detail/'+this.state.tipsterdata_id)
    }

    hisUrl(url) {
      if(localStorage.getItem("route")) {
        let menu = JSON.parse(localStorage.getItem("route"));
        //console.log(menu.length+"-------------")
        if(menu.length === "0") {
          let data = ["/"];
          localStorage.setItem("route", JSON.stringify(data) );
        } 
         if(menu.length > 0) {
            //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                  //console.log("first");
                  menu.pop();
                  //window.shwesports.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  //localStorage.setItem("route", JSON.stringify(menu) );
              }  
              else if(menu[menu.length-1] !== url ){
                  menu.push(url)
                  //window.shwesports.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
              }
              window.shwesports.preurl =  menu[menu.length-2];
          }
      }
    }

    componentDidUpdate() {
      
    }

    fetchData() {
        let dataurl = window.shwesports.apiUrl+'/tipsters/detail/'+this.state.tipsterdata_id;

        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
            this.setState({
                data: resp.data.data
            });
            this.setState({ isLoading: false });
            // console.log(this.state.data);
        })
        .catch(function(error) {
          // console.log(error.tostring());
        });   
 
    }

    

    displayBlock() {
        let ui = []
        // console.log("liked--------"+this.state.liked)
        if(this.state.data  != null){
            ui.push(
                <div className="main-content" key="1">
                    <div className="row content-box">
                        <br/>
                        <h2>{this.state.data.title}</h2>
                        <p>{moment(this.state.data.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY, hh:mm A')}</p>
                        <p>
                            { renderHTML(this.state.data.description) }
                        </p>
                    </div> 
                    
                   
                </div>
            )
        }

        if(ui.length === 0) {
            ui.push(<div className="main-content"><div className="text-center" key="0"><h2>Something was found.</h2></div></div>)
       }
        
        return ui
    }


    
    render() {

        return (
            <div className="page">
                <div className="page-content">
                    
                        { (this.state.isLoading) ?  (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> ) : this.displayBlock() }
                           
                        {/* <FacebookProvider appId="1750258531872776">
                            <Comments href="http://localhost" />
                            <CommentsCount href="http://localhost" /> Comments
                        </FacebookProvider> */}
                        <div className="ant-back-footer">
                            <div className="ant-back-top">
                                <div className="ant-back-top-content">
                                    <Link to={window.shwesports.preurl} className="color-green" >
                                { (JSON.parse(localStorage.getItem("route")) !== null) 
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link> 
                                </div>
                            </div>
                        </div>
                        
                </div>
            </div>
            );
        }
      }                                                                                         
      
export default TipstersListDetailPage;
 