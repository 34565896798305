/* eslint no-dupe-keys: 0 */
import React from 'react';
import { ListView } from 'antd-mobile';

// import { FacebookProvider, CommentsCount } from 'react-facebook';

import {
  Link
} from 'react-router-dom';

import axios from 'axios';
var moment = require('moment');

let NUM_ROWS = 100;
let LIMIT_ROWS = 100;
let TOTAL_INDEX = 0;
//let MAX_LENGTH = 90;
let MAX_LENGTH = 200;

function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}

class HomeListPage extends React.Component {
  constructor(props) {
    super(props);

    

    var dataSource = new ListView.DataSource({
      rowHasChanged: (r1, r2) => r1 !== r2
    });

    this.data = [];
    this.state = {
      
      dataSource: dataSource.cloneWithRows(this.data),
      news      : [],
      isLoading: false,
      advertise: []
      
    };

    this.pageIndex = 1;
    TOTAL_INDEX = 0;
    NUM_ROWS = 20;
    if(this.props.perPage) {
      NUM_ROWS = this.props.perPage;
      TOTAL_INDEX = this.props.pageIndexTotal;
      //console.log(TOTAL_INDEX)
      this.state.isLoading = false;
      
    }
  }

  componentDidMount() {

    if(localStorage.getItem('home_advertise')) {
        
      if(JSON.parse(localStorage.getItem("home_advertise")) != null) {
        let home_advertise = JSON.parse(localStorage.getItem("home_advertise"));
        this.setState({advertise: home_advertise})
      }
    } 
    // if(localStorage.getItem('latest_news')) {
    //   if(JSON.parse(localStorage.getItem("latest_news")) != null) {
    //     let latest_news = JSON.parse(localStorage.getItem("latest_news"));
    //     this.setState({news: latest_news})
        
    //   }
    // } 
    if(localStorage.getItem('latest_news') == 'undefined') {
        if(JSON.parse(localStorage.getItem("latest_news")) != null) {
          let latest_news = JSON.parse(localStorage.getItem("latest_news"));
          this.setState({news: latest_news})
          
        }
    }

    this.fetchData();

    //TOTAL_INDEX = 20;
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);

    // // simulate initial Ajax
    // setTimeout(() => {
    //   this.fetchData();
    // }, 600);

  }

  componentWillUnmount() {
    // clearInterval(this.state)
  }

  fetchData() {
        
        const url = window.shwesports.apiUrl+'/news?limit='+NUM_ROWS+'&start=10&featured=0';

        axios.get(url, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
          // console.log(this.data.concat(resp.data));
          this.data = resp.data.news;
          // console.log("-----------------");
            
            this.setState({
              news      : this.data,
              // dataSource: this.state.dataSource.cloneWithRows(this.data.reverse()),
              isLoading: false,
              advertise: resp.data.advertise
            });

            localStorage.setItem("latest_news", JSON.stringify(resp.data.news) )

            localStorage.setItem("home_advertise", JSON.stringify(resp.data.advertise) )

            //NUM_ROWS = Number(NUM_ROWS) + Number(LIMIT_ROWS);

            //console.log(this.state.advertise)
            
        })
        .catch(function(error) {
          //console.log(error);
        });   
  }

  // onEndReached = (event) => {
  //   // load new data
  //   // hasMore: from backend data, indicates whether it is the last page, here is false
  //   if (this.state.isLoading && !this.state.hasMore) {
      
  //     return;
  //   }
  //   // console.log('reach end', event);
  //   //this.setState({ isLoading: true });
  //   if(TOTAL_INDEX !== 0) { 
  //     if(this.pageIndex+1 >= TOTAL_INDEX) {
  //       this.setState({
  //         isLoading: false,
  //       });
  //     } else {
  //       this.setState({ isLoading: true });
  //     }
  //   } else {
  //     this.setState({ isLoading: false });
  //   }

  //   setTimeout(() => {
  //     //console.log(this.pageIndex)
  //     if(TOTAL_INDEX !== 0) {
  
  //       if(this.pageIndex+1 >= TOTAL_INDEX) {
  //         return ;
  //       }

  //       //this.data = { ...this.data, ...genData(++this.pageIndex) };
        
  //       this.fetchData();
  //       // this.setState({
  //       //   // dataSource: this.state.dataSource.cloneWithRows(this.rData),
  //       //   isLoading: false,
  //       // });
  //     } else {
  //       this.data = { ...this.data, ...genData(++this.pageIndex) };
  //       this.fetchData();
  //       // this.setState({
  //       //   dataSource: this.state.dataSource.cloneWithRows(this.rData),
  //       //   isLoading: false,
  //       // });
  //     }
      
  //   }, 1000);
  // }
  latestNews() {
    let obj
    let rowID
    let data = this.state.dataSource  
    let main = []
    let ui = [];

    let adcount = 0;
    if(this.state.news.length  > 0){
      
        // Outer loop to create parent
        for (let i = 0; i < this.state.news.length; i++) {
          obj = this.state.news[i];
          rowID = i;

            if ( ((parseInt(rowID)+1) % 4 === 0) ) {
              if(this.state.advertise.length>adcount){
                ui.push(
                 <div>
                    <a href={this.state.advertise[adcount].advertise_goto} target="_blank" rel="noopener noreferrer">
                      <div key={"first"+rowID} style={{ padding: '0 5px' }}>
                      <div style={{ display: '-webkit-box', display: 'inline-block', padding: '5px 0' }}><img src={window.shwesports.apiAssetUrl+"/images/"+this.state.advertise[adcount].advertise_image}   alt={this.state.advertise[adcount].advertise_title} style={{ width: '100%' }} /></div></div>
                    </a>
                    <Link to={"/news/"+obj.id_news}>
                        <div className="grid-item">
                          <div class="card">
                            <div key={"second"+rowID} style={{ padding: '0 5px' }}>
                              <img src={window.shwesports.apiAssetUrl+"/images/"+obj.news_image}  className=""  alt={obj.news_title} style={{ maxWidth: "100%" ,borderRadius: "inherit"}} height="400" width="500"/>
                              <div class="container">
                                <h4><b><i  style={{fontSize: "13px" }}>{moment(obj.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY')}</i></b></h4> 
                                <span>{`${obj.news_desc.replace(/(<([^>]+)>)/ig,"").substring(0, MAX_LENGTH)}...`}</span>

                            </div>

                          

                            </div>
                          </div>
                      </div>
                    </Link>
                 </div>

                )
              }else{
                ui.push(

                    <Link to={"/news/"+obj.id_news}>
                    <div className="grid-item">

                          <div class="card">
                            <img src={window.shwesports.apiAssetUrl+"/images/"+obj.news_image} alt="" width="auto" className=""  alt={obj.news_title} style={{ maxWidth: "100%",borderRadius: "inherit"}} height="400" width="500"/>
                            <div class="container">
                              <h4><b><i  style={{fontSize: "13px" }}>{moment(obj.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY')}</i></b></h4> 
                              <span>{`${obj.news_desc.replace(/(<([^>]+)>)/ig,"").substring(0, MAX_LENGTH)}...`}</span> 
                            </div>
                          </div>
                </div>
                          
                  </Link>
                )
              }
              adcount++;

              
            }else{
              ui.push(
                   <Link to={"/news/"+obj.id_news} key={rowID}>
                <div className="grid-item">
                
               <div className="card">
               <img src={window.shwesports.apiAssetUrl+"/images/"+obj.news_image} alt=""  className=""  alt={obj.news_title} style={{ maxWidth: "100%",borderRadius: "inherit"}} height="400" width="500"/>
                  <div class="container">
                    <h4> <i  style={{fontSize: "13px" }}>{moment(obj.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY')}</i> </h4>     
                    <span>{`${obj.news_desc.replace(/(<([^>]+)>)/ig,"").substring(0, MAX_LENGTH)}...`}</span>
                  </div>                     
               </div>
                </div>
                   
              </Link>
              )
            }
        
        }
        //main.push(<div key={0} width="100%">{ui}</div>)
        return ui
    }
}

  render() {
    // const separator = (sectionID, rowID) => (
    //   <div
    //     key={`${sectionID}-${rowID}`}
    //     style={{
    //       backgroundColor: '#F5F5F9',
    //       height: 8,
    //       borderTop: '1px solid #ECECED',
    //       borderBottom: '1px solid #ECECED',
    //     }}
    //   />
    // );
    // let index = this.data.length - 1;
    // let ui;
    // let adcount = 0;
  
    return (
      <div className="page">
     <div className="am-list-header" key="0"><div className="list-header bg-white"><img src="/img/newsicon.png" className="list-icon-circle" alt={window.lang.title.latest_news}  /><span>{window.lang.title.latest_news} </span></div></div>
        <div className="grid-container">

            {this.latestNews()}
          

          {/* <ListView
            ref={el => this.lv = el}
            dataSource={this.state.dataSource}
            renderHeader={() => <div className="list-header"><img src="/img/newsicon.png" className="list-img-circle" alt={window.lang.title.latest_news} /><span>{window.lang.title.latest_news}</span></div>}
            renderFooter={() => (<div style={{ padding: this.state.isLoading ? 30 : 0 , textAlign: 'center', display: this.state.isLoading ? 'block' : 'none' }}>
              {this.state.isLoading ? <img src="/img/loading.gif" alt="loading"  /> : ''}
            </div>)}
            renderRow={row}
            renderSeparator={separator}
            className="am-list"
            pageSize={10}
            useBodyScroll
            // onScroll={() => { console.log('scroll'); }}
            scrollRenderAheadDistance={500}
            onEndReached={this.onEndReached}
            onEndReachedThreshold={10}
          /> */}

        </div>
      </div>
    );
  }
}

export default HomeListPage;

