import React, { Component } from 'react';
import axios from 'axios';
import {
  Link
} from 'react-router-dom';
//import ReactHLS from 'react-hls-player';
//import renderHTML from 'react-render-html';
import 'video-react/dist/video-react.css';
import HelpCenterChat from "./../../component/Chat/HelpCenterChat";
// import 'react-hls/src/style.css'; // need to import basic styles
// import 'react-hls/src/icons.css'; // need to import basic icons
//let useragentcheck = (navigator.userAgent.toLowerCase().indexOf("mac") > -1 );

class HelpCenterAppPage extends Component {
  state = {
    video: {
        //src: "https://live.shwesports.com/live.m3u8",
        // livesrc: "https://api.new.livestream.com/accounts/22774234/events/6897936/live.m3u8",
        //src: "http://ott.artmott.com/ArenaSport1MW/tracks-v1a1/mono.m3u8",
        name: "Channel 2",
        description : "",
        src: "https://stream.shwesports.com:9200/hls/shwesports.m3u8",
        poster2: "http://www.example.com/path/to/video_poster.jpg",
        advertise: {},
    },
    isloading : true
  }

  hisUrl(url) {
    if(localStorage.getItem("route")) {
      let menu = JSON.parse(localStorage.getItem("route"));
      //console.log(menu.length+"-------------")
      if(menu.length === "0") {
        let data = ["/"];
        localStorage.setItem("route", JSON.stringify(data) );
      } 
       if(menu.length > 0) {
          //console.log(menu[menu.length - 1] + "-" + url )
            if(menu[menu.length - 2] === url ){
                //console.log("first");
                menu.pop();
                //window.shwesports.route = JSON.stringify(menu);
                localStorage.setItem("route", JSON.stringify(menu) );
                //localStorage.setItem("route", JSON.stringify(menu) );
            }  
            else if(menu[menu.length-1] !== url ){
                menu.push(url)
                //window.shwesports.route = JSON.stringify(menu);
                localStorage.setItem("route", JSON.stringify(menu) );
            }
            window.shwesports.preurl =  menu[menu.length-2];
        }
    }
  }

  getfetchApi() {
    var self = this;
    let dataurl = window.shwesports.apiUrl+'/user/channels/info?cid=7';
    let data = this.state.data;
    axios({
        method: 'get',
        url: dataurl,
        data: data,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
    })
    .then(function (response) {
        // console.log(response.data.data[2]);
        if(response.data.data) {
            self.setState({  
            //   video : response.data.data[1], 
              advertise : response.data.advertise,
              isloading : false
            });
            //self.setState({  message : "Successfully updated" });
            //console.log(response.data.data)
        } else {
            self.setState({  message : "Something was wrong" });
        }
        //console.log(response.data.success);
    })
    .catch(function (error) {
        //console.log(error);
    });
}

  componentDidMount() {
    this.hisUrl('/help-center');
    // this.getfetchApi();
}

 render() {
 
   return (
        <div className="page">
          <div className="page-content">
                <div className="row">
                    {/* <br /> */}
                        {/* <div className="list-header">
                            <img src="/img/badge.jpg" className="list-img-circle" alt={window.lang.more.live_channel1} /><span>{window.lang.more.live_channel1}</span>
                        </div>
                  <br/> */}
                  <HelpCenterChat/>
                  <br/>
                </div>
                <div className="main-content">
                  <br/>
                  {
                    (this.state.advertise) ? <div className="row">
                      <div className="grid">
                        <a href={this.state.advertise.advertise_goto} target="_blank" rel="noopener noreferrer">
                          <img src={window.shwesports.apiAssetUrl+"/images/"+this.state.advertise.advertise_image} style={{ width: '100%' }} alt={this.state.advertise.advertise_title}/>
                          </a><br/>
                      </div>
                    </div>: ""
                  }

                </div>
                   
                    {/* <div class="ant-back-top"><div class="ant-back-top-content">Back<div class="ant-back-top-icon"></div></div></div> */}
                    <div className="ant-back-footer">
                        <div className="ant-back-top">
                            <div className="ant-back-top-content">
                                <Link to={window.shwesports.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null) 
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link> 
                            </div>
                        </div>
                    </div>
            </div>
        </div>
   );
 }
}
 
export default HelpCenterAppPage;