import React from 'react';
import axios from 'axios';
import mmph from 'myanmar-phonenumber';

class PasswordRestPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { step: "register", message : "", username: '', form : { phone: '',verify_code: '', password: '' }};
        this.validatePhone      = this.validatePhone.bind(this);
        this.checkValidationApi = this.checkValidationApi.bind(this);
        this.changePasswordApi  = this.changePasswordApi.bind(this);
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }
        }
       // console.log(localStorage.getItem('route'));
    }

    componentDidMount() {
        // console.log(mmph.normalizeInput('+၉၅၉၇၈၄၁၂၃၄၅၆')); // returns 09784123456
        // console.log(mmph.normalizeInput('+959784123456')); // returns 09784123456
        // console.log(mmph.getTelecomName('09978412345')); // returns Ooredoo
        // console.log(mmph.getPhoneNetworkType('09978412345')); // returns GSM
        // console.log(mmph.isValidMMPhoneNumber('09978412345')); // returns true if it meets Myanmar Phone
        if(localStorage.getItem('time')) {
            if(localStorage.getItem('time') !== "0" ) {
              this.setState({ step: "profile" }); 
            }
            
          } 
        // if(localStorage.getItem('username')) {
        //         this.setState({ 
        //             form: {
        //                 username : localStorage.getItem('username')
        //             } 
        //         }); 
        // } 

        this.hisUrl('/more/password-reset');
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/sign-up');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    validatePhone() {
        let form = this.state.form
        let data
        //console.log(this.state.form.phone.length)
        //phone : mmph.normalizeInput(this.state.form.phone)
        if(form.phone.length > 6) {
            if(mmph.isValidMMPhoneNumber(form.phone)){
                data = {
                    username : this.state.form.username,
                    phone : this.state.form.phone
                }
                this.setState({  message : "SMS Sending" });
                this.sendSMSApi(data);
            } else {
                this.setState({  message : window.lang.messages.invalid_phone });
            }
            
        } else {
            //console.log(this.state.message)
            this.setState({  message : window.lang.messages.invalid_phone });
        }
        
    }
    sendSMSApi(formdata) {
        var self = this;
        let dataurl = window.shwesports.apiUrl+'/user/register';
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then(function (response) {
            if(response.data.success) {
                self.setState({  message : "SMS already Sent", step : "verify" });
            } else {
                self.setState({  message : "Something was wrong" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    checkValidationApi() {
        let formdata = this.state.form ;
        var self = this;
        let dataurl = window.shwesports.apiUrl+'/user/verifycode';
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then(function (response) {
            if(response.data.success) {
                self.setState({  message : "Successfully", step : "password"  });
            } else {
                self.setState({  message : "Something was wrong" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    changePasswordApi() {
        let formdata = this.state.form ;
        var self = this;
        let dataurl = window.shwesports.apiUrl+'/user/pwd';
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then(function (response) {
            if(response.data.success) {
                self.setState({  message : "Successfully", step : "profile", username : response.data.username , time : response.data.time  });
                localStorage.setItem('time', self.state.time );
                localStorage.setItem('username', self.state.username );
            } else {
                self.setState({  message : "Something was wrong" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    viewHandler() {

        switch(this.state.step) {
            // case "line-up":   return "";
            case "register":   return this.registerBlock();
            case "verify": return this.verifyBlock();
            case "password": return this.passwordBlock();
            case "profile": return this.profileBlock();
            default:      return "";
          }
    }

    registerBlock() {
        return <div className="form-box">
                    <div className="row">
                        <div className="grid-30 grid-s-100 left">
                            <div className="form-group text-left">
                                <label>{window.lang.form.phone}</label>
                            </div>
                        </div>
                        <div className="grid-70 grid-s-100 left text-right">
                            <div className="form-group text-center">
                            <input type="text" className="form-control text-center" placeholder="09**********" name="phone" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.phone} /><br />
                            </div>
                            <input type="submit" className="btn btn-success grid-50" value={window.lang.form.submit} onClick={this.validatePhone} /><br />
                        </div>
                        
                    </div>
        </div>
    }

    verifyBlock() {
        return <div>
                    <div className="row">
                        <div className="grid-30 grid-s-100 left">
                            <div className="form-group text-left">
                                <label>{window.lang.form.verify_code}</label>
                            </div>
                        </div>
                        <div className="grid-70 grid-s-100 left">
                            <div className="form-group text-center">
                            <input type="text" className="form-control text-center"  name="verify_code" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.verify_code} maxLength="6" /><br />
                            </div>
                            <input type="submit" className="btn btn-success grid-50" value="Submit" onClick={this.checkValidationApi} /><br />
                        </div>
                        
                    </div>
        </div>
    }

    passwordBlock() {
        return <div>
                    <div className="row">
                        <div className="grid-30 grid-s-100 left">
                            <div className="form-group text-left">
                                <label>New Password</label>
                            </div>
                        </div>
                        <div className="grid-70 grid-s-100 left">
                            <div className="form-group text-center">
                            <input type="password" className="form-control text-center"  name="password" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.password} /><br />
                            </div>
                            <input type="submit" className="btn btn-success grid-50" value="Submit" onClick={this.changePasswordApi} /><br />
                        </div>
                        
                    </div>
        </div>
    }

    profileBlock() {
        return <div>
            <div className="row">
                <h2>Hi {this.state.username}</h2>
                <p>{window.lang.messages.welcome_message}</p>
            </div>
        </div>
    }

    render() {
        return (
            <div className="page">
                <div className="page-content text-center">
                    <div className="main-content">
                        <br />
                        <br />
                        <h1 className="color-green">{window.lang.form.password_reset}</h1>
                        <br />
                        <span>{this.state.message}</span>
                        
                        { this.viewHandler() }
                    </div>
                </div>
            </div>
        );
    }
}

export default PasswordRestPage;