import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';


var moment = require('moment');

class OrderPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { step: "login", message : "", data : [] };
        
    }


    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.shwesports.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }  
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.shwesports.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.shwesports.preurl =  menu[menu.length-2];
            }
        }
      }

    fetchUserData() {
        const url = window.shwesports.apiUrl+'/user/order/history?token='+localStorage.getItem('time');
    
        axios.get(url, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
            console.log(resp.data);
            this.setState({
                data : resp.data
            });
            
          })
          .catch(function(error) {
            // console.log(error);
          });   
    }

    componentDidMount() {
        if(localStorage.getItem('time')) {
            if(localStorage.getItem('time') !== "0" ) {
              //localStorage.setItem('time', true);
                
              } else {
                this.props.history.push("/more");
                }
          } else {
              this.props.history.push("/more");
          }

        this.fetchUserData();

       
        this.hisUrl('/more/order');
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

   

    

  
    displayBlock() {
        let ui = [];
        let data = this.state.data

            if(this.state.data.length > 0){
              // data.forEach(function(each){
                for (let i = 0; i < this.state.data.length; i++) { 
                  
                  
                    //this.setState.isLoading = false ;
                    ui.push(
                        <tr>
                            <td colSpan="4" >
                                <tr>
                                    <td colSpan="4" className="color-green">{data[i].type} - {data[i].name}</td>
                                </tr>
                                <tr >
                                    <td style={{ width: '40%' }}>{data[i].player.name}</td>
                                    <td style={{ width: '20%' }}>{data[i].gem}</td>
                                    <td style={{ width: '20%' }}>{moment(data[i].expire_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY, hh:mm A')} </td>
                                    <td style={{ width: '20%' }}>{moment(data[i].created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY, hh:mm A')}</td>
                                </tr>
                            </td>
                        </tr>   
                        )
                  
                  
              }
            }
        return ui;
      }

    render() {
        return (
            <div className="page">
                <div className="page-content text-left">
                         
                        <br/>
                        <div className="list-header">
                            <img src="/img/badge.jpg" className="list-img-circle" alt={window.lang.title.order} /><span>{window.lang.title.order}</span>
                        </div>
                        
                        <br />
                        <div className="row text-left">
                            <ul className="secondsubmenu">
                                <li>
                                    <Link to="/more/inbox" className="btn-success btn" >
                                        {window.lang.title.inbox}
                                    </Link> 
                                </li>
                                <li>
                                    <Link to="/more/transaction" className="btn-success btn " >
                                        {window.lang.title.payment}
                                    </Link> 
                                </li>
                                <li>
                                    <Link to="/more/order" className="btn-success btn color-gold" >
                                        {window.lang.title.order}
                                    </Link> 
                                </li>
                            </ul>
                        </div>
                        <br/>
                        <table className="table strip-table">
                            <thead>
                                <tr>
                                    <td style={{ width: '40%' }}>Name</td>
                                    <td style={{ width: '20%' }}>Gem</td>
                                    <td style={{ width: '20%' }}>Expire</td>
                                    <td style={{ width: '20%' }}>Order</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.displayBlock()}
                            </tbody>
                        </table>
                        <br />


                    <div className="ant-back-footer">
                        <div className="ant-back-top">
                            <div className="ant-back-top-content">
                                <Link to={window.shwesports.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null) 
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link> 
                            </div>
                        </div>
                    </div>
                   
                </div>
               
            </div>
        );
    }
}

export default OrderPage;