import React from 'react';
import {
    Link
  } from 'react-router-dom';

export default () => (
  <div className="page">
      <div className="page-content">
          <div className="main-content text-center">
                    <br />
                    <br />
                    <span>APP ‌ဖုန်းထဲထည့်သွင်းပြီးပါက <br /> Browser မှဝင်စရာမလိုပဲ တိုက်ရိုက်အသုံးပြုနိုင်သည်။</span>
                    <br />
                    <br />
                    <img src="/img/shwesport1.png" className="medium-logo" />
                    <br />
                    <Link to="/download/ios" className="btn btn-success grid-50">IOS &nbsp;&nbsp;  ဒေါင်းလုတ်လုပ်ရန်နှိပ်ပါ</Link><br /><br />
                    
                    <a href="https://play.google.com/store/apps/details?id=com.shwesports.ss" target="_blank" className="btn btn-yellow grid-50">Android &nbsp;&nbsp;   ဒေါင်းလုတ်လုပ်ရန်နှိပ်ပါ </a>
                    
          </div>
        </div>
  </div>
);
