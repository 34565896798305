import React from 'react';
import axios from 'axios';
//import { Modal }  from 'antd-mobile';
import {
    Link
  } from 'react-router-dom';

import StatusAlert, { StatusAlertService } from 'react-status-alert'
import 'react-status-alert/dist/status-alert.css'
//   const alert = Modal.alert;
// var moment = require('moment');

class ContestPage extends React.Component {
    constructor(props) {
        super(props)
        window.shwesports.footerMenu = "CONTEST";
        this.state = { message: "", data : {}, step: "list",  league : false, sleague : false, advertise : {advertise_image:""}, isLoading: false, check: 0,package : { id:"1", name:"Daily Bonus",gem:"1",bactive:"0"} ,alertId: ''};
        //this.validatePhone      = this.validatePhone.bind(this);
        this.changeSubMenu      = this.changeSubMenu.bind(this);
        this.submitDailyBonusApi = this.submitDailyBonusApi.bind(this);

        this.showSuccessAlert = this.showSuccessAlert.bind(this);
        this.removeAlert = this.removeAlert.bind(this);
    }

    submitDailyBonusApi() {
        this.sendDailyBonusApi();
    }

    showSuccessAlert(message) {
        const alertId = StatusAlertService.showSuccess(message);
        this.setState({ alertId });
    }
    
    removeAlert() {
        StatusAlertService.removeAlert(this.state.alertId);
    }


    fetchRamAdsData() {
        // let dataurl = window.shwesports.apiUrl+'/advertise/ran';
        let dataurl = window.shwesports.apiUrl+'/advertise/detail/8';

        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "force-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
            this.setState({
                advertise : resp.data.advertise
            });

            localStorage.setItem("contest_advertise", JSON.stringify(resp.data.advertise) )

        })
        .catch(function(error) {
          //console.log(error);
          //console.log(error.tostring());
        });   
 
    }
    
    fetchData() {
        
        // var self = this;
        console.log(" ------------- ")
        if(localStorage.getItem('time') !== 0) {
            let phone = localStorage.getItem('time');  
            let dataurl = window.shwesports.apiUrl+'/user/check';
            // let contests = this.state;
            let contests = {
                phone:  phone,
                type : "dailybonus"  
            };
            axios({
                method: 'post',
                url: dataurl,
                data: contests,
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
            })
            .then(response => {
                
                this.setState({
                    check       : response.data.check, 
                    status      : 1,
                    package : response.data.dailybonus
                });
                // console.log(" ------------- "+response.data)
                
                // if(response.data.success) {
                //     self.setState({
                //         check       : response.data.check, 
                //         status      : 1,
                //         package : response.data.dailybonus
                //     });

                // } 
                //console.log(response.data.success);
            })
            .catch(function(error) {
              // console.log(error.tostring());
            });   
        }
 
    }

    sendDailyBonusApi() {
        
        var self = this;
        if(localStorage.getItem('time') !== 0) {
            // this.setState({
            //     phone:  localStorage.getItem('time')  
            // })
            let dataurl = window.shwesports.apiUrl+'/user/dailybonus';
            // let contests = this.state;
            let contests = {
                phone:  localStorage.getItem('time')  
            };
            axios({
                method: 'post',
                url: dataurl,
                data: contests,
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
            })
            .then(response => {
                if(response.data.success) {
                    self.setState({  
                        message : response.data.message, 
                        status  : 1,
                        check: response.data.check
                    });

                    if(response.data.message){
                        self.showSuccessAlert(response.data.message )
                    }
                      
                    
                    // let stateday = moment(self.state.selectedDays[0]).format("YYYY-MM-DD");
                    // let phone = localStorage.getItem('time') ;
                    // self.fetchData(window.shwesports.apiUrl+'/user/league-contests?phone='+phone+'&filter='+stateday,'livescore');
                     
                    //self.setState({  message : "Successfully updated" });
                } else {

                    self.showSuccessAlert("Something was wrong")
                   
                    self.setState({  
                        message : "Something was wrong",
                     });
                }
                //console.log(response.data.success);
            })
            .catch(function(error) {
              // console.log(error.tostring());
            });   
        }
        
    }

    changeSubMenu(step) {
        if(step === 'league') {
            if(this.state.league) {
                this.setState({ league : false});
            } else {
                this.setState({ league : true});
            }
        }
        
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
          if(menu.length > 0) {
            //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                  menu.pop();
                  //window.shwesports.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  //localStorage.setItem("route", JSON.stringify(menu) );
              }  
              else if(menu[menu.length-1] !== url ){
                  menu.push(url)
                  //window.shwesports.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  
              }
              //window.shwesports.preurl =  menu[menu.length-1];
              window.shwesports.preurl =  menu[menu.length-2];
              //console.log(window.shwesports.preurl)
          }
        }
      }


    componentDidMount() {

        if(localStorage.getItem('time')) {
          if(localStorage.getItem('time') !== "0" ) {
            //localStorage.setItem('time', true);
            this.setState({ step: "list" }); 
          } else {
             this.props.history.push("/more/sign-up");
            }
        } else {
            this.props.history.push("/more/sign-up");
        }

        if(localStorage.getItem('contest_advertise')) {
        
            if(JSON.parse(localStorage.getItem("contest_advertise")) != null) {
              let contest_advertise = JSON.parse(localStorage.getItem("contest_advertise"));
              this.setState({advertise: contest_advertise})
            }
        } 

        if(localStorage.getItem(window.shwesports.time)) {
              if(localStorage.getItem(window.shwesports.time) !== "0" ) {
                  document.getElementById( 'toggle-home' ).style.display = 'none';
              }
        }
        
        this.hisUrl('/contest');

        this.fetchData();
        // this.apifetchurl();
        
        this.fetchRamAdsData();
        clearTimeout(window.shwesports.liveurl);
        
    }

    UNSAFE_componentWillUpdate() {
        //this.hisUrl('/more');
    }

    ruleUi(){
        let ui = [];
        let each = this.state.package;

        
            ui.push(
                <div className="">
                        
                    <ul>
                        <Link to="/more/term-condition">
                            <li className='package-box flex'>
                                <img width="30px" src="/img/list/termsandcond.png" alt={window.lang.more.term_and_conditions} />{window.lang.more.term_and_conditions}<span>&gt;</span>
                            </li>
                        </Link>
                        <hr />
                        <Link  to="/more/gems/store"><li className="package-box flex"><img width="30px" src="/img/list/gemstore.png" alt={window.lang.more.gem_store} />{window.lang.more.gem_store}<span>&gt;</span></li></Link>
                <hr />
                        

                        <Link  to="/more/myaccount"><li className="package-box flex"><img width="30px" src="/img/myaccounticon.png" alt="My Account" />{window.lang.more.my_account}<span>&gt;</span></li></Link>
                        {/* <Link to="/forum"><li><img src="/img/forumicon.png" alt={window.lang.title.forum} />{window.lang.title.forum}<span>&gt;</span></li></Link> */}
                        
                    </ul> 
                      
                </div>
            )
        
        
        return ui;
       
        
    }

    viewHandler() {

        
        switch(this.state.step) {
            // case "line-up":   return "";
            //case "register":   return this.registerBlock();
            //case "profile": return this.profileBlock();
            // case "home": return this.homeBlock();
            case "list": return this.listBlock();
            default:      return "";
          }
    }
    listBlock(){
        return <div className="contest-page">
                <div className="package-gem ">
                    { (this.state.package.bactive === "1")  ? this.dailyBonusUi() : "" }
                </div>
                <div className="contest-box">
                    <div className="">
                    <div className="grid-container">
                        <div className="grid-item1">
                    { (this.state.package.bactive === "1")  ? this.ruleUi() : "" }

                        </div>

                        <div className="grid-item">
                            <Link to="/more/league-contest">
                            <div className='package-box'>
                                <img src="/img/league_contest_40.png" alt="League contest" style={{height:"40px",marginBottom:"5px"}} /><br/>
                                <span className="color-green">{window.lang.title.league_contest} </span>
                            </div>
                        </Link>
                        </div>
                        <div className="grid-item">
                            <Link to="/more/bill-contest">
                                <div className="package-box " >
                                    <img src="/img/bill_contest_40.png" alt="Phone bill" style={{height:"40px",marginBottom:"5px"}} /><br/>
                                    <span className="color-green text-center">{window.lang.title.bill_contest}</span>
                                </div>
                            </Link>
                        </div>
                        <div className="grid-item">
                        <Link to="/more/tipsters">
                            <div className=" package-box">
                                <img src="/img/tipster_40.png" alt="Tipsters" style={{height:"40px",marginBottom:"5px"}} /><br/>
                                <span className="color-green">{window.lang.title.tipsters}</span>
                            </div>
                        </Link>
                        </div>
                        <div className="grid-item">
                        <Link to="/forum">
                            <div className=" package-box">
                                <img src="/img/forumicon.png" alt={window.lang.title.forum} style={{height:"40px",marginBottom:"5px"}} /><br/>
                                <span className="color-green">{window.lang.title.forum}</span>
                            </div>
                        </Link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
    }

    listBlock2() {
        return  <div className="row text-left">
                   
                    <div className="grid-100 text-left">
                        <br /><br />
                        <Link to="/more/league-contest">
                            <div className="grid-40 bg-gray package-block">
                                <img src="/img/league_contest_40.png" alt="League contest" style={{height:"40px",marginBottom:"5px"}} /><br/>
                                <span className="color-green">{window.lang.title.league_contest} </span>
                            </div>
                        </Link>
                        <Link to="/more/bill-contest">
                            <div className="grid-50  bg-gray package-block">
                                <img src="/img/bill_contest_40.png" alt="Phone bill" style={{height:"40px",marginBottom:"5px"}} /><br/>
                                <span className="color-green">{window.lang.title.bill_contest}</span>
                            </div>
                        </Link>
                    </div>
                    <div className="grid-100 text-left">
                        <Link to="/more/tipsters">
                            <div className="grid-40 bg-gray package-block">
                                <img src="/img/tipster_40.png" alt="Tipsters" style={{height:"40px",marginBottom:"5px"}} /><br/>
                                <span className="color-green">{window.lang.title.tipsters}</span>
                            </div>
                        </Link>
                        {/* <Link to="/more/myaccount">
                            <div className="grid-50  bg-gray package-block">
                                <img src="/img/tipster.png" alt="My Account"/><br/>
                                <b className="color-green">{window.lang.more.my_account}</b>
                            </div>
                        </Link> */}

                        <Link to="/forum">
                            <div className="grid-50  bg-gray package-block">
                                <img src="/img/forumicon.png" alt={window.lang.title.forum} style={{height:"40px",marginBottom:"5px"}} /><br/>
                                <span className="color-green">{window.lang.title.forum}</span>
                            </div>
                        </Link>
                    </div>

                    {/* <div className="row">
                                {(this.state.status===3)?<div className="text-center"><button className="btn btn-success" onClick={this.submitDailyBonusApi}>Submit</button>&nbsp;&nbsp;<button className="btn btn-secondary" onClick={this.cancelBillContent}>Cancel</button></div> :"" }
                        </div> */}

                    {/* <input type="submit" name="dailybonus"  value="Daily Bonus" onClick={this.submitDailyBonusApi} className="btn btn-success"/> */}

                    
                        { (this.state.package.bactive === "1")  ? this.dailyBonusUi() : "" }
                    

                    {/* <div className="grid-100 text-center">
                        <b>Daily Bonus</b><br/>
                        <i>1 GEM</i><br />
                        {(this.state.check===1)? <button className="btn btn-success" onClick={this.submitDailyBonusApi}><img src="/img/correct.png" className="pr-4" alt="Correct" />Daily Bonus</button> : <button className="btn btn-success avoid-clicks"><img src="/img/cancel.png" className="pr-4" alt="Cancel" />Claim</button>  }
                        
                    </div> */}

                </div>
    }

    profileBlock() {
      return <div>
          <div className="row">
              <h2>Hi {(localStorage.getItem('username')? localStorage.getItem('username') : "Customer")}</h2>
              <p>Welcome to Shwesports</p>
          </div>
      </div>
   }
   dailyBonusUi() {
    let ui = [];
    let each = this.state.package;

    
        ui.push(
            <div className="flex">
                            <div className="flex-item">
                                <span style={{  }}><h2 className="color-black">{each.name}</h2></span>
                            </div>
                            <div className="flex-item">
                                <span><b><i>{each.gem} GEM</i></b></span>
                            </div>
                            <div className="flex-item">
                            {(this.state.check===1)? <button className="btn btn-success" onClick={this.submitDailyBonusApi}><img src="/img/correct.png" className="pr-4" alt=" " />Claim</button> : <button className="btn btn-success avoid-clicks"><img src="/img/cancel.png" className="pr-4" alt="Cancel" />Claim</button>  }
                            </div>
                    
            </div>
        )
    
    
    return ui;
   
    
}

   dailyBonusUi2() {
        let ui = [];
        let each = this.state.package;

        
            ui.push(
                <div className=" text-center">
                        {/* <br /> */}
                        <div className=" package-gem ">
                            {/* <b className="color-black">{each.name}</b><br/>
                            <b><i>{each.gem} GEM</i></b><br /><br />
                            {(this.state.check===1)? <button className="btn btn-success" onClick={this.submitDailyBonusApi}><img src="/img/correct.png" className="pr-4" alt=" " />Claim</button> : <button className="btn btn-success avoid-clicks"><img src="/img/cancel.png" className="pr-4" alt="Cancel" />Claim</button>  } */}
                            
                        </div>
                        {/* <div className="grid-50 mini-menu left text-left">
                            <ul>
                                <Link to="/more/term-condition"><li><img src="/img/list/termsandcond.png" alt={window.lang.more.term_and_conditions} />{window.lang.more.term_and_conditions}<span>&gt;</span></li></Link>
                                <Link  to="/more/gems/store"><li className="bg-gray"><img src="/img/list/gemstore.png" alt={window.lang.more.gem_store} />{window.lang.more.gem_store}<span>&gt;</span></li></Link>

                                

                                <Link  to="/more/myaccount"><li className="bg-gray"><img src="/img/myaccounticon.png" alt="My Account" />{window.lang.more.my_account}<span>&gt;</span></li></Link>
                                {/* <Link to="/forum"><li><img src="/img/forumicon.png" alt={window.lang.title.forum} />{window.lang.title.forum}<span>&gt;</span></li></Link> */}
                                
                            {/* </ul> */}
                        {/* </div> */}


                        {/* <div className="grid-15 bg-gray right p-4">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nemo architecto 
                        </div> */}
                    {/* <div className={(this.state.check===1)? "grid-40 bg-gray package-block box-shadow left" : "grid-40 bg-gray package-block active box-shadow" }>
                        <b className="color-green">{each.name}</b><br/>
                        <b><i>{each.gem} GEM</i></b><br /><br />
                        {(this.state.check===1)? <button className="btn btn-success" onClick={this.submitDailyBonusApi}><img src="/img/correct.png" className="pr-4" alt=" " />Claim</button> : <button className="btn btn-success avoid-clicks"><img src="/img/cancel.png" className="pr-4" alt="Cancel" />Claim</button>  }
                    </div>
                    <div className="grid-50 setting-menu right">
                        <ul>
                            <Link  to="/more/gems/store"><li><img src="/img/list/gemstore.png" alt={window.lang.more.gem_store} />{window.lang.more.gem_store}<span>&gt;</span></li></Link>
                        </ul>
                    </div> */}
                </div>
            )
        
        
        return ui;
       
        
    }


    render() {
        return (
            
            <div className="page">
                <div className="page-content text-center">
                    <div className="">
                        {(this.state.message !== "") ? <div className="grid"><StatusAlert/></div>: ""}
                        
                        { this.viewHandler() }

                        
                    </div>
                
                    <br />
                    {(this.state.advertise) ? <div className="row">
                        <div className="grid">
                            <br/>
                            <a href={this.state.advertise.advertise_goto} target="_blank" rel="noopener noreferrer">
                            <div  style={{ padding: '0 5px' }}>
                                <div style={{ display: '-webkit-box',  padding: '5px 0' }}>
                                    <img src={window.shwesports.apiAssetUrl+"/images/"+this.state.advertise.advertise_image} style={{ width: '100%' }} alt={this.state.advertise.advertise_title}/>
                                </div>
                            </div>
                            </a>
                            {/* <a href={this.state.advertise.advertise_goto} target="_blank">
                                <img src={window.shwesports.apiAssetUrl+"/images/"+this.state.advertise.advertise_image} style={{ width: '100%' }} alt={this.state.advertise.advertise_title}/>
                            </a><br/><br/> */}
                        </div>
                        </div>: "" }
                   
                    <div className="ant-back-footer">
                        <div className="ant-back-top">
                            <div className="ant-back-top-content">
                                <Link to={window.shwesports.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null) 
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContestPage;