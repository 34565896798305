/* eslint no-dupe-keys: 0 */
import React from 'react';

import Carousel from 're-carousel'

// import {
//   Link
// } from 'react-router-dom';

import axios from 'axios';

let NUM_ROWS = 4;

class HighlightsVideoSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      // dummy slides data
      // slides: (function () {
      //   var slides = [];
      //   for (var i = 0; i < 600; i += 1) {
      //     slides.push('Slide ' + (i + 1));
      //   }
      //   return slides;
      // }()),
      // virtual data
      videoSlides: [],
      virtualVideoData: {
        slides: [
          {
            "id_highlights": "31",
            "highlights_name": "ဂ်ာမနီ 1-1အီတလီ (pen 6-5 )",
            "highlights_link": "https://www.youtube.com/watch?v=JXZ3FmTk_eI",
            "highlights_embed": "http://www.youtube.com/embed/JXZ3FmTk_eI",
            "highlights_image": "https://img.youtube.com/vi/JXZ3FmTk_eI/mqdefault.jpg",
            "highlights_desc": "<p>ဂ်ာမနီ 1-1အီတလီ (pen 6-5 )</p>",
            "created_at": "2018-12-19 09:39:07",
            "updated_at": "2018-12-19 15:12:36"
          }
        ]
      },
      leagueMenu: 'NEWS',
      date: new Date()
    };

    // console.log(this.props)
    // if(this.props.videoData) {
    //   // console.log()
    //   console.log( this.props.videoData)
    //   this.state.virtualVideoData.slides = this.props.videoData
    // }

   
    
  }
  onSelect = key => {
    this.setState({ selected: key });
  }

  // componentWillMount() {
  //   this.fetchData();
  // }

  componentDidMount() {
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);

    // new Swiper('.swiper-video-container', {
    //   // ...
    //   loop: true,
    //   slidesPerView: 1,
    //   slidesPerGroup: 1,
    //   pagination: {
    //     el: '.swiper-vieo-pagination',
    //   },
    // });

    //setTimeout(() => {
      this.videoSliders();
    //}, 600);
    
    // this.timerID = setInterval(
    //   () => this.tick(),
    //   1000
    // );
    

  }

  videoSliders() {
    this.fetchData();

    // new Swiper('.swiper-video-container', {
    //   // ...
    //   loop: true,
    //   slidesPerView: 'auto',
    //   slidesPerGroup: 1,
    //   pagination: {
    //     el: '.swiper-vieo-pagination',
    //   },
    // });
  }

  // tick() {
  //   this.setState({
  //     date: new Date()
  //   });
  // }

  fetchData() {
        
    const url = window.shwesports.apiUrl+'/highlights?limit='+NUM_ROWS+'&start=10&featured=0';

    axios.get(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "no-cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      //body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    .then(resp => {
      // console.log(this.data.concat(resp.data));
      //data = resp.data;
      //this.state.videoSlides = resp.data;
    //   this.state.videoSlides = data
      // this.setState({
      //       videoSlides : resp.data
      //   });
      
      this.setState({videoSlides: resp.data})
      // this.state.videoSlides = resp.data
      //this.setState.virtualVideoData.slides.push(resp.data)
      //let this.key = this.data.length;
    //   console.log(this.state.videoSlides)
    //   console.log("-----------------");
    // //   console.log("----lenght---"+data.length)
    //   console.log("-----------------");
        
        
        // this.setState({
        //   dataSource: this.state.dataSource.cloneWithRows(data.reverse()),
        //   isLoading: false,
        // });

        // this.data = { ...this.data, ...genData(++this.pageIndex) };
        // console.log(this.data)
        // NUM_ROWS = parseInt(NUM_ROWS) + parseInt(NUM_ROWS);
        //console.log(this.pageIndex)
        
      })
      .catch(function(error) {
        //console.log(error.tostring());
      });   
  }
  // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.dataSource !== this.props.dataSource) {
  //     this.setState({
  //       dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
  //     });
  //   }
  // }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    // if (this.state.isLoading && !this.state.hasMore) {
    //   return;
    // }
    // console.log('reach end', event);
    // this.setState({ isLoading: true });
    // setTimeout(() => {
    //   this.rData = { ...this.rData, ...genData(++pageIndex) };
    //   this.setState({
    //     dataSource: this.state.dataSource.cloneWithRows(this.rData),
    //     isLoading: false,
    //   });
    // }, 1000);
  }

  render() {
    return  <div className="swiper-video-container1" style={{height:'400px'}}>
        <Carousel loop auto >
            {/* <div style={{backgroundColor: 'tomato', height: '100%'}}>Frame 1</div>
            <div style={{backgroundColor: 'orange', height: '100%'}}>Frame 2</div>
            <div style={{backgroundColor: 'orchid', height: '100%'}}>Frame 3</div> */}
            {this.state.videoSlides.map((slide, index) => (
                <div className="swiper-slide1"
                key={index} style={{left: `${this.state.videoSlides.offset}px`,background:'url('+window.shwesports.apiAssetUrl+'/images/'+slide.news_image+')', backgroundSize:'cover'}}
                >
                {/* <div className="swiper-slide"
                key={index}
                style={{left: `${this.state.virtualVideoData.offset}px`}}
                > */}
                  <a
                      href={`/highlight/${slide.id_highlights}/${slide.highlights_name}`} 
                      style={{ display: 'inline-block', width: '100%' }}
                      >
                      <p >{slide.highlights_name}</p>
                      <img src={slide.highlights_image} alt="" style={{width:'100%', height: '300px'}} />
                   
                  </a>
                </div>
             ))}
        </Carousel>
        </div>
    
  }
}

export default HighlightsVideoSlider;