import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
var moment = require('moment');
//var moment = require('moment');

class AnnouncementsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            step: "login", 
            message : "", 
            data : [
                {id:	1, title : "Shwe Sports", type	: 0, description : "Shwe Sports version 1.1.2 is available now", link:"https://shwe-sports.com",
                    created_at : "2019-05-21 08:14:30" },
            ]
        };
        
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.shwesports.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }  
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.shwesports.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.shwesports.preurl =  menu[menu.length-2];
            }
        }
      }

    getAnnouncementsApi() {
        var self = this;
        let dataurl = window.shwesports.apiUrl+'/user/announcements';
        let data = this.state.data;
        axios({
            method: 'get',
            url: dataurl,
            data: data,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then(function (response) {
            if(response.data.data) {
                self.setState({  
                    data : response.data.data, 
                });

                localStorage.setItem("noti", JSON.stringify(response.data.data) )
                //self.setState({  message : "Successfully updated" });
                //console.log(response.data.data)
            } else {
                self.setState({  message : "Something was wrong" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            //console.log(error);
        });
    }

    
    
    handleInputChange(event) {
        const target = event.target;
        let value = target.type === 'radio' ? target.checked : target.value;
        let rev_value;
        if(value) {
            value     = 1;
            rev_value = 0;
        } else {
            value     = 0;
            rev_value = 1;
        }
        const name = target.name;
        let str = this.state.contests;
        // console.log(name)
        // console.log(name.split("-").length)

        if(name.split("-").length>0) {
            let word  = name.split("-");
            let first = word[0];
            let id    = word[1];
            console.log(first)
            if(first === "per_result1") {
                str[id].per_result1 = value;
                str[id].per_result2 = rev_value;
            }

            if(first === "per_result2") {
                str[id].per_result2 = value;
                str[id].per_result1 = rev_value;
            }

            if(first === "over") {
                str[id].over = value;
                str[id].under = rev_value;
            }

            if(first === "under") {
                str[id].under = value;
                str[id].over = rev_value;
            }
            
        }
        
        

        this.setState({contests:str});
        //console.log(name)
        // this.setState({
        //   [name]: value
        // });
        console.log(this.state.contests)
    }

    displayBlock( ) {
        let data = this.state.data
        //console.log(data.length)
        if(data.length  > 0){
            let ui = []
            // data.forEach(function(each){
            for (let i = 0; i < data.length; i++) { 
                let each = data[i]
                ui.push(<div className="league-box box-shadow"><div className="league-list-header p-4"><span>{each.title}</span></div><div className="league-box-paragraph"><div className="row">
                {each.description}<br/><sub><i>{moment(each.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMMM YYYY, hh:mm A')}</i></sub></div></div></div>)
                
                
           }
           if(ui.length === 0) {
                ui.push(<div className="text-center" key="0"><h2>အချက်အလက်များရှာမတွေ့ပါ။</h2></div>)
           }
            return ui
        }
    }

    componentDidMount() {
        // console.log(mmph.normalizeInput('+၉၅၉၇၈၄၁၂၃၄၅၆')); // returns 09784123456
        // console.log(mmph.normalizeInput('+959784123456')); // returns 09784123456
        // console.log(mmph.getTelecomName('09978412345')); // returns Ooredoo
        // console.log(mmph.getPhoneNetworkType('09978412345')); // returns GSM
        // console.log(mmph.isValidMMPhoneNumber('09978412345')); // returns true if it meets Myanmar Phone

        if(localStorage.getItem(window.shwesports.time)) {
              if(localStorage.getItem(window.shwesports.time) !== "0" ) {
                  document.getElementById( 'toggle-home' ).style.display = 'none';
              }
        }
        // document.getElementById( 'toggle-home' ).style.display = 'none';
        
        if(localStorage.getItem('time')) {
            if(localStorage.getItem('time') !== "0" ) {
              this.setState({ step: "profile" }); 
            }
            
          } 

        if(localStorage.getItem('noti')) {
            if(JSON.parse(localStorage.getItem("noti")) != null) {
              let noti = JSON.parse(localStorage.getItem("noti"));
              this.setState({data: noti})
              
            }
        } 
        // if(localStorage.getItem('username')) {
        //         this.setState({ 
        //             form: {
        //                 username : localStorage.getItem('username')
        //             } 
        //         }); 
        // } 

        // localStorage.setItem('time', 0);
        // localStorage.setItem('username', "");
        // else {
        //   localStorage.setItem('time', 'profile');
        //   this.setState({ step: "home" }); 
        // }
        this.hisUrl('/more/announcements');
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
        this.getAnnouncementsApi();
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    render() {
        return (
            <div className="page">
                <div className="page-content">
                    <div className="main-content text-center">
                        
                        <div className="row text-left">
                            <br />
                            <div class="list-header">
                                <img src="/img/badge.jpg" class="list-img-circle" alt={window.lang.more.announcement} /><span>{window.lang.more.announcement}</span>
                            </div>
                            <br/>
                            {this.displayBlock()}
                        </div>
                        <div className="ant-back-footer">
                            <div class="ant-back-top">
                                <div class="ant-back-top-content">
                                    <Link to={window.shwesports.preurl} className="color-green" >
                                { (JSON.parse(localStorage.getItem("route")) !== null) 
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AnnouncementsPage;