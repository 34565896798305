import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
  
class FeedbackPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = { step: "feedback", message : "", form : {time: '',title: '', description: '' }};
        //this.state = { step: "register", message : "", form : {username: '', phone: '',verify_code: '', password: '' }};
        this.validatePhone      = this.validatePhone.bind(this);
    }

    validatePhone() {
        let data
        //console.log(this.state.form.phone.length)
        if( this.state.form.title !== "" &&  this.state.form.description !== "") {
            data = {
                title : this.state.form.title,
                description : this.state.form.description,
                time : this.state.form.time
            }
            this.setState({  message : "Sending Data" });
            this.sendFeedbackApi(data);
            
        } else {
            //console.log(this.state.message)
            this.setState({  message : "Please fill up required field." });
        }
        
    }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    sendFeedbackApi(formdata) {
        var self = this;
        let dataurl = window.shwesports.apiUrl+'/user/feedback';
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then(function (response) {
            if(response.data.success) {
                self.setState({  form : {title: '', description: '' } , message : "အကြံပြုချက် အတွက်ကျေးဇူးတင်ပါသည်။"});
            } else {
                self.setState({  message : "တစ်ခုခု မှားယွင်းနေပါသည်။" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            console.log(error);
        });
    }


    feedbackBlock() {
        return <div className="container">
            <div className="row">
                        <div className="grid-30 grid-s-100 left">
                            <div className="form-group text-left">
                                <label>{window.lang.form.title}</label>
                            </div>
                        </div>
                        <div className="grid-70 grid-s-100 left">
                            <div className="form-group text-left">
                            <input type="text" className="form-control" name="title" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.title} /><br />
                            </div>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="grid-30 grid-s-100 left">
                            <div className="form-group text-left">
                                <label>{window.lang.form.description}</label>
                            </div>
                        </div>
                        <div className="grid-70 grid-s-100 left text-right">
                            <div className="form-group text-center">
                            <textarea className="form-control text-left"  name="description" onChange={(e) => this.inputChangeHandler.call(this, e)}  rows="3">
                              {this.state.form.description} 
                            </textarea>
                            <br/>
                            </div>
                            <input type="submit" className="btn btn-success grid-40" value={window.lang.form.submit} onClick={this.validatePhone} /><br />
                        </div>
                        <div className="ant-back-footer">
                            <div class="ant-back-top">
                                <div class="ant-back-top-content">
                                    <Link to={window.shwesports.preurl} className="color-green" >
                                { (JSON.parse(localStorage.getItem("route")) !== null) 
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link> 
                                </div>
                            </div>
                        </div> 
            </div>
        </div>
    }


    

    componentDidMount() {

        if(localStorage.getItem('time')) {
            if(localStorage.getItem('time') !== "0" ) {
                //localStorage.setItem('time', true);
                console.log("ok");
            } else {
                this.props.history.push("/more/sign-up");
                }
        } else {
            this.props.history.push("/more/sign-up");
        }

        if(localStorage.getItem(window.shwesports.time)) {
              if(localStorage.getItem(window.shwesports.time) !== "0" ) {
                  document.getElementById( 'toggle-home' ).style.display = 'none';
              }
        }
        // document.getElementById( 'toggle-home' ).style.display = 'none';

        // if(localStorage.getItem('time')) {
        //     if(localStorage.getItem('time') !== "0" ) {
        //       this.setState({ step: "feedback" }); 
        //     }
            
        //   } 

        if(localStorage.getItem('username')) {
                this.setState({ 
                    form: {
                      time : localStorage.getItem('time')
                    } 
                }); 
        } 

        this.hisUrl('/more/feedback');
    }


    

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                    menu.pop();
                    //window.shwesports.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }  
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.shwesports.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    
                }
                //window.shwesports.preurl =  menu[menu.length-1];
                window.shwesports.preurl =  menu[menu.length-2];
            }
        }
      }
    

    render() {
        return (
            <div className="page">
                <div className="page-content">
                    <div className="main-content text-left">
                        <br />
                    
                        <div class="list-header">
                            <img src="/img/badge.jpg" class="list-img-circle" alt={window.lang.more.feedback} /><span>{window.lang.more.feedback}</span>
                        </div>
                        <br/>
                        <span>{this.state.message}</span>
                        <br />
                        <br />
                        
                        { this.feedbackBlock() }
                    
                    </div>
                </div>
            </div>
        );
    }
}

export default FeedbackPage;