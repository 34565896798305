import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
import { Modal }  from 'antd-mobile';



//var moment = require('moment');
require('./../../../Shwesports.js')
const alert = Modal.alert;

class LeagueTablePage extends React.Component {
    constructor(props) {
        super(props)

        window.shwesports.footerMenu = "CONTEST";
        this.state = {
            selectedDays: [new Date()],
            status: 1,
            step: "login",
            message : "",
            phone : "",
            league: [],
            advertise: {},
            contests :
                {
                    id_gcontest :	0,
                    event_name	: "",
                    rule_regulation	: "",
                    created_at      : "",
                    start_date	    : "",
                    expire_date	    : "",
                }
            ,
            data            : [],
            isLoading       : true
        };
        this.checkLeaderboardSup         = this.checkLeaderboardSup.bind(this);
        //this.handleInputChange = this.handleInputChange.bind(this);
    }

    checkLeaderboardSup(id) {
        //console.log("ID --------- "+ id)
        //console.log(this.state.step);

        // alert(id)

        alert('10 Gems ကျသင့်မှာ ဖြစ်ပါသည်', '၀ယ်ယူမလား မ၀ယ်ဘူးလား', [
            { text: 'မ၀ယ်ဘူး', onPress: () => console.log('cancel'+id) },
            {
            text: '၀ယ်မယ်',
            onPress: () => {
                    // localStorage.setItem('time', 0);
                    // localStorage.setItem('username', "");
                    // this.setState({ step: "login" , message : "Successfully Log Out" });
                    // //this.profileBlock()
                    // //this.viewHandler();
                    this.props.history.push("/participant/predict/order/"+id);
                }

            },
        ])

    }

    // checkLeaderboardSup() {
    //     //console.log(this.state.step);
    //     let data = window.confirm("Have you finished participant competition subscription?");
    //     if(data){
    //         console.log("ok");
    //     }
    // }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          }
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.shwesports.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.shwesports.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.shwesports.preurl =  menu[menu.length-2];
            }
        }
    }

    fetchData(url,data) {

        // let phone = localStorage.getItem('time')

        let dataurl = url;

        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
            this.setState({
                top         : resp.data.top,
                bottom      : resp.data.bottom,
                status      : resp.data.status,
                advertise   : resp.data.advertise,
                isLoading   : false
            });
            //console.log(this.state);
        })
        .catch(function(error) {
        //   console.log(error.tostring());
        });

        //console.log(this.state.contests);

    }

    displayBlock1( ) {
        let data = this.state.top
        //console.log(data.length)
        if(data.length  > 0){
            let ui = []

                ui.push(<div className="league-box" key="0">
                        <div className="league-list-header">
                            <img src="/img/badge.jpg" className="list-img-circle" alt="Trending" />
                                <span>Leaderboard (Top 20)</span>
                        </div>
                        <div className="row">
                            <table className="table  table-border league-table" >
                                <tbody>
                                    {this.childui(0,data)}
                                    </tbody>
                            </table>
                        </div>
                </div>)
            return ui
        }
    }

    displayBlock2( ) {
        let data = this.state.bottom
        //console.log(data.length)
        if(data.length  > 0){
            let ui = []

                ui.push(<div className="league-box" key="0">
                        <div className="league-list-header">
                            <img src="/img/badge.jpg" className="list-img-circle" alt="Trending" />
                                <span>Leaderboard (Bottom 20)</span>
                        </div>
                        <div className="row">
                            <table className="table  table-border league-table" >
                                <tbody>
                                    {this.childui(0,data)}
                                    </tbody>
                            </table>
                        </div>
                </div>)
            return ui
        }
    }


    childui(id,data) {
        let ui = []
            //console.log("-------------"+data)

            if(Array.isArray(data) === false) {
                // console.log(data)
                // console.log(this.state.contests)
            } else {

                let arlength = data.length;

                if(arlength  > 0){
                    let ui = []
                    // data.forEach(function(each){
                    for (let i = 0; i < arlength; i++) {
                        let each = data[i]

                        //check handicap and change setting

                        ui.push(<tr key={id+""+i}>
                                <td><b>{i+1}</b></td>
                                <td colSpan="3" className="text-center pointer">
                                    {/* function need to add */}
                                    {/* <b  className="color-green" onClick={this.checkLeaderboardSup.bind(this, each.id)} >
                                        {each.username}
                                    </b> */}

                                     <Link to={"/more/account/"+each.id}  className="color-green">
                                        {each.username}
                                    </Link>
                                    {/*<Link to={"/participant/predict/order/"+each.id}  className="color-green">
                                        {each.username}
                                    </Link> */}
                                    {/* <b className="color-green" >{each.username}</b> */}
                                    {/* <b className="color-green" onClick={() => this.checkLeaderboardSup(each.id)} >{each.username}</b> */}
                                </td>
                                <td colSpan="2" className="text-center">
                                    {each.phone.substring(0, each.phone.length - 4)+"****"}
                                </td>
                                <td>{each.league_points}</td>
                            </tr>)

                }

                if(ui.length === 0) {
                    //this.setState({status : 1});
                        ui.push(<div className="text-center" key="0"><h2>There is no event today</h2></div>)
                }
                    return ui
                }
        }

        // console.log(status+"-------")
        // if(status === 1) {
        //     console.log(status+"-------")
        //     this.setState({status : 0});
        // }

        return ui;
    }


    //childui


    sendDailyBonusApi() {

        var self = this;
        if(localStorage.getItem('time') !== 0) {
            // this.setState({
            //     phone:  localStorage.getItem('time')
            // })
            let dataurl = window.shwesports.apiUrl+'/user/dailybonus';
            // let contests = this.state;
            let contests = {
                phone:  localStorage.getItem('time')
            };
            axios({
                method: 'post',
                url: dataurl,
                data: contests,
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
            })
            .then(function (response) {
                if(response.data.success) {
                    self.setState({
                        message : response.data.message,
                        status  : 1,
                        check: response.data.check
                    });

                    // let stateday = moment(self.state.selectedDays[0]).format("YYYY-MM-DD");
                    // let phone = localStorage.getItem('time') ;
                    // self.fetchData(window.shwesports.apiUrl+'/user/league-contests?phone='+phone+'&filter='+stateday,'livescore');

                    //self.setState({  message : "Successfully updated" });
                } else {
                    self.setState({
                        message : "Something was wrong",
                     });
                }
                //console.log(response.data.success);
            })
            .catch(function (error) {
                // console.log(error);
            });
        }

    }

    componentDidMount() {
        if(localStorage.getItem('time')) {
            if(localStorage.getItem('time') !== "0" ) {
              this.setState({
                  step: "profile"
                });
            }
        }

        if(localStorage.getItem(window.shwesports.time)) {
              if(localStorage.getItem(window.shwesports.time) !== "0" ) {
                  document.getElementById( 'toggle-home' ).style.display = 'none';
              }
        }
        // document.getElementById( 'toggle-home' ).style.display = 'none';

        this.hisUrl('/more/league-table');
        // let stateday = moment(this.state.selectedDays[0]).format("YYYY-MM-DD");
        // let phone = localStorage.getItem('time') ;
        this.fetchData(window.shwesports.apiUrl+'/user/league-table','livescore');
        //this.fetchData();
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    render() {
        return (
            <div className="page">
                <div className="page-content text-center">
                    <div className="main-content">
                        <div className="row text-left">
                            <br/>
                                <div className="list-header">
                                    <img src="/img/badge.jpg" className="list-img-circle" alt={window.lang.title.leaderboard}     /><span>{window.lang.title.leaderboard}      </span>
                                </div>

                            <br />
                            <ul className="secondsubmenu">
                                <li>
                                    <Link to="/more/league-contest" className="btn-success btn" >
                                        {window.lang.title.contest}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/more/league-table" className="btn-success btn color-gold" >
                                        {window.lang.title.leaderboard}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/more/league-history" className="btn-success btn" >
                                        {window.lang.title.history}
                                    </Link>
                                </li>
                            </ul>

                        </div>
                        <div className="row text-left">
                            <span>{this.state.message}</span>
                            <br />
                            { (this.state.isLoading) ?  (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> ) : this.displayBlock1() }
                            <br />
                            {
                                (this.state.advertise) ? <div className="row">
                                <div className="grid1">
                                    <a href={this.state.advertise.advertise_goto} target="_blank" rel="noopener noreferrer">
                                        <img src={window.shwesports.apiAssetUrl+"/images/"+this.state.advertise.advertise_image} style={{ width: '100%' }} alt={this.state.advertise.advertise_title}/>
                                    </a><br/><br/>
                                </div>
                                </div>: ""
                            }
                            <br />
                            { (this.state.isLoading) ?  (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> ) : this.displayBlock2() }

                        </div>

                        <div className="ant-back-footer">
                            <div className="ant-back-top">
                                <div className="ant-back-top-content">
                                    <Link to={window.shwesports.preurl} className="color-green" >
                                { (JSON.parse(localStorage.getItem("route")) !== null)
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

export default LeagueTablePage;
