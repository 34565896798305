import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
import mmph from 'myanmar-phonenumber';

class SignUpPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { step: "home", message : "", form : {username: '', phone: '',verify_code: '', password: '' }};
        //this.state = { step: "register", message : "", form : {username: '', phone: '',verify_code: '', password: '' }};
        this.validatePhone      = this.validatePhone.bind(this);
        this.checkValidationApi = this.checkValidationApi.bind(this);
        this.changePasswordApi  = this.changePasswordApi.bind(this);

        this.changestate        = this.changestate.bind(this);
    }

    changestate() {
        this.setState({ step : "register" });
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }
        }
       // console.log(localStorage.getItem('route'));
    }

    componentDidMount() {
        // console.log(mmph.normalizeInput('+၉၅၉၇၈၄၁၂၃၄၅၆')); // returns 09784123456
        // console.log(mmph.normalizeInput('+959784123456')); // returns 09784123456
        // console.log(mmph.getTelecomName('09978412345')); // returns Ooredoo
        // console.log(mmph.getPhoneNetworkType('09978412345')); // returns GSM
        // console.log(mmph.isValidMMPhoneNumber('09978412345')); // returns true if it meets Myanmar Phone
        if(localStorage.getItem('time')) {
            if(localStorage.getItem('time') !== "0" ) {
              this.setState({ step: "profile" }); 
            }
            
          } 
        if(localStorage.getItem('username')) {
                this.setState({ 
                    form: {
                        username : localStorage.getItem('username')
                    } 
                }); 
        } 

        this.hisUrl('/more/sign-up');
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/sign-up');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    validatePhone() {
        let form = this.state.form
        let data
        //console.log(this.state.form.phone.length)
        if(form.phone.length >= 6) {
            if(mmph.isValidMMPhoneNumber(form.phone)){
                data = {
                    username : this.state.form.username,
                    phone : this.state.form.phone
                }
                this.setState({  message : "လုပ်ဆောင်နေပါသည်။" });
                this.sendSMSApi(data);
            } else {
                this.setState({  message : "ဖုန်းနံပါတ် မှားယွင်းနေပါသည်။" });
            }
            
        } else {
            //console.log(this.state.message)
            this.setState({  message : "ဖုန်းနံပါတ် မှားယွင်းနေပါသည်။" });
        }
        
    }
    sendSMSApi(formdata) {
        var self = this;
        let dataurl = window.shwesports.apiUrl+'/user/register';
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then(function (response) {
            if(response.data.success === 2) {
                self.setState({  message : "သင်မှတ်ပုံတင်ထားပြီး ဖြစ်ပါသည်" });
            } else if(response.data.success === 1) {
                self.setState({  message : "SMS ပို့ပြီးပါပြီ", step : "verify" });
            }  else {
                self.setState({  message : "တစ်ခုခုတော့မှားနေပြီ" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    checkValidationApi() {
        let formdata = this.state.form ;
        var self = this;
        let dataurl = window.shwesports.apiUrl+'/user/verifycode';
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then(function (response) {
            if(response.data.success) {
                self.setState({  message : "Successfully", step : "password"  });
            } else {
                self.setState({  message : "Something was wrong" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    changePasswordApi() {
        if(this.state.form.password.length >= 6) {
            let formdata = this.state.form ;
            var self = this;
            let dataurl = window.shwesports.apiUrl+'/user/pwd';
            axios({
                method: 'post',
                url: dataurl,
                data: formdata,
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
            })
            .then(function (response) {
                if(response.data.success) {
                    self.setState({  message : "Successfully", step : "profile"  });
                    localStorage.setItem('time', self.state.form.phone );
                    localStorage.setItem('username', self.state.form.username );
                } else {
                    self.setState({  message : "Something was wrong" });
                }
                //console.log(response.data.success);
            })
            .catch(function (error) {
                // console.log(error);
            });
        } else {
            this.setState({  message : "စကားဝှက်မှာအနည်းဆုံးအက္ခရာ ၆ လုံးရှိရမည်" });
        }
        
    }

    viewHandler() {

        switch(this.state.step) {
            // case "line-up":   return "";
            case "register":   return this.registerBlock();
            case "home":    return this.homeBlock();
            case "verify": return this.verifyBlock();
            case "password": return this.passwordBlock();
            case "profile": return this.profileBlock();
            default:      return "";
          }
    }

    homeBlock() {
        return <div>
            <br />
            <br />
            {/* <h1 className="color-green">SHWE SPORTS</h1> */}
            <img src="/img/shwesport1.png" alt="profile" className="medium-logo"/>
            {/* <img src="/img/league/shwesport1.png" alt="shwesports logo" /> */}
            <br />
            <span>{this.state.message}</span>
            <br />
            <br />
            <div className="row">
                <Link to="/more/login" className="btn btn-success grid-50" style={{fontSize: "1rem"}}>{window.lang.form.login}</Link><br /><br />
                <Link to="/more/sign-up" className="btn btn-yellow grid-50" onClick={this.changestate} style={{fontSize: "1rem"}}>{window.lang.form.register}</Link>
            </div>
        </div>
    }

    registerBlock() {
        return <div className="form-box">
            <div className="row">
                        <div className="grid-30 grid-s-100 left">
                            <div className="form-group text-left">
                                <label>{window.lang.form.name}</label>
                            </div>
                        </div>
                        <div className="grid-70 grid-s-100 left">
                            <div className="form-group text-center">
                            <input type="text" className="form-control text-center" name="username" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.username} /><br />
                            </div>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="grid-30 grid-s-100 left">
                            <div className="form-group text-left">
                                <label>{window.lang.form.phone}</label>
                            </div>
                        </div>
                        <div className="grid-70 grid-s-100 left text-right">
                            <div className="form-group">
                            <input type="text" className="form-control text-center" placeholder="09**********" name="phone" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.phone} /><br />
                            </div>
                            <Link to="/more/login" className="color-green">{window.lang.form.login}</Link> | &nbsp;&nbsp;
                            <input type="submit" className="btn btn-success grid-40" value={window.lang.form.submit} onClick={this.validatePhone} /><br />
                        </div>
                        
                    </div>
        </div>
    }

    verifyBlock() {
        return <div>
                    <div className="row">
                        <div className="grid-30 grid-s-100 left">
                            <div className="form-group text-left">
                                <label>Verify Code</label>
                            </div>

                        </div>
                        <div className="grid-70 grid-s-100 left">
                            <div className="form-group text-center">
                            <input type="text" className="form-control text-center"  name="verify_code" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.verify_code} maxlength="6" /><br />
                            </div>
                            <input type="submit" className="btn btn-success grid-50" value="Submit" onClick={this.checkValidationApi} /><br />
                        </div>
                        
                    </div>
        </div>
    }

    passwordBlock() {
        return <div>
                    <div className="row">
                        <div className="grid-30 grid-s-100 left">
                            <div className="form-group text-left">
                                <label>New Password</label>
                            </div>
                        </div>
                        <div className="grid-70 grid-s-100 left">
                            <div className="form-group text-center">
                            <input type="password" className="form-control text-center"  name="password" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.password} /><br />
                            </div>
                            <input type="submit" className="btn btn-success grid-50" value="Submit" onClick={this.changePasswordApi} /><br />
                        </div>
                        
                    </div>
        </div>
    }

    profileBlock() {
        return <div>
            <div className="row">
                <h2>Hi {this.state.form.username}</h2>
                <p>Welcome to Shwesports</p>
            </div>
        </div>
    }

    render() {
        return (
            <div className="page">
                <div className="page-content text-center">
                    <div className="main-content">
                        { (this.state.step !==  "home") ? <div className="row">
                        <br />
                        <br />
                        <h1 className="color-green">{window.lang.form.register}</h1>
                        <br /></div>: ""}
                        
                        <span>{this.state.message}</span>
                        
                        { this.viewHandler() }
                    </div>
                </div>
            </div>
        );
    }
}

export default SignUpPage;