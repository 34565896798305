/* eslint no-dupe-keys: 0 */
import React from 'react';
import Swiper from 'swiper/dist/js/swiper.min.js';

import {
  Link
} from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-responsive-modal';
import { ListView } from 'antd-mobile';
import NewsVideoSlider from './../NewsVideoSlider';
//import HighlightsVideoSlider from './HighlightsVideoSlider';
import HomeListPage from './HomeListPage';

import ScrollMenu from 'react-horizontal-scrolling-menu';
//import { FacebookProvider, CommentsCount } from 'react-facebook';
//import { askForPermissioToReceiveNotifications } from './../push-notification';
require('./../../../Shwesports.js')

//var moment = require('moment');

// const list = [
//   { id: 0, name: 'NEWS', shortname: 'NEWS', url : 'NEWS' },
//   { id: 0, name: 'HIGHLIGHTS',  shortname: 'HIGHLIGHTS',  url : 'HIGHLIGHTS' },
//   { id: 1, name: 'English Premier League',  shortname: 'EPL',  url : 'epl' },
//   { id: 2, name: 'Spain Laliga Premier', shortname: 'LALIGA', url : 'laliga' },
//   { id: 3, name: 'Itlaian Serie A', shortname: 'SERIE A', url : 'serie-a' },
//   { id: 4, name: 'German Bundesliga',shortname: 'BUNDESLIGA',  url : 'bundesliga' },
//   { id: 5, name: 'France Ligue 1',shortname: 'LIGUE 1',  url : 'ligue-one' },
//   { id: 6, name: 'UEFA Champion League', shortname: 'CHAMPION LEAGUE', url : 'champion' },
//   { id: 7, name: 'UEFA Europa League', shortname: 'EUROPA LEAGUE', url : 'europa' },
//   { id: 8, name: 'World Cup', shortname: 'WORLDCUP', url : 'worldcup' },
//   { id: 9, name: 'Euro', shortname: 'EURO', url : 'euro' },
//   { id: 10, name: 'Others', shortname: 'OTHERS', url : 'others' }
// ];


let data = [
  {
    news_image: 'https://zos.alipayobjects.com/rmsportal/dKbkpPXKfvZzWCM.png',
    news_name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut consectetur temporibus eligendi fuga, dolor expedita ',
    news_desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut consectetur temporibus eligendi fuga, dolor expedita ',
  }
];


let NUM_ROWS = 3;
//let MAX_LENGTH = 90;
// One item component
// selected prop will be passed
const MenuItem = ({ activeMenu, data, text, selected }) => {
  return (
    
      <div
      className="menu-item"
      >
        {data.id === 0 ? (
          <Link to={`${data.url}`} className={(activeMenu === text) ? 'active cont-wrap-link' : 'cont-wrap-link' } >{(window.shwesports.lang === 'mm')? data.mmshortname : data.shortname }</Link>
        ) : (
          <Link to={`/news/league/${data.id}/${data.url}`} className={(activeMenu === text) ? 'active cont-wrap-link' : 'cont-wrap-link' } >{(window.shwesports.lang === 'mm')? data.mmshortname : data.shortname }</Link>
        )}
        {/* <Link to="/about" className={(activeMenu === text) ? 'active' : '' }  > */}
         
          {/* <span>{text}</span> */}

        {/* </Link> */}
      </div>
  );
};


// All items component
// Important! add unique key
export const Menu = (list,selected,activeMenu) => list.map(el => {
  const { name,shortname } = el;
  // console.log(selected)
  return (
    <MenuItem
      activeMenu={activeMenu}
      text={shortname}
      key={name}
      data={el}
    />
  );
});


const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    >{text}</div>
  );
};


const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });


const closeIcon = (
  <svg fill="currentColor" viewBox="0 0 20 20" width={28} height={28}>
    <path
      fillRule="evenodd"
      d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
      clipRule="evenodd"
    ></path>
  </svg>
);


class HomePage extends React.Component {
  constructor(props) {
    super(props);
    window.shwesports.footerMenu = "NEWS";
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });

    this.state = {
      selected: 0,
      dataSource,
      isLoading: true,
      // virtual data
      videoSlides:['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],

      virtualVideoData: {
        slides: ['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],
      },
      leagueMenu: 'NEWS',
      open: false,
      advopen : false,
      menu : window.shwesports.mainmenu,
    };


    
  }
  onSelect = key => {
    this.setState({ selected: key });
  }

  onOpenModal = () => {
    this.setState({ open: true,  advopen : true });
  };
 
  onCloseModal = () => {
    this.setState({ open: false , advopen : false });
    

    //localStorage.setItem('popup', false);
    // window.shwesports.popup = false
    // console.log(window.shwesports.popup)
  };

  componentDidMount() {
    let menu = ["/"];

    localStorage.setItem("route", JSON.stringify(menu) );
    
    // document.getElementById( 'toggle-home' ).style.display = 'flex';

    
    
    // console.log(localStorage.getItem('route'));
    //console.log(window.shwesports.apiUrl)
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);

    

    const self = this;


    new Swiper('.swiper-video-container', {
      // ...
      loop: true,
      slidesPerView: 1,
      slidesPerGroup: 1,
      virtual: {
        slides: self.state.videoSlides,
        renderExternal(videoData) {
          // assign virtual slides data
          self.setState({
            virtualVideoData: videoData,
          });
        }
      },
      pagination: {
        el: '.swiper-vieo-pagination',
      },
    });

    
   

    // this.setState({ open: true }); 

      var d = new Date();
      var month = d.getDay();

      if(localStorage.getItem('popup')) {
        if(localStorage.getItem('popup') != month) {
          localStorage.setItem('popup', month);
          this.setState({ open: true }); 
        }
        
      } else {
        localStorage.setItem('popup', -1);
        this.setState({ open: true }); 
      }
      this.setState({ open: true }); 

      this.setState({ open: false});
      //console.log(localStorage.getItem('popup'));                            
      //this.fetchData();

      if(localStorage.getItem(window.shwesports.time)) {
          if(localStorage.getItem(window.shwesports.time) !== "0" ) {
              document.getElementById( 'toggle-home' ).style.display = 'flex';
          }
      }
      
      clearTimeout(window.shwesports.liveurl);

      

  }

  componentWillUnmount() {
    clearInterval(this.state)
  }

  UNSAFE_componentWillMount() {
      window.shwesports.lang = localStorage.getItem("lang");
  }

  fetchData() {
        
    const url = window.shwesports.apiUrl+'/news?limit='+NUM_ROWS+'&start=10&featured=1';

    axios.get(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "no-cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      //credentials: "same-origin", // include, *same-origin, omit
      crossDomain: true,
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      //body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    .then(resp => {
      // console.log(this.data.concat(resp.data));
      data = resp.data.news;
        
        this.setState({
          dataSource: this.state.dataSource.cloneWithRows(data.reverse()),
          isLoading: false,
          menu : resp.data.menu
        });

        // this.data = { ...this.data, ...genData(++this.pageIndex) };
        // console.log(this.data)
        NUM_ROWS = Number(NUM_ROWS) + Number(NUM_ROWS);
        // console.log(this.pageIndex)
        // lang = localStorage.getItem('lang');
        
      })
      .catch(function(error) {
        //console.log(error);
      });   
  }
  // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.dataSource !== this.props.dataSource) {
  //     this.setState({
  //       dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
  //     });
  //   }
  // }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    // if (this.state.isLoading && !this.state.hasMore) {
    //   return;
    // }
    // console.log('reach end', event);
    // this.setState({ isLoading: true });
    // setTimeout(() => {
    //   this.rData = { ...this.rData, ...genData(++pageIndex) };
    //   this.setState({
    //     dataSource: this.state.dataSource.cloneWithRows(this.rData),
    //     isLoading: false,
    //   });
    // }, 1000);
  }

  render() {
    const { selected } = this.state;
    let leagueMenu = this.state.leagueMenu;
    // Create menu from items
    const menu = Menu(this.state.menu, selected, leagueMenu);

    return (
      <div className="page">
        <div className="page-content">
            
            <ScrollMenu
              data={menu}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              selected={selected}
              onSelect={this.onSelect}
              alignCenter={false}
              wheel={true}
              inertiaScrolling={true}
              // scrollToSelected={true}
            />

            {/* <button onClick={askForPermissioToReceiveNotifications} >
                  Click here to receive notifications
            </button> */}
            
              <NewsVideoSlider />

              <div className="main-content">

                    <Modal open={this.state.open} onClose={this.onCloseModal} center closeIcon={closeIcon} classNames={{
                            overlay: 'customOverlay',
                            modal: 'customModal',
                          }} >
                      <center>
                        {/* <br/> */}
                        {/* <a href="/contest" >   */}
                        <Link to="/contest">
                          <img src="/img/shwesport_popup.jpg" width="100%"/>
                        </Link>
                      {/* <br />
                      <a href="/contest"  className="download-apk-button">Go to Contest</a> */}
                      </center>
                    </Modal>
                    
                  
                    

                {/* <ListView
                  ref={el => this.lv = el}
                  dataSource={this.state.dataSource}
                  renderHeader={() => <div className="list-header"><Link to="/feature"><img src="/img/trending.jpg" className="list-img-circle" alt="Trending" /><span>TRENDING NEWS</span></Link></div>}
                  // renderFooter={() => (<div style={{ padding: 30, textAlign: 'center' }}>
                  //   {this.state.isLoading ? 'Loading...' : 'Loaded'}
                  // </div>)}
                  renderRow={row}
                  renderSeparator={separator}
                  className="am-list" 
                  useBodyScroll
                  // onScroll={() => { console.log('scroll'); }}
                  scrollRenderAheadDistance={500}
                /> */}

                <HomeListPage pageIndexTotal="20" perPage="30" isLoading="false" />

              </div>

              {/* <HighlightsVideoSlider></HighlightsVideoSlider> */}
            

        </div>
      </div>
    );
  }
}

export default HomePage;