import React from "react";
import {
  Route,
  Switch,
  Redirect,
  Link
} from 'react-router-dom';
import SideMenu from "../components/sidemenu/SideMenu";
import "./ToolBar.css";

let checklogin = () => {
      if(localStorage.getItem(window.shwesports.time)) {
          if(localStorage.getItem(window.shwesports.time) !== "0" ) {
              return true;
          }
      }
      return false;
  }

const toolbar = props => (
  <header className="App-header">
      <div className="App-header-inner">
        <div className="row text-center">
          <div className="align-center left grid-10 logo">
              {(checklogin()) ?
              <div className="toggle-btn">
                <SideMenu click={props.drawerToggleClickHandler} />
              </div>
              : "" }
          </div>
          <div className={
              ((navigator.userAgent.toLowerCase().indexOf("shwesports") > -1 ) ? "align-right left grid-50 logo app-header-center" : "align-right left grid-50 logo" ) }>
            <Link to="/" className="color-white">
              <b><img src="/img/logoonly.png" style={{height: "25px", verticalAlign: "top", marginRight:"3px"}} alt="Shwe Sports"/>hwe Sports</b>
            </Link>
          </div>
          <div className={
              ((navigator.userAgent.toLowerCase().indexOf("shwesports") > -1 ) ? "align-right right grid-40 app-header-right" : "align-right right grid-40 " ) }>
            
            {
              ((navigator.userAgent.toLowerCase().indexOf("shwesports") > -1 ) ? "" : <Link to="/download" className="btn btn-yellow" style={{height: "25px", verticalAlign: "top", marginBottom : "3px" }} >App 
              <img src="/img/guide/download.png"  alt="download" style={{height: "25px" , verticalAlign: "top", paddingLeft: "10px"}}/>
            </Link>  )                   
            }
            
            
            <Link to="/more/myaccount" style={{marginBottom : "3px",paddingLeft: "10px"}}>
               <img src="/img/profile.jpg" className="round-img" alt="profile"/>
            </Link> 
            {/* <div className="download-apk-here">
                {
                  ((navigator.userAgent.toLowerCase().indexOf("shwesports") > -1 ) ? "" : <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.shwesports.ss" >Download APK Here</a> )                   
                }
            </div> */}
            
          </div>
        </div>
      </div>
    </header>
);

export default toolbar;