import React, { useState, useEffect } from "react";
// import queryString from 'query-string';
import io from "socket.io-client";

// import TextContainer from '../TextContainer/TextContainer';
import Messages from '../Messages/Messages';
import InfoBar from '../InfoBar/InfoBar';
import Input from '../Input/Input';

import './Chat.css';

let socket;

const HelpCenterChat = () => {
  let [name, setName] = useState('');
  const [room, setRoom] = useState('');
  //const [total, setTotal] = useState('');
  const [users, setUsers] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  // const ENDPOINT = 'http://project-chat-application.herokuapp.com/';
  //const ENDPOINT = 'http://127.0.0.1:5001/';
  const ENDPOINT = 'https://stream.shwesports.com:8182/';
  

  useEffect(() => {
    // var d = new Date();
    // var n = d.getUTCSeconds();
    var n = Math.floor(Math.random() * 10000) + 1;

    let username = localStorage.getItem('username');

    if(username) {
      name = username +n
      setName(name);
    } else {
      name = "User"+n;
      setName(name);
    }
    //let name = username +n;
    

    // if(localStorage.getItem('time')) {
    //   let time = localStorage.getItem('time');
    //   if( time !== "0" ) {
        
    //   }
      
    // } else {
    //   let name = "Anynomous-"+n;
    //   setName(name);
    // }
    
    let room = "Help Center";

    
    //const { name, room } = queryString.parse(location.search);

    socket = io(ENDPOINT);

    setRoom(room);
    

    socket.emit('join', { name, room }, (error) => {
      if(error) {
        alert(error);
      }
    });
  }, [ENDPOINT, ""]);
  
  useEffect(() => {
    socket.on('message', message => {
      setMessages(messages => [ ...messages, message ]);
    });
    
    socket.on("roomData", ({ users }) => {
      setUsers(users);
    });
}, []);

  const sendMessage = (event) => {
    event.preventDefault();
    
    if(message) {
      socket.emit('sendMessage', message, () => setMessage(''));
    }
  }

  let callbackFunction = (childData) => {

    //event.preventDefault();
    //console.log(childData+"=========");

    //if(childData) {
      setMessage(childData)
   
      socket.emit('sendMessage', childData, () => setMessage(''));
    //}
    

     // this.setState({message: childData})
  }

  return (
        <div className="outerContainer">
          <div className="container-full-chat">
              <InfoBar room={room} total={users.length}/>
              <Messages messages={messages} name={name} parentCallback={callbackFunction}/>
              <Input message={message} setMessage={setMessage} sendMessage={sendMessage}  total="0"/>
          </div>
          {/* <TextContainer users={users}/> */}
        </div>
  )                                                                                                                                                                                                                                                                                                         
}

export default HelpCenterChat;
