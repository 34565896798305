import React from "react";
import "./SideDrawer.css";
import {
    Link
  } from 'react-router-dom';

import { Modal }  from 'antd-mobile';


const alert = Modal.alert;

 let checklogin = () => {
      if(localStorage.getItem(window.shwesports.time)) {
          if(localStorage.getItem(window.shwesports.time) !== "0" ) {
              return true;
          }
      }
      return false;
  }
  



const sideDrawer = props => {
  let drawerClasses = ["side-drawer"];

  if (props.show) {
    drawerClasses = ["side-drawer", "open"];
  }




  return (
    
      <nav className={drawerClasses.join(" ")}>
              <div className="row drawerTop" >
                  <div className="grid-100" style={{marginRight:"16px" , marginLeft:"24px", paddingTop:"30px"}}>
                      <img src="/img/footer/_profile.png" style={{height: "50px", verticalAlign: "middle", marginRight:"16px" }} alt="User"/>
                      <b className="color-white">{localStorage.getItem('username')}</b>
                  </div>
                  
              </div>
              
              
              <ul>                     

                        <Link to="/channelsschedule"><li><img src="/img/channellist.png" alt={window.lang.more.channelsschedule} style={{height: "24px", verticalAlign: "middle", marginRight:"16px" }}  />{window.lang.more.channelsschedule}</li></Link>
                        <Link to="/channel1"><li><img src="/img/channel1.png" alt={window.lang.more.live_channel1} style={{height: "24px", verticalAlign: "middle", marginRight:"16px" }}  />{window.lang.more.live_channel1}</li></Link>
                        <Link to="/channel2"><li><img src="/img/channel2.png" alt={window.lang.more.live_channel2} style={{height: "24px", verticalAlign: "middle", marginRight:"16px" }}  />{window.lang.more.live_channel2}</li></Link>
                       {/* <Link to="/channel3"><li><img src="/img/channel3.png" alt={window.lang.more.live_channel3} />{window.lang.more.live_channel3}</li></Link>
                */}
                        <Link to="/more/league-table"  > <li><img src="/img/footer/cupoff.png" alt={window.lang.more.video} style={{height: "24px", verticalAlign: "middle", marginRight:"16px" }}   />{window.lang.title.leaderboard} </li></Link>
                        <Link to="/SKILLVIDEO"><li><img src="/img/videoicon.png" alt={window.lang.more.video} style={{height: "24px", verticalAlign: "middle", marginRight:"16px" }}   />{window.lang.more.video}</li></Link>
                        
                        
                        {/* <Link  to="/more/package/store"><li><img src="/img/list/packagestore.png" alt="Package Store" />Package Store </li></Link> */}
                       {/* <Link to="/help-center"><li><img src="/img/videoicon.png" alt={window.lang.more.help_center} style={{height: "24px", verticalAlign: "middle", marginRight:"16px" }}  />{window.lang.more.help_center}</li></Link> */}
                        <Link to="/more/announcements"><li><img src="/img/annoucenmetnicon.png" alt={window.lang.more.announcement} style={{height: "24px", verticalAlign: "middle", marginRight:"16px" }}  />{window.lang.more.announcement} </li></Link>
                        {(checklogin()) ?  <Link to="/more/feedback"><li><img src="/img/advice_icon.png" alt={window.lang.more.feedback} style={{height: "24px", verticalAlign: "middle", marginRight:"16px" }}  />{window.lang.more.feedback} </li></Link> : ""}

                  
                
                 
                  {(checklogin()) ? 
                    <div className="row mt-10" >
                      &nbsp; <a className="btn btn-success grid-80 pointer"  onClick={userLogout} style={{ fontSize: "15px" }}>{window.lang.form.logout}</a>
                  </div>
                  : ""}
                 
              </ul>


              

      </nav>
  );
};

let userLogout = () => {

      alert('Message', 'အကောင့်မှ ထွက်ရန် သေချာပါသလား။', [
          { text: 'မထွက်ပါ', onPress: () => console.log('cancel') },
          {
          text: 'ထွက်မည်',
          onPress: () => {
                  localStorage.setItem(window.shwesports.time, 0);
                  localStorage.setItem('username', "");
                  // this.setState({ step: "login" , message : "Successfully Log Out" }); 
                  // sideDrawer({ show : "close"})
                  window.location.reload();
              }
              
          },
      ])
  }

export default sideDrawer;


// https://codesandbox.io/s/b2dfd?file=/src/components/sidemenu/SideDrawer.js