/* eslint no-dupe-keys: 0 */
import React from 'react';


import {
  Link
} from 'react-router-dom';
import { ListView } from 'antd-mobile';
import axios from 'axios';
import ScrollMenu from 'react-horizontal-scrolling-menu';

require('./../../Shwesports.js')

let lang = localStorage.getItem('lang');


const NUM_ROWS = 3;
let pageIndex = 0;

// One item component
// selected prop will be passed
export const Menu = (list,selected,activeMenu) => list.map(el => {
  const { fullname,  url} = el;
  // console.log(selected)
  return (
    <MenuItem
      activeMenu={selected}
      text={fullname}
      key={url}
      data={el}
    />
  );
});


// All items component
// Important! add unique key
const MenuItem = ({ activeMenu, data, key, selected }) => {
  return (
    
      <div
      className="menu-item"
      >
        {data.id === 0 ? (
          <Link to={`/${data.url}`} className={(activeMenu === data.url) ? 'active' : '' } >{(lang === 'mm')? data.mmshortname : data.shortname }</Link>
        ) : (
          <a href={`/tip-and-odd/league/${data.url}`} className={(activeMenu === data.url) ? 'active' : '' } >{(lang === 'mm')? data.mmshortname : data.shortname }</a>
          // <Link to={`/tip-and-odd/league/${data.url}`} className={(activeMenu === data.url) ? 'active' : '' } >{data.name}</Link>
        )}
        {/* <Link to="/about" className={(activeMenu === text) ? 'active' : '' }  > */}
         
          {/* <span>{text}</span> */}

        {/* </Link> */}
      </div>
  );
};



const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    >{text}</div>
  );
};


const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });


function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}

// function title(id) {
//   { allmenu.map((item, i) => {
//     let statusClass = 'sign'
//     if (item.uri === id) statusClass += ' sign-complete'BUNDESLIGA
//     let boundClick = this.signOrder.bind(this, i)
//     return <p>
//       <span><span className={statusClass} onClick={() => this.signOrder(item.orderID)}>{item.uri}</span></span>
//       </p>
//     })
//   }
// }

class TipOddLeaguePage extends React.Component {
  constructor(props) {
    super(props);

    window.shwesports.footerMenu = "TIP&ODDS";

    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });

    this.state = {
      menu :[
        { id: 0, name: 'All',  shortname: 'All',  mmshortname: 'အားလုံး', url : 'tip-and-odd' },
        { id: 1, name: 'English Premier League',  shortname: 'EPL', mmshortname: 'ပရီမီးယားလိဂ်',  url : 'EPL' },
        { id: 2, name: 'Spain Laliga Premier', shortname: 'LALIGA', mmshortname: 'လာလီဂါ', url : 'SPL' },
        { id: 3, name: 'Itlaian Serie A', shortname: 'SERIE A', mmshortname: 'စီးရီးအေ', url : 'SERIEA' },
        { id: 4, name: 'German Bundesliga',shortname: 'BUNDESLIGA', mmshortname: 'ဘွန်ဒက်လီဂါ',  url : 'BUNDESLIGA' },
        { id: 5, name: 'France Ligue 1', shortname: 'LIGUE 1', mmshortname: 'လီဂူး၀မ်း',  url : 'LIGUE1' },
        { id: 6, name: 'UEFA Champion League', shortname: 'CHAMPION LEAGUE', mmshortname: 'ချန်ပီယံလိဂ်', url : 'CHAMPION' },
        { id: 7, name: 'UEFA Europa League', shortname: 'EUROPA LEAGUE', mmshortname: 'ဥရောပလိဂ်', url : 'EUROPA' },
        { id: 8, name: 'World Cup', shortname: 'WORLDCUP', mmshortname: 'ကမ္ဘာ့ဖလား', url : 'WC' },
        { id: 9, name: 'Euro', shortname: 'EURO', mmshortname: 'ယူရို', url : 'UEFA' },
        { id: 10, name: 'Others', shortname: 'OTHERS', mmshortname: 'အခြား', url : 'OTHERS' }
      ],
      selected: 'EPL',
      dataSource,
      isLoading: true,
      videoSlides:['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],

      virtualVideoData: {
        slides: ['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],
      },
      leagueMenu: 'EPL',
      league_title: 'EPL',
      data : [],
      assets: {}
    };

    if(props.match.params.name) {
      this.state.selected = props.match.params.name
    }
    
    //console.log(props.match.params.name)
  }
  onSelect = key => {
    this.setState({ selected: key });
    //console.log(this.state.selected)
    //this.fetchData()
    
  }

  hisUrl(url) {
    if(localStorage.getItem("route")) {
      let menu = JSON.parse(localStorage.getItem("route"));
      //console.log(menu.length+"-------------")
      if(menu.length === "0") {
        let data = ["/"];
        localStorage.setItem("route", JSON.stringify(data) );
      } 
      if(menu.length > 0) {
        //console.log(menu[menu.length - 1] + "-" + url )
          if(menu[menu.length - 2] === url ){
              console.log("first");
              menu.pop();
              //window.shwesports.route = JSON.stringify(menu);
              localStorage.setItem("route", JSON.stringify(menu) );
              //localStorage.setItem("route", JSON.stringify(menu) );
          }  
          else if(menu[menu.length-1] !== url ){
              menu.push(url)
              //window.shwesports.route = JSON.stringify(menu);
              localStorage.setItem("route", JSON.stringify(menu) );
              
          }
          //window.shwesports.preurl =  menu[menu.length-1];
          window.shwesports.preurl =  menu[menu.length-2];
          //console.log(window.shwesports.preurl)
      }
    }
  }              
  
  UNSAFE_componentWillMount() {
    window.shwesports.lang = localStorage.getItem("lang");
  }

  componentDidMount() {
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);
    this.fetchData();
    this.hisUrl('/tip-and-odd/league/'+this.state.selected)
    //console.log(this.state.league_name)
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(prevProps.match.params.name)
    // console.log(prevState.selected);
    // console.log(this.state.selected)
    //this.setState({ selected: prevProps.match.params.name });
    // if (this.state.selected !== prevState.selected) {
    //   // At this point, we're in the "commit" phase, so it's safe to load the new data.
    //   this.setState({ selected: prevProps.match.params.name });
    //   this.fetchData();
    // }
  }
  // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.dataSource !== this.props.dataSource) {
  //     this.setState({
  //       dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
  //     });
  //   }
  // }
  capitalize(str) {
    if(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    } else {
      return "";
    }
    
  }

  urlSearch(url) {
    let result;
    result = 0 ;
    //menu = this.state.menu;
    this.state.menu.filter(function(x) {
      //console.log(x.url == url)
      //return x.url === url;
      if(x.url === url) {
        return result = 1;
      }
      
     // dict[x.code] = x.name
     })

     return result;
    // return list.filter(
    //     function(list){ return list.url == url }
    // );
  }

  displayBlock( ) {
        let data = this.state.data
        //console.log(data.length)
        if(data.length > 0){
            let ui = []

                ui.push(<div className="tip-odd-row row"  key="0">
                        
                        <br/>
                            <table className="bg-light-gray tip-table box-shadow">
                                <thead>
                                  <tr>
                                    <td className="bdr-right bg-yellow-dark color-black">ပွဲချိန်</td>
                                    <td className="bdr-right bg-red color-black">ပွဲစဉ်</td>
                                    <td className="bdr-right bg-green-light color-black">ဘော်ဒီ</td>
                                    <td className="bdr-right bg-blue-light color-black">ဂိုးပေါင်း</td>
                                    <td className="bdr-right  bg-yellow color-black">Shwe Sports</td>
                                    <td className="bdr-right bg-orange-light color-black">Asian bookie</td>
                                    <td className="bdr-right bg-purple color-white">Predition Z</td>
                                    <td className="bdr-right bg-orange-light color-black">Goals88</td>
                                  </tr>
                                    
                                    </thead>
                                    {this.childUi2(0,data)}
                            </table>
                </div>)
            return ui
        }
    }

   childUi2(id,data) {

      let ui = [];
      // let data = this.state.data
      let leaguelength = data.length 
      let league_title = ""
          console.log('-----------------')
          //console.log(data)
          if(leaguelength > 0){
                // data.forEach(function(each){
                  for (let i = 0; i < leaguelength; i++) { 
                  let row = data[i];
                  ui.push(<tbody>
                                <tr>
                                  <td className="bdr-right bdr-top-bottom" colspan="8">
                                    {(this.urlSearch(this.state.leagueMenu))? <img src={'/img/league/'+this.state.leagueMenu+'.png'} alt={this.state.league_title} height="20px" /> : <img src={'/img/league/OTHERS.png'} alt={data[i].league_title} height="20px" />}
                                    &nbsp;&nbsp; {this.state.league_title}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="bdr-right">{row.date} <hr /> {row.time}</td>
                                  <td className="bdr-right">{row.home} vs <br /> {row.away} <br /> {(row.h_a === 'H')? <b className="color-dark-blue tip-odd-handicap"><span className="color-white tipo-box">{row.h_a} </span> </b> : <b className="color-dark-blue tip-odd-handicap"> <span className="color-white tipo-box"> {row.h_a} </span> </b> }
                                  </td>
                                  <td className="bdr-right">{(row.h_a === 'H')? <b className="color-dark-blue tip-odd-handicap">{row.handicap} </b> : <b className="color-dark-blue tip-odd-handicap"> {row.handicap}   </b> }</td>
                                  <td className="bdr-right">{(row.h_a === 'H')? <b className="color-dark-blue tip-odd-handicap">{row.o_u}</b> : <b className="color-dark-blue tip-odd-handicap"> {row.o_u}  </b> } </td>
                                  <td className="bdr-right">
                                    {row.mmbookie} &nbsp;<hr /> 
                                    { this.capitalize(row.mmbookie_ou) === "Under" ?
                                          <span className="color-red">{this.capitalize(row.mmbookie_ou)}&nbsp;</span>
                                          :
                                          <span className="color-black">{this.capitalize(row.mmbookie_ou)}&nbsp;</span>
                                    }
                                  </td>
                                  <td className="bdr-right">{row.asianbookie} &nbsp; <hr /> 
                                    { this.capitalize(row.asianbookie_ou) === "Under" ?
                                          <span className="color-red">{this.capitalize(row.asianbookie_ou)}&nbsp;</span>
                                          :
                                          <span className="color-black">{this.capitalize(row.asianbookie_ou)}&nbsp;</span>
                                    }
                                  </td>
                                  <td className="bdr-right">{row.soccerhot} &nbsp;<hr /> 
                                    { this.capitalize(row.soccerhot_ou) === "Under" ?
                                          <span className="color-red">{this.capitalize(row.soccerhot_ou)}&nbsp;</span>
                                          :
                                          <span className="color-black">{this.capitalize(row.soccerhot_ou)}&nbsp;</span>
                                    }
                                  </td>
                                  <td className="bdr-right">
                                      {row.goals88}&nbsp; <hr />
                                        { this.capitalize(row.goals88_ou) === "Under" ?
                                            <span className="color-red">{this.capitalize(row.goals88_ou)}&nbsp;</span>
                                            :
                                            <span className="color-black">{this.capitalize(row.goals88_ou)}&nbsp;</span>
                                      }
                                    </td>
                                </tr>
                         
                           

                    </tbody>);
                  league_title = this.state.league_title
              }

          } else {
            ui.push(<div className="tip-odd-row row"  key='0'>
                          <div className="row text-center mb-10">
                              <img src={'/img/league/'+this.state.selected+'.png'} alt={this.state.league_title}/>
                              <b className="color-dark-blue tip-odd-title">{this.state.league_title}</b>
                          </div>
                    </div>);
          }
      return ui;
    }

  fetchData() {
      let dataurl = window.shwesports.apiUrl+'/tipandodd/league/'+this.state.selected;

      axios.get(dataurl, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "no-cors", // no-cors, cors, *same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        //credentials: "same-origin", // include, *same-origin, omit
        crossDomain: true,
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            // "Content-Type": "application/x-www-form-urlencoded",
        },
        redirect: "follow", // manual, *follow, error
        referrer: "no-referrer", // no-referrer, *client
        //body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      .then(resp => {
      //   this.data = resp.data;
      //   console.log("ok2");
      //     console.log(this.data);
          //console.log(this.pageIndex);
          // this.pageIndex++;
          this.setState({
              league_title : resp.data.league_title,
              data: resp.data.data,
              menu: resp.data.menu,
              assets: resp.data.assets,
              leagueMenu: resp.data.uri,
              isLoading: false 
          });
          // console.log(resp.data);
      })
      .catch(function(error) {
        // console.log(error.tostring());
      });   
      
  }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      return;
    }
    console.log('reach end', event);
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.rData = { ...this.rData, ...genData(++pageIndex) };
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(this.rData),
        isLoading: false,
      });
    }, 1000);
  }

  render() {
    
    let { selected } = this.state;
    let leagueMenu = this.state.leagueMenu;
    // Create menu from items
    const menu = Menu(this.state.menu, selected, leagueMenu);

    
    return (
      <div className="page">
        <div className="page-content">
            <ScrollMenu
              data={menu}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              selected={selected}
              onSelect={this.onSelect}
              alignCenter={false}
              wheel={true}
              inertiaScrolling={true}
            />

            { (this.state.isLoading) ?
            (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> ) : this.displayBlock() }
            <div className="ant-back-footer">
                <div className="ant-back-top">
                    <div className="ant-back-top-content">
                        <Link to={window.shwesports.preurl} className="color-green" >
                    { (JSON.parse(localStorage.getItem("route")) !== null) 
                    ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                    </Link> 
                    </div>
                </div>
            </div>
          </div>
      </div>
    );
  }
}

export default TipOddLeaguePage;