let rand_one = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
let rand_two = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
let salt = "r27bHi9jwClte3W8MypKXXqpMCvIRZErVOttKsz9SNf14xKwtK6J1rjWE9Zc";
// hlvixpdbur27bHi9jwClte3W8MypKXXqpMCvIRZErVOttKsz9SNf14xKwtK6J1rjWE9Zcdzivymys
let token = window.btoa(encodeURIComponent( rand_one + salt  + rand_two ));
let rand_compound = window.btoa(encodeURIComponent( rand_one + "!" + rand_two ));
//let token = encodeURIComponent(escape(window.atob( rand_one + salt  + rand_two )));
// atob(rand_one + salt  + rand_two);

window.BPdata = {
    apiUrlCMS  : 'https://dep.shwesports.com/api/v1/cms',
    // apiUrlCMS  : 'http://shwesports-full.local/api/v1/cms',
    salt : salt,
    rand_one : rand_one,
    rand_two : rand_two,
    rand_compound : rand_compound,
    token : token,
}

window.shwesports =
    {
        time : "time",
        data :
            {
                path: '/',
                component: 'Home',
            },
        footerMenu : "News",
        baseUrl : 'https://shwesports.com/',
        mobilebaseUrl : 'http://shwesports.com',
        apiUrl  : 'https://dashboard.shwesports.com/api/ver1',
        dashboardUrl : 'https://dashboard.shwesports.com',
        apiAssetUrl : 'https://dashboard.shwesports.com/assets',
        popup   : true,
        leagues : [{"id":"2882","country":"Asia","name":"World Cup - Qualification - Third Stage"},{"id":"3031","country":"England","name":"England: Fa Cup"},{"id":"3037","country":"England","name":"England: Premier League"},{"id":"3038","country":"England","name":"England: Championship"},{"id":"3368","country":"World","name":"World: Friendly International"},{"id":"3232","country":"Spain","name":"Spain: Laliga"},{"id":"3230","country":"Spain","name":"Spain: Copa Del Rey"},{"id":"2840","country":"Europe","name":"Europe: Europa League - Play Offs"},{"id":"2838","country":"Europe","name":"Europe: Champions League"},{"id":"3364","country":"Europe","name":"Europe: Euro - Qualification"},{"id":"3054","country":"France","name":"France: Ligue 1"},{"id":"3050","country":"France","name":"France: Ligue 1 - Relegation"},{"id":"3102","country":"Italy","name":"Itlaian Serie A"},{"id":"3062","country":"German","name":"German Bundesliga"},{"id":"2889","country":"Europe","name":"World: World Cup"},{"id":"3631","country":"World","name":"World: World Cup U20"},{"id":"3729","country":"World","name":"World: World Cup Women"},{"id":"2872","country":"southamerica","name":"South America: Copa Am\u00e9rica"}],
        route : "[\"/\"]",
        preurl: "/",
        liveurl: "",
        mainmenu :[
            { id: 1, name: 'English Premier League',  shortname: 'EPL', mmshortname: 'ပရီးမီးယားလိဂ်',  url : 'premier-league' },
            { id: 2, name: 'Spain Laliga Premier', shortname: 'LALIGA', mmshortname: 'လာလီဂါ', url : 'laliga' },
            { id: 3, name: 'Itlaian Serie A', shortname: 'SERIE A', mmshortname: 'စီးရီးအေ', url : 'serie-a' },
            { id: 4, name: 'German Bundesliga',shortname: 'BUNDESLIGA', mmshortname: 'ဘွန်ဒက်လီဂါ',  url : 'bundesliga' },
            { id: 5, name: 'France Ligue 1', shortname: 'LIGUE 1', mmshortname: 'လီဂူး၀မ်း',  url : 'ligue-1' },
            { id: 6, name: 'UEFA Champion League', shortname: 'CHAMPION LEAGUE', mmshortname: 'ချန်ပီယံလိဂ်', url : 'champion-league' },
            { id: 7, name: 'UEFA Europa League', shortname: 'EUROPA LEAGUE', mmshortname: 'ယူရိုပါလိဂ်', url : 'europa-league' },
            { id: 8, name: 'World Cup', shortname: 'WORLDCUP', mmshortname: 'ကမ္ဘာ့ဖလား', url : 'worldcup' },
            { id: 9, name: 'Euro', shortname: 'EURO', mmshortname: 'ယူရို', url : 'euro' },
            { id: 10, name: 'Others', shortname: 'OTHERS', mmshortname: 'အခြား', url : 'others' }
          ],
        lang : "mm"
    }
;


window.enlang =
{
    more : {
        my_account          : 'My Account',
        live_channel        : 'Sport Live Channel',
        live_channel1       : 'Live Channel 1',
        live_channel2       : 'Live Channel 2',
        live_channel3       : 'Live Channel 3',
        channelsschedule    : 'Channels Schedule',
        gem_store           : 'Gem Store',
        term_and_conditions : 'Terms & Conditions',
        announcement        : 'Announcement',
        feedback            : 'Feedback',
        video               : 'Skill Videos',
        help_center         : 'Help Center',
        version_check       : 'Version Update Check',
    },
    title : {
        latest_euro_news      : 'LATEST EURO NEWS',
        latest_news           : 'LATEST NEWS',
        latest_highlights     : 'LATEST HIGHLIGHTS',
        highlights      : 'Highlights',
        league_contest  : 'League Contest',
        bill_contest    : 'Bill Contest',
        tipsters        : 'Tipsters',
        tip             : 'Tips',
        league_history  : 'League History',
        bill_history    : 'Bill History',
        transaction     : 'Transaction',
        contest         : 'Contest',
        leaderboard     : 'Leaderboard',
        history         : 'History',
        euro_news       : 'Euro News',
        euro_standing   : 'Euro Standing',
        euro_team       : 'Euro Team',
        payment         : 'Payment',
        order           : 'Order',
        forum           : 'Forum',
        inbox           : 'Inbox',
        data_history_win_loss   : 'Data’s history of Win/Lose',
        football_betting_change : 'Football Betting Change',
        odds_and_tips           : 'Odds and Tips',
    },
    form : {
        user            : 'User',
        id              : 'ID',
        gems            : 'Gems',
        point           : 'Point',
        hightest_point  : 'Highest Point',
        rank            : 'Rank',
        join_date       : 'Join Date',
        language        : 'Language',
        login           : 'User Login',
        register        : 'User Register',
        password_reset  : 'Password Reset',
        verify_code     : 'Verify Code',
        logout          : 'Logout',
        title           : 'Title',
        description     : 'Description',
        name            : 'Name',
        phone           : 'Phone',
        password        : 'Password',
        forgot_password : 'Forgot email?',
        submit          : 'Submit',
        cancel          : 'Cancel',
        new_post        : 'New Post',
        comment         : 'Comment',
        que             : {
            confirm     : 'Confirm Box',
            delete      : 'Are you sure to delete?'
        }
    },
    packages : {
        oneday          : 'One Day',
        oneweek         : 'One Week',
        onemonth        : 'One Month',
        buy             : 'Buy',
    },

    footer : [
        {icon: '/img/footer/newson.png',icon_off: '/img/footer/newsoff.png',text: 'SPORT', check: 'NEWS', url : "/news"},

            //   {icon: '/img/newspaper.jpg',icon_off: '/img/newspaperoff.jpg',text: 'သတင်း', check: 'NEWS', url : "/"},
            //  {icon: '/img/newspaper.jpg',icon_off: '/img/newspaperoff.jpg',text: 'ယူရို', check: 'Euro', url : "/"},
            // soccer-balloff.jpg  moreoff.png
                {icon: '/img/footer/matchon.png',icon_off: '/img/footer/matchoff.png',text: 'LIVE', check: 'LIVE SCORES', url : "/live-score"},
                // {icon: '/img/televisionon.jpg',icon_off: '/img/televisionoff.jpg',text: 'MORE', check: 'MORE', url : "/more" },
                {icon: '/img/footer/channelon.png',icon_off: '/img/footer/channeloff.png',text: 'CHANNEL', check: 'CHANNEL', url : "/channel" },
            {icon: '/img/footer/tipon.png',icon_off: '/img/footer/tipoff.png',text: 'OTHER', check: 'MORE', url : "/more" },

                {icon: '/img/footer/cupon.png',icon_off: '/img/footer/cupoff.png',text: 'CONTEST', check: 'CONTEST', url : "/contest"}
                // {icon: '/img/league_contest.png',icon_off: '/img/league_contest.png',text: 'CONTEST', check: 'CONTEST', url : "/contest"}

              ],
    messages : {
        que_submit      : "Are you sure want to submit?",
        invalid_phone   : "Invalid phone number",
        welcome_message : "Welcome to Shwesports",
        sending         : "Sending",
        somethingwrong  : "Something was wrong",
    },
};

window.mmlang =
{
    more : {
        my_account          : 'ကိုယ်ပိုင်အကောင့်',
        live_channel        : 'Sport တိုက်ရိုက်ချန်နယ်',
        live_channel1       : 'တိုက်ရိုက်ချန်နယ် ၁',
        live_channel2       : 'တိုက်ရိုက်ချန်နယ် ၂',
        live_channel3       : 'တိုက်ရိုက်ချန်နယ် ၃',
        channelsschedule    : 'ချန်နယ်အချိန်ဇယား',
        gem_store           : 'Gem ဆိုင်',
        term_and_conditions : 'စည်းကမ်းချက်များ',
        announcement        : 'အသိပေးကြေညာချက်',
        feedback            : 'အကြံပေးခြင်း ',
        video               : 'ဗွီဒီယိုများ',
        help_center         : 'အကူအညီ',
        version_check       : 'Version Update Check',
    },
    title : {
        latest_euro_news        : 'နောက်ဆုံးရ ယူရိုသတင်းများ',
        latest_news             : 'နောက်ဆုံးရသတင်းများ',
        latest_highlights       : 'နောက်ဆုံးရဟိုက်လိုက်များ',
        highlights      : 'ဟိုက်လိုက်များ',
        league_contest  : 'League ပြိုင်ပွဲ',
        bill_contest    : 'ဖုန်းဘေလ်ပြိုင်ပွဲ',
        transaction     : 'ငွေသွင်းငွေထုတ်',
        tipsters        : 'ခန့်မှန်းပေးသူများ',
        tip             : 'ခန့်မှန်းချက်',
        league_history  : 'လိဂ်ပြိုင်ပွဲသမိုင်း',
        bill_history    : 'ဖုန်းဘေလ်ပြိုင်ပွဲသမိုင်း',
        contest         : 'ပြိုင်ပွဲ',
        leaderboard     : 'ရပ်တည်မှုဇယား',
        history         : 'လိဂ်ပြိုင်ပွဲသမိုင်း',
        euro_news       : 'ယူရို သတင်း',
        euro_standing   : 'ယူရို ရပ်တည်မှုဇယား',
        euro_team       : 'ယူရို အသင်းများ',
        payment         : 'ငွေပေးချေမှု',
        order           : 'မှာယူခြင်း',
        forum           : 'ဖိုရမ်',
        inbox           : 'စာတိုက်ပုံး',
        data_history_win_loss   : 'ကြေး နိုင်၊ရှုံး မှတ်တမ်း',
        football_betting_change : 'ကြေးပြောင်း',
        odds_and_tips           : 'ပေါက်ကြေး နှင့် ရွေးချယ်မူ',
    },
    form : {
        user            : 'အသုံးပြုသူ နာမည်',
        id              : 'မှတ်ပုံတင်အမှတ်',
        gems            : 'Gems',
        point           : 'ရမှတ်',
        hightest_point  : 'အမြင့်ဆုံးရမှတ်',
        rank            : 'အဆင့်',
        join_date       : 'အသင်းဝင်ရက်စွဲ',
        language        : 'ဘာသာစကား',
        login           : 'အကောင့်၀င်ရန်',
        register        : 'အကောင့်မှတ်ပုံတင်ရန်',
        logout          : 'ထွက်မည်',
        password_reset  : 'စကားဝှက်ကို ပြန်လည်သတ်မှတ်မည်',
        verify_code     : 'ကုဒ်အတည်ပြုပါ',
        title           : 'ခေါင်းစဉ်',
        description     : 'ဖော်ပြချက်',
        name            : 'နာမည်',
        phone           : 'ဖုန်းနံပါတ်',
        password        : 'စကားဝှက်',
        forgot_password : 'စကားဝှက် မေ့နေပါသလား။',
        submit          : 'လုပ်ဆောင်မည်',
        cancel          : 'မလုပ်ဆောင်ပါ',
        new_post        : 'အသစ်တင်မည်',
        comment         : 'မှတ်ချက်',
        que             : {
            confirm     : 'အတည်ပြုပါ',
            delete      : 'သင်ဖျက်ဖို့သေချာလား။'
        }
    },
    packages : {
        oneday          : 'တစ်ရက်စာ',
        oneweek         : 'တစ်ပတ်စာ',
        onemonth        : 'တစ်လစာ',
        buy             : '၀ယ်မယ်',
    },
    footer : [
        {icon: '/img/footer/newson.png',icon_off: '/img/footer/newsoff.png',text: 'အားကစား', check: 'NEWS', url : "/news"},
            
            //   {icon: '/img/newspaper.jpg',icon_off: '/img/newspaperoff.jpg',text: 'သတင်း', check: 'NEWS', url : "/"},
            //  {icon: '/img/newspaper.jpg',icon_off: '/img/newspaperoff.jpg',text: 'ယူရို', check: 'Euro', url : "/"},
            // soccer-balloff.jpg  moreoff.png
            {icon: '/img/footer/matchon.png',icon_off: '/img/footer/matchoff.png',text: 'ပွဲများ', check: 'LIVE SCORES', url : "/live-score"},
            // {icon: '/img/televisionon.jpg',icon_off: '/img/televisionoff.jpg',text: 'အခြား', check: 'MORE', url : "/more" },
            {icon: '/img/footer/channelon.png',icon_off: '/img/footer/channeloff.png',text: 'ရုပ်သံ', check: 'CHANNEL', url : "/channel" },
            {icon: '/img/footer/tipon.png',icon_off: '/img/footer/tipoff.png',text: 'အခြား', check: 'MORE', url : "/more" },
            {icon: '/img/footer/cupon.png',icon_off: '/img/footer/cupoff.png',text: 'ပြိုင်ပွဲ', check: 'CONTEST', url : "/contest"}
            // {icon: '/img/league_contest.png',icon_off: '/img/league_contest.png',text: 'ပြိုင်ပွဲ', check: 'CONTEST', url : "/contest"}
        ],
    // footer : [{icon: '/img/newfooticongray.png',icon_off: '/img/newfooticongreen.png',text: 'သတင်း', check: 'NEWS', url : "/"},
    //         //   {icon: '/img/newspaper.jpg',icon_off: '/img/newspaperoff.jpg',text: 'သတင်း', check: 'NEWS', url : "/"},
    //         //  {icon: '/img/newspaper.jpg',icon_off: '/img/newspaperoff.jpg',text: 'ယူရို', check: 'Euro', url : "/"},
    //         // soccer-balloff.jpg  moreoff.png
    //         {icon: '/img/newfooticongreen.png',icon_off: '/img/newfooticongreen.png',text: 'ပွဲများ', check: 'LIVE SCORES', url : "/live-score"},
    //         // {icon: '/img/televisionon.jpg',icon_off: '/img/televisionoff.jpg',text: 'အခြား', check: 'MORE', url : "/more" },
    //         {icon: '/img/televisionon.jpg',icon_off: '/img/televisionoff.jpg',text: 'ရုပ်သံ', check: 'MORE', url : "/more" },
    //         {icon: '/img/odds.png',icon_off: '/img/oddsoff.png',text: 'ခန့်မှန်းချက်', check: 'TIP&ODDS', url : "/tip-and-odd" },
    //         {icon: '/img/more.png',icon_off: '/img/moreoff.png',text: 'ပြိုင်ပွဲ', check: 'CONTEST', url : "/contest"}
    //         // {icon: '/img/league_contest.png',icon_off: '/img/league_contest.png',text: 'ပြိုင်ပွဲ', check: 'CONTEST', url : "/contest"}
    //     ],


    messages : {
        que_submit      : "ပေးပို့ချင်တာသေချာပါသလား",
        invalid_phone   : "ဖုန်းနံပါတ် မှားယွင်းနေပါသည်။",
        welcome_message : "Shwesports မှကြိုဆိုပါသည်",
        sending         : "လုပ်ဆောင်နေပါသည်",
        somethingwrong  : "အချက်အလက်များ မှားယွင်းနေပါသည်။",
        your_comment    : 'သင့်ရဲ့မှတ်ချက်',
    },
};


window.lang = window.mmlang;
//window.lang = window.enlang;