/* eslint no-dupe-keys: 0 */
import React from 'react';


import {
  Link
} from 'react-router-dom';
import { ListView } from 'antd-mobile';
import axios from 'axios';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import Modal from 'react-responsive-modal';

require('./../../Shwesports.js')

//let lang = localStorage.getItem('lang');


const NUM_ROWS = 3;
let pageIndex = 0;

// One item component
// selected prop will be passed
export const Menu = (list,selected,activeMenu) => list.map(el => {
  const { fullname, url} = el;
  // console.log(selected)
  return (
    <MenuItem
      activeMenu={selected}
      text={fullname}
      key={url}
      data={el}
    />
  );
});

// All items component
// Important! add unique key
const MenuItem = ({ activeMenu, data, key, selected }) => {
  return (
    
      <div
      className="menu-item"
      >
        {data.id === 0 ? (
          <Link to={`/${data.url}`} className={(activeMenu === data.url) ? 'active' : '' } >{(window.shwesports.lang === 'mm')? data.mmshortname : data.shortname }</Link>
        ) : (
          <Link to={`/football-betting-historytip-and-odd/league/${data.url}`} className={(activeMenu === data.url) ? 'active' : '' } >{(window.shwesports.lang === 'mm')? data.mmshortname : data.shortname }</Link>
          // <Link to={`/football-betting-historytip-and-odd/league/${data.url}`} className={(activeMenu === data.url) ? 'active' : '' } >{data.name}</Link>
        )}
        {/* <Link to="/about" className={(activeMenu === text) ? 'active' : '' }  > */}
         
          {/* <span>{text}</span> */}

        {/* </Link> */}
      </div>
  );
};



const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    >{text}</div>
  );
};


const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });


function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}

class FootballBettingHistoryDetail extends React.Component {
  constructor(props) {
    super(props);

    window.shwesports.footerMenu = "TIP&ODDS";
    
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });

    this.state = {
      menu :[
        { id: 0, name: 'All',  shortname: 'All',  mmshortname: 'အားလုံး', url : 'football-betting-historytip-and-odd' },
        { id: 1, name: 'English Premier League',  shortname: 'EPL', mmshortname: 'ပရီမီးယားလိဂ်',  url : 'EPL' },
        { id: 2, name: 'Spain Laliga Premier', shortname: 'LALIGA', mmshortname: 'လာလီဂါ', url : 'SPL' },
        { id: 3, name: 'Itlaian Serie A', shortname: 'SERIE A', mmshortname: 'စီးရီးအေ', url : 'SERIEA' },
        { id: 4, name: 'German Bundesliga',shortname: 'BUNDESLIGA', mmshortname: 'ဘွန်ဒက်လီဂါ',  url : 'BUNDESLIGA' },
        { id: 5, name: 'France Ligue 1', shortname: 'LIGUE 1', mmshortname: 'လီဂူး၀မ်း',  url : 'LIGUE1' },
        { id: 6, name: 'UEFA Champion League', shortname: 'CHAMPION LEAGUE', mmshortname: 'ချန်ပီယံလိဂ်', url : 'CHAMPION' },
        { id: 7, name: 'UEFA Europa League', shortname: 'EUROPA LEAGUE', mmshortname: 'ဥရောပလိဂ်', url : 'EUROPA' },
        { id: 8, name: 'World Cup', shortname: 'WORLDCUP', mmshortname: 'ကမ္ဘာ့ဖလား', url : 'WC' },
        { id: 9, name: 'Euro', shortname: 'EURO', mmshortname: 'ယူရို', url : 'UEFA' },
        { id: 10, name: 'Others', shortname: 'OTHERS', mmshortname: 'အခြား', url : 'OTHERS' }
      ],
      
      selected: 'inital',
      dataSource,
      isLoading: false,
      videoSlides:['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],

      virtualVideoData: {
        slides: ['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],
      },
      leagueMenu: 'football-betting-historytip-and-odd',
      league_name: 'football-betting-historytip-and-odd',
      data : [],
      advertise : [],
      open: false,
    };

    if(props.match.params.id) {
      this.state.selected = props.match.params.id
    }
    
    //console.log(props.match.params.name)
  }
  onSelect = key => {
    this.setState({ selected: key });
    //console.log(this.state.selected)
    //this.fetchData()
    
  }
  
  hisUrl(url) {
    if(localStorage.getItem("route")) {
      let menu = JSON.parse(localStorage.getItem("route"));
      //console.log(menu.length+"-------------")
      if(menu.length === "0") {
        let data = ["/"];
        localStorage.setItem("route", JSON.stringify(data) );
      } 
      if(menu.length > 0) {
        //console.log(menu[menu.length - 1] + "-" + url )
          if(menu[menu.length - 2] === url ){
              console.log("first");
              menu.pop();
              //window.shwesports.route = JSON.stringify(menu);
              localStorage.setItem("route", JSON.stringify(menu) );
              //localStorage.setItem("route", JSON.stringify(menu) );
          }  
          else if(menu[menu.length-1] !== url ){
              menu.push(url)
              //window.shwesports.route = JSON.stringify(menu);
              localStorage.setItem("route", JSON.stringify(menu) );
              
          }
          //window.shwesports.preurl =  menu[menu.length-1];
          window.shwesports.preurl =  menu[menu.length-2];
          //console.log(window.shwesports.preurl)
      }
    }
  }

  UNSAFE_componentWillMount() {
    window.shwesports.lang = localStorage.getItem("lang");
  }

  onOpenModal = () => {
    this.setState({ open: true,  advopen : true });
  };
 
  onCloseModal = () => {
    this.setState({ open: false , advopen : false });
    

    //localStorage.setItem('popup', false);
    // window.shwesports.popup = false
    // console.log(window.shwesports.popup)
  };

  componentDidMount() {
    
    if(localStorage.getItem('latest_tipandodd')) {
      if(JSON.parse(localStorage.getItem("latest_tipandodd")) != null) {
        let latest_tipandodd = JSON.parse(localStorage.getItem("latest_tipandodd"));
        this.setState({data: latest_tipandodd})
        
      }
    } 
    if(localStorage.getItem('tipandodd_advertise')) {
      if(JSON.parse(localStorage.getItem("tipandodd_advertise")) != null) {
        let tipandodd_advertise = JSON.parse(localStorage.getItem("tipandodd_advertise"));
        this.setState({advertise: tipandodd_advertise})
      }
    } 

 
    if(localStorage.getItem(window.shwesports.time)) {
          if(localStorage.getItem(window.shwesports.time) !== "0" ) {
              document.getElementById( 'toggle-home' ).style.display = 'none';
          }
    }
    
    // document.getElementById( 'toggle-home' ).style.display = 'none';
    
    this.fetchData();
    this.hisUrl('/football-betting-historytip-and-odd')
    //this.state.isLoading = true;
    clearTimeout(window.shwesports.liveurl);
    //console.log(this.state.league_name)
  }

  componentDidUpdate(prevProps, prevState) {
    //this.setState({ selected: prevProps.match.params.name });
    // if (this.state.selected !== prevState.selected) {
    //   // At this point, we're in the "commit" phase, so it's safe to load the new data.
    //   this.setState({ selected: prevProps.match.params.name });
    //   this.fetchData();
    // }
  }
    // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
    // componentWillReceiveProps(nextProps) {
    //   if (nextProps.dataSource !== this.props.dataSource) {
    //     this.setState({
    //       dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
    //     });
    //   }
    // }
  capitalize(str) {
    if(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    } else {
      return "";
    }
  }

  urlSearch(url) {
    this.state.menu.forEach(function(x) {
      //console.log(x.url == url)
      return x.url === url
     // dict[x.code] = x.name
     })

     return false;
    // return list.filter(
    //     function(list){ return list.url == url }
    // );
  }

  displayBlock( ) {
        let data = this.state.data
        //console.log(data.length)
        if(data.length > 0){
            let ui = []

                ui.push(<div className="tip-odd-row row"  key="0">
                        
                        <br/>
                            <table className="bg-light-gray tip-table box-shadow">
                                <thead>
                                    <tr >
                                      <td className="bdr-right bg-green-light color-black" >Date</td>
                                      <td className="bdr-right bg-blue-light color-black">League</td>
                                      <td className="bdr-right bg-blue-light color-black">အိမ်ကွင်း</td>
                                      <td className="bdr-right bg-blue-light color-black">ပွဲအဖြေ</td>
                                      <td className="bdr-right bg-blue-light color-black">အဝေးကွင်း</td>
                                      <td className="bdr-right bg-blue-light color-black">Handicap</td>
                                      <td className="bdr-right bg-blue-light color-black">O/U</td>
                                      <td className="bdr-right bg-blue-light color-black">H Result</td>
                                      <td className="bdr-right bg-blue-light color-black">O/U Result</td>
                                    </tr>
                                  </thead>
                                  {this.childUi2(0,data)}
                            </table>
                </div>)
            return ui
        }
    }

   childUi2(id,data) {
    let ui = [];
    // let data = data
    let league_title = ""
    let j = 0;
    let adcount = 0;
    //this.setState.isLoading = false ;
    //this.setState({ isLoading: false });
        //console.log(data)
       // console.log(this.state.data)
        if(this.state.data.length > 0){
          
          // data.forEach(function(each){
            for (let i = 0; i < this.state.data.length; i++) { 
              
              if(data[i].data.length > 0 ) {
                //this.setState.isLoading = false ;
                
                for (let y = 0; y < data[i].data.length; y++) { 
                    let row = data[i].data[y];
                    //console.log(row)
                    // console.log('======================')
                    
                    ui.push(<tbody>
                            { (league_title !== data[i].league_title) ?
                              <tr>
                                <td className="bdr-right bdr-top-bottom bg-light-gray" colspan="10">
                                  <img src={'/img/league/'+data[i].icon} alt={data[i].league_title} height="20px"/> 
                                  &nbsp;&nbsp;  { data[i].league_title  }
                                </td>
                              </tr>
                              : "" }

                                <tr className="bdr-right bdr-top-bottom">
                                  <td className="bdr-right"><a href={'/football-betting-history/league/'+row.id+'/'+row.name} >{row.name}</a></td>
                                  <td className="bdr-right"> <small className="font-small">{row.league.tnomenu_name}</small> </td>
                                  <td className="bdr-right"> {row.league.home_id}</td>
                                  <td className="bdr-right"> {row.league.home_result } - {row.league.away_result }</td>
                                  <td className="bdr-right"> {row.league.away_id }</td>
                                  <td className="bdr-right"> {row.league.handicap }</td>
                                  <td className="bdr-right"> {row.league.o_u }</td>
                                  <td className="bdr-right"> {row.league.h_result }</td>
                                  <td className="bdr-right"> {row.league.o_u_result }</td>
                                </tr>
                            {   
                               ((this.state.advertise.length>adcount && league_title !== data[i].league_title) ?
                               <tr>
                                  <td className="bdr-right bdr-top-bottom bg-light-gray" colspan="12">
                                    <a href={this.state.advertise[adcount].advertise_goto} target="_blank" rel="noopener noreferrer">
                                      <img src={window.shwesports.apiAssetUrl+"/images/"+this.state.advertise[adcount].advertise_image} style={{ width: '100%',paddingTop: '20px' }} alt={this.state.advertise[adcount].advertise_title}/>
                                      </a>
                                  </td>
                                </tr> : "") 
                            }

                           

                    </tbody>);
                    if(league_title !== data[i].league_title){
                      adcount++;
                    }
                    
                    league_title = data[i].league_title
                    j = 1;
                  }
              }
              
          }
        }
    return ui;
  }


  fetchData() {
        
        // let dataurl = url;
        let dataurl = window.BPdata.apiUrlCMS+'/sport/betting-history/league/'+this.state.selected+'/history';

        axios({
            method: 'get',
            mode: "no-cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: "same-origin", // include, *same-origin, omit
            crossDomain: true,
            url: dataurl,
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token" : window.BPdata.token,
              "BP-Agent"        : window.BPdata.rand_compound,
              "BP-Time"         : localStorage.getItem(window.BPdata.time)
              // "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(resp => {
            // console.log(resp.data)
            if(resp.data.data) {

                this.setState({
                    // twogame: resp.data.data.data,
                    // event : resp.data.event,
                    // setting : resp.data.setting,
                    // isLoading: false,
                    // isEvent : resp.data.is_event,

                    data: resp.data.data,
                    advertise: resp.data.advertise,
                    menu: resp.data.menu,
                    leagueMenu: resp.data.uri,
                });

            } else {

                this.setState({
                    isLoading: false,
                    isEvent : false,
                });

            }
            


        })
        .catch(function(error) {
          //console.log(error.tostring());
        });   


        
  }

 
  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      return;
    }
    console.log('reach end', event);
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.rData = { ...this.rData, ...genData(++pageIndex) };
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(this.rData),
        isLoading: false,
      });
    }, 1000);
  }

  
  

  render() {
    
    let { selected } = this.state;
    let leagueMenu = this.state.leagueMenu;
    // Create menu from items
    const menu = Menu(this.state.menu, selected, leagueMenu);

    
    return (
      <div className="page">
        <div className="page-content">
            <ScrollMenu
              data={menu}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              selected={selected}
              onSelect={this.onSelect}
              alignCenter={false}
              wheel={true}
              inertiaScrolling={true}
            />

          { (this.state.isLoading) ?
            (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> ) : "" }

            <br />
            <div className="row text-left">
                <ul className="secondsubmenu">
                    <li>
                        <Link to="/tip-and-odd" className="btn-success btn" >
                            {window.lang.title.odds_and_tips}
                        </Link> 
                    </li>
                    <li>
                        <Link to="/football-betting-change" className="btn-success btn" >
                            {window.lang.title.football_betting_change}
                        </Link> 
                    </li>
                    <li>
                        <Link to="/football-betting-history" className="btn-success btn  color-gold" >
                            {window.lang.title.data_history_win_loss}
                        </Link> 
                    </li>

                </ul>
            </div>

            {this.displayBlock() }

            <br />
            <p className="text-center">မှတ်ချက် - ဖုန်းအလျားလိုက်ထားပြီးမြင်ကွင်းကိုကြည့်ရူပါက ပိုမိုအဆင်ပြေပါသည်။</p> <br />

            {
                (this.state.advertise.length > 0) ? <div className="row">
                <div className="grid">
                <a href={this.state.advertise[0].advertise_goto} target="_blank" rel="noopener noreferrer">
                            <img src={window.shwesports.apiAssetUrl+"/images/"+this.state.advertise[0].advertise_image} style={{ width: '100%' }} alt={this.state.advertise[0].advertise_title}/>
                            </a><br/><br/>
                </div>
                </div>: ""
            }
            
            <div className="ant-back-footer">
                <div className="ant-back-top">
                    <div className="ant-back-top-content">
                        <Link to={window.shwesports.preurl} className="color-green" >
                    { (JSON.parse(localStorage.getItem("route")) !== null) 
                    ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                    </Link> 
                    </div>
                </div>
            </div>
          </div>
      </div>
    );
  }
}

export default FootballBettingHistoryDetail;