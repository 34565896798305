import React, { Component } from 'react';
import {
  Route,
  Switch,
  Redirect,
  Link
} from 'react-router-dom';
// import { FacebookProvider, Like } from 'react-facebook';

// import logo from './logo.svg';

import 'swiper/dist/css/swiper.min.css';
import 'swiper/dist/js/swiper.min.js';
import 'react-day-picker/lib/style.css';

import './kitchen-sink.css';
import './App.css';
import './Reset.css';
import './Ant.css';
import './Color.css';
import './Component.css';


import { Grid } from 'antd-mobile';

import Chat from './components/component/Chat/Chat';
import HomePage from './components/pages/news/HomePage';
import ListPage from './components/pages/ListPage';
import FeaturePage from './components/pages/FeaturePage';
import ListDetailPage from './components/pages/news/ListDetailPage';
import LeaguePage from './components/pages/news/LeaguePage';
import HighlightsLeaguePage from './components/pages/Highlights/HighlightsLeaguePage';
import AboutPage from './components/pages/AboutPage';

import IOSDownloadPage from './components/pages/IOSDownloadPage';
import DownloadPage from './components/pages/DownloadPage';

import LiveScorePage from './components/pages/live/LiveScorePage';
import LiveScoreMatchDetailPage from './components/pages/live/LiveScoreMatchDetailPage';

import ChannelAppPage from './components/pages/live/ChannelAppPage';
import BeinAppPage from './components/pages/live/BeinAppPage';
import SuperAppPage from './components/pages/live/SuperAppPage';
import Channel1AppPage from './components/pages/live/Channel1AppPage';
import MovieAppPage from './components/pages/live/MovieAppPage';
import Channel1AppDetailPage from './components/pages/live/Channel1AppDetailPage';
import Channel2AppPage from './components/pages/live/Channel2AppPage';
import Channel2AppDetailPage from './components/pages/live/Channel2AppDetailPage';
import Channel3AppPage from './components/pages/live/Channel3AppPage';
import Channel3AppDetailPage from './components/pages/live/Channel3AppDetailPage';
import ChannelsSchedulePage from './components/pages/ChannelsSchedulePage';

import HelpCenterAppPage from './components/pages/live/HelpCenterAppPage';


import HighlightsPage from './components/pages/Highlights/HighlightsPage';
import HighlightDetailPage from './components/pages/Highlights/HighlightsDetailPage';
import HighlightsListPage from './components/pages/Highlights/HighlightsListPage';
import TipOddPage from './components/pages/TipOddPage';
import TipOddLeaguePage from './components/pages/TipOddLeaguePage';
import FootballBettingChangePage from './components/pages/FootballBettingChangePage';
import FootballBettingHistoryDetail from './components/pages/FootballBettingHistoryDetail';
import FootballBettingHistory from './components/pages/FootballBettingHistory';
import BillContestPage from './components/pages/BillContestPage';
import LeagueContestPage from './components/pages/league/LeagueContestPage';
import LeagueTablePage from './components/pages/league/LeagueTablePage';
// import SLeagueContestPage from './components/pages/sleague/SLeagueContestPage';
// import SLeagueTablePage from './components/pages/sleague/SLeagueTablePage';

import MorePage from './components/pages/MorePage';
import ContestPage from './components/pages/ContestPage';
import MyAccPage from './components/pages/user/MyAccPage';
import AccPage from './components/pages/AccPage';

import InboxPage from './components/pages/user/InboxPage';
import TransactionPage from './components/pages/user/TransactionPage';
import OrderPage from './components/pages/user/OrderPage';
import LoginPage from './components/pages/LoginPage';
import SignUpPage from './components/pages/SignUpPage';
import FeedbackPage from './components/pages/FeedbackPage';
import PrizePoolPage from './components/pages/PrizePoolPage';
import TipstersPage from './components/pages/TipstersPage';
import TipstersListPage from './components/pages/TipstersListPage';
import TipstersListDetailPage from './components/pages/TipstersListDetailPage';

import PasswordRestPage from './components/pages/PasswordRestPage';
import AnnouncementsPage from './components/pages/user/AnnouncementsPage';


import HighlightsVideoSlider from './components/pages/HighlightsVideoSlider';

import BillHistoryPage from './components/pages/user/BillHistoryPage';
import LeagueHistoryPage from './components/pages/league/LeagueHistoryPage';
// import SLeagueHistoryPage from './components/pages/sleague/SLeagueHistoryPage';
import TermAndCondition from './components/pages/TermAndConditionPage';
import AccBillHistoryPage from './components/pages/AccBillHistoryPage';
import AccLeagueHistoryPage from './components/pages/AccLeagueHistoryPage';

import GemStorePage from './components/pages/GemStorePage';
import PackageStorePage from './components/pages/PackageStorePage';

import UserPredictPackage from './components/pages/UserPredictPackage';


import SkillvideoPage from './components/pages/SkillvideoPage';
import SkillvideoDetailPage from './components/pages/SkillvideoDetailPage';

import ParticipantPage from './components/pages/ParticipantPage';

import ForumPage from './components/pages/forum/ForumPage';
import ForumDetailPage from './components/pages/forum/ForumDetailPage';
import ForumCreatePage from './components/pages/forum/ForumCreatePage';
import ImagePreviewPage from './components/pages/ImagePreviewPage';

import Toolbar from "./components/Toolbar";
import SideDrawer from "./components/sidemenu/SideDrawer";
import BackDrop from "./components/backdrop/BackDrop";

// import EuroChampionPage from './components/pages/euro/EuroChampionPage';
// import EuroStandingPage from './components/pages/euro/EuroStandingPage';
// import EuroTeamPage     from './components/pages/euro/EuroTeamPage';
// import EuroTeamDetailPage from './components/pages/euro/EuroTeamDetailPage';


// import CommentPage from './components/pages/CommentPage';



require('./Shwesports.js')
// const data1 = Array.from(new Array(4)).map(() => ({
//   icon: 'https://gw.alipayobjects.com/zos/rmsportal/WXoqXTHrSnRcUwEaQgXJ.png',
// }));



// data1 = data1.reverse();
let url = '/';

let checklogin = () => {
    if(localStorage.getItem(window.shwesports.time)) {
        if(localStorage.getItem(window.shwesports.time) !== "0" ) {
            return true;
        }
    }
    return false;
}

class App extends Component {
  constructor(props) {
    super(props);
    //console.log(props);
    this.state = {
      url: '/',
    }

    //this.goBack = this.goBack.bind(this);
  }
  getCount() {
    const clicked = this.state.clicked
    if(clicked){
      this.setState({count: this.state.count - 1, clicked: false});
    } else {
      this.setState({count: this.state.count + 1, clicked: true})
    }

  }
  UNSAFE_componentWillMount() {
    if(!localStorage.getItem('lang')){
      localStorage.setItem('lang','mm');
    } else {
      if(localStorage.getItem('lang') === 'mm') {
        window.lang = window.mmlang;
      } else {
        window.lang = window.enlang;
      }
    }
  }
  componentDidMount() {
    
    // this.window.shwesports.route =JSON.stringify( "/" );
      //console.log(localStorage.getItem('route'))
      
  }

  UNSAFE_componentWillUpdate() {
    //this.goBack();
    
      
    // } else {
    //   this.setState({ url: "/" }); 
    // }
  }

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  backDropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };
  render() {
   
    if (JSON.parse(localStorage.getItem("route")) !== null)  {
      let routes = JSON.parse(localStorage.getItem("route"));
      //console.log(routes.length)
        if(routes.length>1) {
          // if(window.shwesports.preurl ===  routes[routes.length-1]) {
          //   url = routes[routes.length-1] ;
          // } else {
            url = routes[routes.length-1] ;
          //}
          
        } else {
          url = "/";
        }
      
     }

    let backdrop;

    if (this.state.sideDrawerOpen) {
      // sideDrawer = <SideDrawer />;
      backdrop = <BackDrop click={this.backDropClickHandler} />;

    }

    return (
      <div className="App">
        <Toolbar drawerToggleClickHandler={this.drawerToggleClickHandler} />
        <SideDrawer show={this.state.sideDrawerOpen} />
        {backdrop}
        <div className="App-intro">
          <div id="fb-root"></div>
          <Switch>

            <Route path="/help-center" component={HelpCenterAppPage} />
            
            <Route path="/chat" component={Chat} />
            
            <Route path="/feature" component={FeaturePage} />
            <Route path="/latest" component={ListPage} />
            
            <Route path="/download/ios" component={IOSDownloadPage} />
            <Route path="/download" component={DownloadPage} />
            <Route path="/comming-soon" component={AboutPage} />
            <Route path="/live-score" component={LiveScorePage} />
            <Route exact path="/live/match-detail/:id" component={LiveScoreMatchDetailPage} />
            
            <Route path="/channel1/detail" component={Channel1AppDetailPage} />
            
            <Route path="/channel" component={ChannelAppPage} />
            <Route path="/channel1" component={Channel1AppPage} />
            
            <Route path="/channel2/detail" component={Channel2AppDetailPage} />
            <Route path="/channel2" component={Channel2AppPage} />
            
            <Route path="/channel3/detail" component={Channel3AppDetailPage} />
            <Route path="/channel3" component={Channel3AppPage} />
            

            <Route path="/channelsschedule" component={ChannelsSchedulePage} />
            
            <Route path="/beinsport" component={BeinAppPage} />
            <Route path="/supersport" component={SuperAppPage} />
            <Route path="/movies" component={MovieAppPage} />

            <Route path="/news/league/:id/:name" component={LeaguePage} />
            <Route path="/news/:id" component={ListDetailPage} />
            
            <Route path="/tip-and-odd/league/:name" component={TipOddLeaguePage} />
            <Route path="/tip-and-odd" component={TipOddPage} />

            <Route path="/football-betting-change" component={FootballBettingChangePage} />
            <Route path="/football-betting-history/league/:id/:name" component={FootballBettingHistoryDetail} />
            <Route path="/football-betting-history" component={FootballBettingHistory} />
            

            {/* <Route path="/comment" component={CommentPage} /> */}
            
            <Route path="/highlightsvideo" component={HighlightsVideoSlider} />
            <Route path="/highlight/latest" component={HighlightsListPage} />
            <Route path="/highlight/league/:id/:name" component={HighlightsLeaguePage} />
            <Route path="/highlight/:id/:name" component={HighlightDetailPage} />
            <Route path="/highlights" component={HighlightsPage} />
            
            <Route path="/image/:id" component={ImagePreviewPage} />
            
            <Route path="/forum/create" component={ForumCreatePage} />
            <Route path="/forum/:id" component={ForumDetailPage} />
            <Route path="/forum" component={ForumPage} />

            <Route path="/skillvideo/:id/:name" component={SkillvideoDetailPage} />
            <Route path="/skillvideo" component={SkillvideoPage} />

            <Route path="/participant/predict/order/:id" component={UserPredictPackage} />

            <Route path="/more/announcements" component={AnnouncementsPage} />
            <Route path="/more/myaccount" component={MyAccPage} />
            <Route path="/more/account/:id/bill-history" component={AccBillHistoryPage} />
            <Route path="/more/account/:id/league-history" component={AccLeagueHistoryPage} />
            <Route path="/more/account/:id" component={AccPage} />
            <Route path="/more/transaction" component={TransactionPage} />
            <Route path="/more/order" component={OrderPage} />
            <Route path="/more/inbox" component={InboxPage} />
            
            <Route path="/more/bill-contest" component={BillContestPage} />
            <Route path="/more/league-contest" component={LeagueContestPage} />
            {/* <Route path="/more/sleague-contest" component={SLeagueContestPage} /> */}
            
            <Route path="/more/league-table" component={LeagueTablePage} />
            {/* <Route path="/more/sleague-table" component={SLeagueTablePage} /> */}
            <Route path="/more/prize-pool" component={PrizePoolPage} />
            <Route path="/more/tipsters/:id/detail/:id2" component={TipstersListDetailPage} />
            <Route path="/more/tipsters/:id" component={TipstersListPage} />
            <Route path="/more/tipsters" component={TipstersPage} />
            
            
            <Route path="/more/gems/store" component={GemStorePage} />
            <Route path="/more/package/store" component={PackageStorePage} />

            
            <Route path="/more/bill-history" component={BillHistoryPage} />
            <Route path="/more/league-history" component={LeagueHistoryPage} />
            {/* <Route path="/more/sleague-history" component={SLeagueHistoryPage} /> */}
            
            <Route path="/more/login" component={LoginPage} />
            <Route path="/more/feedback" component={FeedbackPage} />
            <Route path="/more/sign-up" component={SignUpPage} />
            <Route path="/more/password-reset" component={PasswordRestPage} />
            <Route path="/more/term-condition" component={TermAndCondition} />
            <Route path="/more" component={MorePage} />
            <Route path="/contest" component={ContestPage} />
            <Route path="/participant" component={ParticipantPage} />

            <Route path="/news" component={HomePage} />
            {/* <Route path="/euro/euro-standing" component={EuroStandingPage} />
            <Route path="/euro/euro-team" component={EuroTeamPage} />
            
            <Route  path="/news"  component={EuroChampionPage} /> */}

            <Route exact path="/" component={HomePage} />




            {/* <Route path="/euro/team/:id" component={EuroTeamDetailPage} /> */}
           

            <Redirect to="/" />
          </Switch>
          
        </div>
        <footer  className="App-footer">
          
          <div className="App-footer-inner">
            {/* {window.shwesports.footerMenu} */}
          
            <Grid data={window.lang.footer}
              columnNum={5}
              renderItem={dataItem => (
                <Link to={dataItem.url} className="cont-wrap-link">
                    <div style={{ color: '#888', fontSize: '14px', marginTop: '0px' }}>
                    { (window.shwesports.footerMenu === dataItem.check ) ? <img src={dataItem.icon}  alt="" /> :  <img src={dataItem.icon_off}  alt="" /> }
                      <span>{dataItem.text}</span>
                    </div>
                </Link>
              )}
            />
          </div>
        </footer>
        
      </div>
    );

    
  }
  
}

export default App;
