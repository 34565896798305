import React from 'react';
import axios from 'axios';
import mmph from 'myanmar-phonenumber';
//import { Modal }  from 'antd-mobile';
import {
    Link
  } from 'react-router-dom';

//   const alert = Modal.alert;

class MorePage extends React.Component {
    constructor(props) {
        super(props)
        window.shwesports.footerMenu = "MORE";
        this.state = { step: "home", message : "", league : false, advertise : {advertise_image:""}, sleague : false};
        this.validatePhone      = this.validatePhone.bind(this);
        this.changeSubMenu      = this.changeSubMenu.bind(this);
        this.cacheClear         = this.cacheClear.bind(this);
        this.emptyCache         = this.emptyCache.bind(this);
    }

    changeSubMenu(step) {
        if(step === 'league') {
            if(this.state.league) {
                this.setState({ league : false});
            } else {
                this.setState({ league : true});
            }
        }

        //console.log(step)
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          }
          if(menu.length > 0) {
            //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                  menu.pop();
                  //window.shwesports.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  //localStorage.setItem("route", JSON.stringify(menu) );
              }
              else if(menu[menu.length-1] !== url ){
                  menu.push(url)
                  //window.shwesports.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );

              }
              //window.shwesports.preurl =  menu[menu.length-1];
              window.shwesports.preurl =  menu[menu.length-2];
              //console.log(window.shwesports.preurl)
          }
        }
      }




    componentDidMount() {

        this.setState({ step: "list" });

        if(localStorage.getItem(window.shwesports.time)) {
              if(localStorage.getItem(window.shwesports.time) !== "0" ) {
                  document.getElementById( 'toggle-home' ).style.display = 'none';
              }
        }
        // document.getElementById( 'toggle-home' ).style.display = 'none';

        // if(localStorage.getItem('time')) {
        //   if(localStorage.getItem('time') !== "0" ) {
        //     //localStorage.setItem('time', true);
        //     this.setState({ step: "list" });
        //   }

        // }

        this.hisUrl('/more');

        this.fetchRamAdsData();

        clearTimeout(window.shwesports.liveurl)
        clearTimeout(window.shwesports.livecounturl)
        clearTimeout(window.shwesports.livecounturl2)
    }

    UNSAFE_componentWillUpdate() {
        //this.hisUrl('/more');
    }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    cacheClear() {
        window.localStorage.clear();
        //window.location.reload(true);
        //this.props.history.push("/");
    }

    // cacheClear() {
    //     let data = window.confirm("Are you sure want to cache clear?");
    //     if(data){
    //         window.localStorage.clear();
    //         this.props.history.push("/contest");
    //     }
    // }

    validatePhone() {
        let form = this.state.form
        let data
        //console.log(this.state.form.phone.length)
        if(form.phone.length > 6) {
            if(mmph.isValidMMPhoneNumber(form.phone)){
                data = {
                    username : this.state.form.username,
                    phone : mmph.normalizeInput(this.state.form.phone)
                }
                this.setState({  message : "SMS Sending" });
                this.sendSMSApi(data);
            } else {
                this.setState({  message : "Invalid phone number" });
            }

        } else {
            //console.log(this.state.message)
            this.setState({  message : "Invalid phone number" });
        }

    }
    sendSMSApi(formdata) {
        var self = this;
        let dataurl = window.shwesports.apiUrl+'/user/register';
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then(function (response) {
            if(response.data.success) {
                self.setState({  message : "SMS already Sent", step : "verify" });
            } else {
                self.setState({  message : "Something was wrong" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    viewHandler() {

        switch(this.state.step) {
            // case "line-up":   return "";
            //case "register":   return this.registerBlock();
            case "profile": return this.profileBlock();
            case "home": return this.homeBlock();
            case "list": return this.listBlock();
            default:      return "";
          }
    }

    checklogin(){
        if(localStorage.getItem('time')) {
            if(localStorage.getItem('time') !== "0" ) {
                return true;
            }
        }
        return false;
    }

    listBlock() {
        return  <div className="row text-left setting-menu">

                    {(this.state.advertise) ? <a href={this.state.advertise.advertise_goto} target="_blank" rel="noopener noreferrer">
                            <img src={window.shwesports.apiAssetUrl+"/images/"+this.state.advertise.advertise_image} style={{ width: '100%',marginTop:'20px',borderRadius:"15px",marginBottom:'5px' }} alt={this.state.advertise.advertise_title}/>

                    </a>: "" }
                    <div className="grid-container">
                        <div className="grid-item">
                            <Link className="" to="/tip-and-odd">
                                <div className="more-box flex-box">
                                    <img src="/img/tipster_40.png" alt={window.lang.title.tip} />{window.lang.title.tip}<span>&gt;</span>
                                </div>
                                </Link>
                        </div>
                        <div className="grid-item">
                            <Link to="/more/league-contest"  > 
                                <div className="more-box flex-box">
                                    <img src="/img/footer/cupoff.png" alt={window.lang.title.league_contest} width="50px" />{window.lang.title.league_contest} <span>&gt;</span>
                                </div>
                            </Link>
                        </div>
                        <div className="grid-item">
                                <a href="https://t.me/mmhighlights" target="_blank">
                                    <div className="more-box flex-box">
                                    <img src="/img/videoicon.png" alt={window.lang.title.highlights} />{window.lang.title.highlights}<span>&gt;</span>
                                    </div>
                                </a>
                        </div>
                        <div className="grid-item">
                            <Link to="/more/announcements">
                                <div className="more-box flex-box">
                                <img src="/img/annoucenmetnicon.png" alt={window.lang.more.announcement} />{window.lang.more.announcement} <span>&gt;</span>
                                </div>
                                
                            </Link>
                        </div>
                        <div className="grid-item">
                            {(this.checklogin()) ?  
                                <a href="https://www.facebook.com/shwesports">
                                    <div className="more-box flex-box">
                                    <img src="/img/advice_icon.png" alt={window.lang.more.help_center} />{window.lang.more.help_center} <span>&gt;</span>
                                    </div>
                                </a> : ""}
                        </div>
                        <div className="grid-item"  style={{ margin: "auto" }}>
                            {/* <a onClick={this.emptyCache} className="pointer " style={{"padding" : "0px" }}>
                                <div className="version-box flex-box" style={{ textAlign:'center' }}>
                                {window.lang.more.version_check}
                                <span>&nbsp;  </span>
                                </div>
                            </a> */}
                            <div className="more-box flex-box "  style={{"background" : "green", "color" : "#fff" }}>
                                <a onClick={this.emptyCache} className="pointer btn-green " style={{"padding" : "3px" , "color" : "#fff"}}>
                                <span className="btn-version1">{window.lang.more.version_check}</span>
                                
                                </a>
                            </div>
                             
                        </div>
                       
                                
                    </div>
                   {/* <ul>
                        <div className="flex-between">
                            <div className="">
                                <Link className="" to="/channel"><img src="/img/channel1.png" alt={window.lang.more.live_channel} />{window.lang.more.live_channel}<span>&gt;</span></li></Link>
                            </div>
                            <div>
                                <Link to="/more/league-contest"  > <li><img src="/img/footer/cupoff.png" alt={window.lang.title.league_contest} />{window.lang.title.league_contest} <span>&gt;</span></li></Link>
                            </div>
                            <div>
                                <a href="https://t.me/mmhighlights" target="_blank"><li><img src="/img/videoicon.png" alt={window.lang.title.highlights} />{window.lang.title.highlights}<span>&gt;</span></li>
                                </a>
                            </div>
                        </div>
                        <div className="flex-between">
                            <div>
                            <Link to="/more/announcements"><li><img src="/img/annoucenmetnicon.png" alt={window.lang.more.announcement} />{window.lang.more.announcement} <span>&gt;</span></li></Link>
                            </div>
                            <div>
                            {(this.checklogin()) ?  <Link to="/more/feedback"><li><img src="/img/advice_icon.png" alt={window.lang.more.feedback} />{window.lang.more.feedback} <span>&gt;</span></li></Link> : ""}
                            </div>
                            <div>
                            <a onClick={this.emptyCache} className="pointer " style={{"padding" : "0px" }}><li className='btn-version' style={{ "margin":"auto" }}> {window.lang.more.version_check}<span>&nbsp;  </span></li> </a>
                            </div>
                        </div>
                        <div className="flex-between">
                            <div>1</div>
                            <div>2</div>
                            <div>3</div>
                        </div>
                   </ul> */}
              {/*      <img src="/img/k9win.jpg" className="img-responsive" />*/}

                  


                </div>
    }

    emptyCache(){

        // alert("ok");

        if('caches' in window){

            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
            });

            // Makes sure the page reloads. Changes are only visible after you refresh.
            window.location.reload(true);
        }
    }


    profileBlock() {
      return <div>
          <div className="row">
              <h2>Hi {(localStorage.getItem('username')? localStorage.getItem('username') : "Customer")}</h2>
              <p>Welcome to Shwesports</p>
          </div>
      </div>
   }

    homeBlock() {
        return <div>
            <br />
            <br />
            <br />
            {/* <h1 className="color-green">SHWE SPORTS</h1> */}
            <img src="/img/shwesport1.png" alt="profile" className="medium-logo"/>
            {/* <img src="/img/league/shwesport1.png" alt="shwesports logo" /> */}
            <br />
            <span>{this.state.message}</span>
            <br />
            <br />
            <div className="row">
                <Link to="/more/login" className="btn btn-success grid-50">{window.lang.form.login}</Link><br /><br />
                <Link to="/more/sign-up" className="btn btn-yellow grid-50">{window.lang.form.register}</Link>
            </div>
        </div>
    }

    fetchRamAdsData() {
        let dataurl = window.shwesports.apiUrl+'/advertise/detail/9';

        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "force-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
            this.setState({
                advertise : resp.data.advertise
            });

            localStorage.setItem("contest_advertise", JSON.stringify(resp.data.advertise) )

        })
        .catch(function(error) {
          //console.log(error);
          //console.log(error.tostring());
        });

    }


    render() {
        return (
            <div className="page">
                <div className="page-content text-center">

                    { this.viewHandler() }

                    <div className="ant-back-footer">
                        <div className="ant-back-top">
                            <div className="ant-back-top-content">
                                <Link to={window.shwesports.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null)
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MorePage;
